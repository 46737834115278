import React, { type PropsWithChildren, useState } from 'react';

import { createSafeContext } from './createSafeContext';
import HistoryService from '../services/history.service';
import HistoryModel from '../components/history/HistoryModel';
import { useForecast } from './ForecastProvider';

interface HistoryContext {
  historyId: string;
  setHistoryId: (x: string) => void;
  history: any;
  setHistory: (history: any) => void;
  getHistory: () => HistoryModel | null;
  handlers: {
    list: (forecastId: string) => Promise<void>;
    rollback: (historyId: string) => Promise<void>;
    remove: (historyId: string) => Promise<void>;
  };
}

const [useHistory, Provider] = createSafeContext<HistoryContext>();

export { useHistory };

export const HistoryProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [historyId, setHistoryId] = useState<string>('');
  const [history, setHistory] = useState<any>(null);
  const ref = React.useRef<any>({});
  const { forecastId } = useForecast();

  const list = async (forecastId: string) => {
    if (!forecastId) return;
    try {
      const res = await HistoryService.ListHistory({
        forecastId,
      });
      const { histories } = res.data ?? {};
      if (histories) {
        const history = histories[0];
        const historyModel = new HistoryModel(history, histories);
        ref.current.history = historyModel;
        ref.current.historyId = historyModel.historyId;
        setHistory(historyModel);
        setHistoryId(historyModel.historyId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const rollback = async (historyId: string) => {
    if (!historyId) return;
    return HistoryService.RollbackHistory({
      historyId: historyId,
    })
      .then(() => {
        // refetch the history
        list(forecastId);
        // window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const remove = async (historyId: string) => {
    if (!historyId) return;
    return HistoryService.RemoveHistory({
      historyId: historyId,
    })
      .then(() => {
        // refetch the history
        list(forecastId);
        // window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    if (forecastId) {
      list(forecastId);
    }
  }, [forecastId]);

  const value = {
    historyId,
    setHistoryId,
    history,
    setHistory,
    getHistory: () => {
      return ref.current.history;
    },
    handlers: {
      list,
      rollback,
      remove,
    },
  };

  return <Provider value={value}>{children}</Provider>;
};
