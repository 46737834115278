import React from 'react';

interface IGrowthInputProps {
  value: string | number;
  onChange: (value: string | number) => void;
  style?: any;
  className?: string;
  disabled?: boolean;
}

const validate = (input: string) => {
  const isValid = /^[+-]?\d*\.?\d*$/.test(input);
  return isValid;
};

const GrowthInput: React.FC<IGrowthInputProps> = (props: any) => {
  const { style, className, disabled, onChange, value } = props;
  const [intervalValue, $intervalValue] = React.useState(value);
  const ref = React.useRef<any>({
    isChanging: false,
  });
  React.useEffect(() => {
    if (!ref.current.isChanging) {
      $intervalValue(value);
    }
  }, [value, ref]);
  return (
    <input
      type="text"
      style={style}
      className={className}
      disabled={disabled}
      value={intervalValue}
      onChange={(e) => {
        ref.current.isChanging = true;
        const value = e.target.value;
        if (!validate(value)) {
          return false;
        }
        $intervalValue(value);
        onChange && onChange(e);
      }}
      onBlur={() => {
        ref.current.isChanging = false;
      }}
    />
  );
};

export default GrowthInput;
