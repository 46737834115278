import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { useForecast } from '../../providers/ForecastProvider';

interface ISelectItem {
  name: string;
  forecastId?: string;
  territoryId?: string;
  forecastCalendarId?: string;
  year?: number;
  status: string;
}

interface INavbarSelect {
  items: ISelectItem[];
  placeholder: string;
  scope: 'Territory' | 'Forecast' | 'TimePeriod';
  setSavedValues: (x: any) => void;
  disabled?: boolean;
  savedValues: any;
}

const CheckModalSelect: React.FC<INavbarSelect> = (props) => {
  const { items, placeholder, scope, setSavedValues, disabled, savedValues } = props;
  const [selectedOption, setSelectedOption] = useState<any>();
  const { hasForecasts, setCurrentForecastYear, setIsForecastClosed } = useForecast();

  useEffect(() => {
    if (scope === 'TimePeriod' && !selectedOption && savedValues.forecastCalendarId) {
      setSelectedOption(options?.find((option) => option.value === savedValues.forecastCalendarId));
    }
  }, [savedValues]);

  const handleChange = ({ value }: { value: string }) => {
    const curYear = new Date().getFullYear();
    if (!value) {
      return;
    } else {
      setSelectedOption(options?.find((option) => option.value === value));
      if (scope === 'Forecast') {
        const selectedForecast = options?.find((option) => option.value === value);
        setIsForecastClosed(selectedForecast?.status === 'Closed');
      }
      if (scope === 'TimePeriod') {
        const currForecastCalendar = items.filter((item) => item.forecastCalendarId === value);
        const yearValue =
          currForecastCalendar && currForecastCalendar[0]?.year ? currForecastCalendar[0].year : curYear;
        setCurrentForecastYear(yearValue);
        localStorage.setItem('curYear', String(yearValue));
        localStorage.setItem('previousCurrentYear', String(yearValue));
      }
      localStorage.setItem(scope, value);
      setSavedValues((prevState: any) => {
        const label = scope === 'Forecast' ? 'forecastId' : 'forecastCalendarId';
        return { ...prevState, [label]: value };
      });
    }
  };

  const options = useMemo(() => {
    return items?.map((item) => ({
      value:
        scope === 'Forecast' ? item.forecastId : scope === 'Territory' ? item.territoryId : item.forecastCalendarId,
      label: item.name,
      status: item.status,
    }));
  }, [items, scope]);

  return (
    <div>
      <Select
        isDisabled={!hasForecasts || disabled}
        isSearchable={true}
        value={selectedOption}
        onChange={(option: any) => handleChange(option)}
        placeholder={placeholder}
        options={options}
        styles={{
          option: (styles, { data }) => {
            const status = data.status;
            return {
              ...styles,
              fontStyle: scope === 'Forecast' && status === 'Closed' ? 'italic' : 'initial',
            };
          },
          singleValue: (styles, { data }) => {
            const status = data.status;
            return {
              ...styles,
              fontStyle: scope === 'Forecast' && status === 'Closed' ? 'italic' : 'initial',
              color: scope === 'Forecast' && status === 'Closed' ? 'gray' : 'black',
            };
          },
          container: (baseStyles) => ({
            ...baseStyles,
            width: '180px',
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            minHeight: '27px',
            borderColor: '#e5e5ea',
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            height: '27px',
            alignItems: 'normal',
          }),
          indicatorsContainer: (baseStyles) => ({
            ...baseStyles,
            height: '27px',
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            zIndex: 5000,
          }),
        }}
      />
    </div>
  );
};

export default CheckModalSelect;
