import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScrollToTop from 'react-scroll-to-top';
import { faAngleUp, faCloudDownload, faEnvelope, faMailBulk, faMailForward } from '@fortawesome/free-solid-svg-icons';
import { parseBonusData } from '../../utils/downloadUtils';
import { hideLoading, showLoading } from '../../lib/uiService';
import { ITerritory } from '../../lib/types';
import ForecastService from '../../services/forecast.service';
import salesCompService from '../../services/sales.bonus.service';
import useErrorHandling from '../../hooks/useErrorHandling';
import { MarketManagerItem } from '../../components/SalesBonus/MarketManagerItem';
import { RollupManagerItem } from '../../components/SalesBonus/RollupManagerItem';
import { formatDate } from '../../utils/formatDate';

export interface Description {
  manager: string;
  userId: any;
  territory?: string;
  inValidUser: boolean;
  totalPotential: string;
  totalEarned: string;
}

interface Body {
  description?: Description;
  header: any;
  rows: any;
}

interface ImportDataItem {
  title: string;
  body: Body[];
}

const ReviewSalesBonus: React.FC = () => {
  const [importData, setImportData] = useState<ImportDataItem[]>();
  const [territories, setTerritories] = useState<any>([]);
  const [existingDates, setExistingDates] = useState<any>([]);
  const [hasExistingDate, setHasExistingDate] = useState<boolean>(false);
  const [isOpen, setOpentModal] = useState(false);
  const [openDateWarningModal, setOpenDateWarningModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [emailSenderData, setEmailSenderData] = useState({});
  const [bonusDate, setBonusDate] = useState<any>();
  const [file, setFile] = useState<any>();
  const [modalBonus, setBonusData] = useState({
    failedRows: [],
    failureCount: 0,
    recordsInserted: 0,
    recordsUpdated: 0,
    totalProcessed: 0,
    updatedRows: [],
  });
  const [modalRollUp, setRollUpData] = useState({
    failedRows: [],
    failureCount: 0,
    recordsInserted: 0,
    recordsUpdated: 0,
    totalProcessed: 0,
    updatedRows: [],
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { setError } = useErrorHandling();
  const { state } = location;

  useEffect(() => {
    showLoading();
    setFile(state);
    readXlsFile(state);
    ForecastService.GetTerritories()
      .then(({ data }) => {
        const tempTerritories = data.map((region: ITerritory) => region?.name);
        setTerritories(tempTerritories);
      })
      .catch((error) => console.error(error));

    salesCompService
      .GetSalesComp()
      .then(({ data }) => {
        if (data.length > 0) {
          const tempSalesCompList = data.map((comp: any) => ({
            startDate: formatDate({ month: '2-digit', day: '2-digit', year: 'numeric' }, new Date(comp.startDate)),
            endDate: formatDate({ month: '2-digit', day: '2-digit', year: 'numeric' }, new Date(comp.endDate)),
          }));
          setExistingDates(tempSalesCompList);
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ status: error.response.status });
      });
    hideLoading();
  }, []);

  useEffect(() => {
    if (bonusDate?.startDate && bonusDate?.endDate) {
      setHasExistingDate(
        existingDates.some((item) => item.startDate === bonusDate.startDate && item.endDate === bonusDate.endDate),
      );
    }
  }, [bonusDate]);

  useEffect(() => {
    if (importData) {
      if (importData.length == 0) {
        toast.warn('There is no match Data in spreedsheet');
        navigate('/sales-bonus/upload');
        return;
      }
      navigate('/sales-bonus/review#Market%20Manager');
    }
  }, [importData]);

  const readXlsFile = async (file: any) => {
    if (!file) {
      toast.warn('Please select a file');
      return;
    }
    await parseBonusData(file, setImportData, setBonusDate);
  };

  const getColumn = useCallback((header: any[]) => {
    return header.map((val, i) => {
      if (i === header.length - 1) {
        return {
          Header: val,
          id: 'hidden',
          Cell: ({ data, row }: { data: any; row: any }) => (
            <td>
              <span>{data[row.id][val]}</span>
            </td>
          ),
        };
      } else {
        return {
          Header: val,
          id: i,
          Cell: ({ data, row }: { data: any; row: any }) => {
            return (
              <td>
                <span>{data[row.id][val]}</span>
              </td>
            );
          },
        };
      }
    });
  }, []);

  const doubleRoleCheck = () => {
    const checkData = importData?.flatMap(({ body }) => {
      return body.map(({ description }) => description);
    });
    const marketManagers = importData
      ? importData[0]?.body
          .filter(({ description }) => description?.userId)
          .map(({ description }) => description?.manager.toLowerCase())
      : [];
    const regionalManagers = importData
      ? importData[1]?.body
          .filter(({ description }) => description?.userId)
          .map(({ description }) => description?.manager.toLowerCase())
      : [];
    const salesDirectors = importData
      ? importData[2]?.body
          .filter(({ description }) => description?.userId)
          .map(({ description }) => description?.manager.toLowerCase())
      : [];
    const withRoleMMandRM = marketManagers.filter((manager) => regionalManagers.includes(manager));
    const withRoleMMandDM = marketManagers.filter((manager) => salesDirectors.includes(manager));
    const withRoleRMandDM = regionalManagers.filter((manager) => salesDirectors.includes(manager));
    if (checkData?.every((data) => data && data?.userId == -1)) {
      toast.warn('There is no available data to create!');
      return false;
    }
    if (withRoleMMandRM.length > 0) {
      toast.warn(
        `User ${withRoleMMandRM.join(', ')} cannot be more than one role (Market Manager, Regional Manager).  Please update and re-upload`,
      );
      return false;
    }
    if (withRoleMMandDM.length > 0) {
      toast.warn(
        `User ${withRoleMMandDM.join(', ')} cannot be more than one role (Market Manager, Sales Director).  Please update and re-upload`,
      );
      return false;
    }
    if (withRoleRMandDM.length > 0) {
      toast.warn(
        `User ${withRoleRMandDM.join(', ')} cannot be more than one role (Sales Director, Regional Manager).  Please update and re-upload`,
      );
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    if (!doubleRoleCheck()) {
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    setLoading(true);
    setOpentModal(true);
    salesCompService
      .ImportSalesBonus(formData)
      .then((res) => {
        if (res.data) {
          const { failedRows, failureCount, recordsInserted, recordsUpdated, totalProcessed, updatedRows, emailData } =
            res.data;
          setEmailSenderData(emailData);
          setBonusData({ failedRows, failureCount, recordsInserted, recordsUpdated, totalProcessed, updatedRows });
          localStorage.setItem('salesCompId', res.data.salesCompId);
        }
        formData.append('salesCompId', res.data.salesCompId);
        salesCompService
          .ImportCompRollUp(formData)
          .then((res) => {
            if (res.data) {
              setRollUpData(res.data);
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
            setError(err.status);
          });
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        setError(err.status);
      });
  };

  return (
    <>
      <div>
        <h3 className="text-dark mb-4">Review Upload</h3>
        {!importData && (
          <div
            className="position-absolute d-flex align-items-center justify-content-center"
            style={{ height: '900px', width: '960px' }}
          >
            <div
              className="me-2 spinner-border text-info"
              style={{
                position: 'relative',
                zIndex: 1,
              }}
            ></div>
          </div>
        )}
        <Card
          style={{
            filter: !importData ? 'blur(5px)' : 'none',
            transition: 'filter 0s ease',
          }}
        >
          <div className="p-3">
            <h5 className="text-primary">Upload Monthly Sales Bonus</h5>
            <div className="d-flex justify-content-between mt-4">
              <div>
                <h3>Confirm Details from Upload</h3>
                <p>
                  Bonus Period:{' '}
                  <span className="h5">
                    {bonusDate?.startDate ?? ''} - {bonusDate?.endDate ?? ''}
                  </span>
                </p>
              </div>
              <div className="mr-5">
                <Button href="#Market Manager" className="mx-1" variant="light">
                  Market Managers
                </Button>
                <Button href="#Regional Managers" className="mx-1" variant="light">
                  Regional Managers
                </Button>
                <Button href="#Director of Sales" className="mx-1" variant="light">
                  Directors
                </Button>
              </div>
              <div className="py-3">
                <Button
                  onClick={() => {
                    if (hasExistingDate) {
                      setOpenDateWarningModal(true);
                    } else {
                      onSubmit();
                    }
                  }}
                  variant="primary"
                >
                  Import and Send
                </Button>
              </div>
            </div>
          </div>
          <div>
            {importData &&
              importData.map(({ title, body }, i) => (
                <div className="px-3" key={i}>
                  <h3 id={title}>{title}</h3>
                  {body.map(({ description, header, rows }, j) => {
                    const columns = getColumn(header);
                    return (
                      description.manager !== 'Goal Plan' && (
                        <div key={j}>
                          {title === 'Market Managers' ? (
                            <MarketManagerItem
                              description={description}
                              territories={territories}
                              columns={columns}
                              rows={rows}
                            />
                          ) : (
                            <RollupManagerItem description={description} columns={columns} rows={rows} />
                          )}
                        </div>
                      )
                    );
                  })}
                </div>
              ))}
          </div>
          <div className="p-3 d-flex justify-content-between">
            <Button onClick={() => navigate('/sales-bonus/upload')} variant="primary">
              Go Back
            </Button>
            <Button
              onClick={() => {
                if (hasExistingDate) {
                  setOpenDateWarningModal(true);
                } else {
                  onSubmit();
                }
              }}
              variant="primary"
            >
              Import and Send
            </Button>
          </div>
          <Modal show={isOpen} centered>
            <Modal.Header>
              <FontAwesomeIcon size="2x" className="text-primary" icon={faCloudDownload} />
              <span className="mx-2 text-lg fw-bold">{!isLoading ? 'Import Completed' : 'Importing...'}</span>
            </Modal.Header>
            <Modal.Body>
              {isLoading ? (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
                  <div>
                    <h3>Marketing Manager Data</h3>
                    <div className="m-2">
                      <div>
                        <p className="fw-bold">Rows Processed: {modalBonus.totalProcessed}</p>
                        <p className="fw-bold">Records inserted: {modalBonus.recordsInserted}</p>
                        <p className="fw-bold">Records Updated: {modalBonus.recordsUpdated}</p>
                        <p className="fw-bold">Rows Updated: {`${modalBonus.updatedRows.join(', ')}`}</p>
                        <p className="fw-bold">Failures: {modalBonus.failureCount}</p>
                        <p className="fw-bold">Failed Rows: {`${modalBonus.failedRows.join(', ')}`}</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3>RM and Director Data</h3>
                    <div className="m-2">
                      <div>
                        <p className="fw-bold">Rows Processed: {modalRollUp.totalProcessed}</p>
                        <p className="fw-bold">Records inserted: {modalRollUp.recordsInserted}</p>
                        <p className="fw-bold">Records Updated: {modalRollUp.recordsUpdated}</p>
                        <p className="fw-bold">Rows Updated: {`${modalRollUp.updatedRows.join(', ')}`}</p>
                        <p className="fw-bold">Failures: {modalRollUp.failureCount}</p>
                        <p className="fw-bold">Failed Rows: {`${modalRollUp.failedRows.join(', ')}`}</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                disabled={isLoading}
                onClick={() => {
                  salesCompService
                    .SendBonusEmails(emailSenderData)
                    .then(() => {
                      toast.success('Emails successfully sent');
                    })
                    .catch((err) => {
                      console.error(err);
                      setError(err.status);
                    });
                }}
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </Button>
              <Button
                disabled={isLoading}
                variant="primary"
                onClick={() => {
                  setOpentModal(!isOpen);
                  navigate('/sales-bonus/upload');
                }}
              >
                Finish
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={openDateWarningModal} centered>
            <Modal.Header></Modal.Header>
            <Modal.Body className="text-center">
              <p className="fw-bold">
                You are uploading sales bonus data that overlaps with an existing bonus period. The existing data will
                be updated based on this new sheet.
              </p>
              <p className="fw-bold">Are you sure you want to continue?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {
                  setOpenDateWarningModal(false);
                  navigate('/sales-bonus/upload');
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                variant="primary"
                onClick={() => {
                  setOpenDateWarningModal(false);
                  onSubmit();
                }}
              >
                Continue
              </Button>
            </Modal.Footer>
          </Modal>
          <ScrollToTop
            smooth
            component={<FontAwesomeIcon className="text-white" icon={faAngleUp} />}
            viewBox="0 0 245 245"
            className="bg-secondary"
          />
        </Card>
      </div>
    </>
  );
};

export default ReviewSalesBonus;
