import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { formatNum } from '../../utils/formatNum';
import { MONTHS } from '../../constants';
import { darkenRgbaColor } from '../../utils/calculation';
import { useForecast } from '../../providers/ForecastProvider';

const BarChart = ({ data, type }: { data: any; type: string }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const [chartInstance, setChartInstance] = useState<Chart | null>(null);
  const { forecastId, currentForecastYear } = useForecast();

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstance) {
        chartInstance.destroy();
      }
      const originalBorderColors: any = [];

      // Initialize originalBorderColors array with initial border colors
      data.forEach((dataset: any) => {
        originalBorderColors.push(dataset.borderColor);
      });

      const ctx: any = chartRef.current.getContext('2d');
      const newChartInstance = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: MONTHS,
          datasets: data,
        },
        options: {
          scales: {
            y: {
              ticks: {
                callback: function (value) {
                  return type === 'cases' ? formatNum(Number(value), 1, 1) : `$${formatNum(Number(value))}`;
                },
              },
            },
          },
          aspectRatio: 1.5,
          plugins: {
            tooltip: {
              enabled: true,
              callbacks: {
                label: function (context) {
                  return `${context.dataset.label as string}: ${type === 'cases' ? context.formattedValue : `$${context.formattedValue}`}`;
                },
              },
            },
            legend: {
              display: true,
            },
          },
          hover: {
            mode: 'dataset',
            intersect: true,
          },
          onHover: (event, elements) => {
            const chart = newChartInstance;
            if (chart.legend && chart.legend.legendItems) {
              if (elements.length) {
                const datasetIndex: number = elements[0].datasetIndex;
                chart.data.datasets.forEach((dataset: any, i) => {
                  if (i === datasetIndex) {
                    dataset.borderColor = darkenRgbaColor(dataset.borderColor, 30);
                  } else {
                    dataset.borderColor = originalBorderColors[i];
                  }
                });
              }
              chart.update();
            }
          },
        },
      });

      setChartInstance(newChartInstance);
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [data, forecastId, currentForecastYear]);

  return <canvas ref={chartRef} />;
};

export default BarChart;
