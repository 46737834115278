import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Form, InputGroup } from 'react-bootstrap';

import { useSidebar } from '../../providers/SidebarProvider';

import NavbarUser from './NavbarUser';
import TerritorySelect from './TerritorySelect';

import NavbarSelect from './NavbarSelect';
import { useForecast } from '../../providers/ForecastProvider';
import ForecastService from '../../services/forecast.service';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IForecast, UserRole } from '../../lib/types';
import { PERIOD_TYPES } from '../../constants';
import { useAuth } from '../../providers/AuthProvider';

const NavbarComponent: React.FC = () => {
  const { isOpen, setIsOpen } = useSidebar();
  const [searchParams] = useSearchParams();
  const { id, role } = useAuth();
  const paramForecastId = searchParams.get('forecastId');
  const paramCalendarId = searchParams.get('forecastCalendarId');
  const paramTerritoryId = searchParams.get('territoryId');
  const paramYear = searchParams.get('year');
  const paramUser = searchParams.get('userId');
  const [forecasts, setForecasts] = useState<IForecast[]>([]);
  const {
    forecastUpdated,
    setisProjectionCreatedByUser,
    setHasForecast,
    forecastId,
    setForecastId,
    setIsForecastFromParams,
    showPeriods,
    setShowPeriods,
    setCurrentForecastYear,
    setForecastCalendarId,
    hasForecasts,
    setTerritoryId,
    setIsForecastClosed,
  } = useForecast();
  const location = useLocation();
  const isLeft =
    location.pathname.includes('/admin') ||
    location.pathname.includes('/analysis') ||
    location.pathname.includes('/sales-bonus');
  const [timePeriodOptions, setTimePeriodOptions] = useState([]);
  const [isParamData, setIsParamData] = useState(false);

  useEffect(() => {
    if (hasForecasts) {
      if (paramForecastId && paramCalendarId && paramTerritoryId && paramYear && paramUser) {
        setIsParamData(true);
        setisProjectionCreatedByUser(id === paramUser);
        setIsForecastFromParams(true);
        setForecastId(paramForecastId);
        setForecastCalendarId(paramCalendarId);
        setTerritoryId(paramTerritoryId);
        setCurrentForecastYear(Number(paramYear));
      } else {
        setIsParamData(false);
        setIsForecastFromParams(false);
        setisProjectionCreatedByUser(false);
        const prevCurryear = localStorage.getItem('previousCurrentYear') || '';
        const previousTimePeriod = localStorage.getItem('previousTimePeriod') || '';
        const previousTerritoryId = localStorage.getItem('previousTerritory') || '';
        const previousForecastId = localStorage.getItem('previousForecast') || '';
        setForecastId(previousForecastId);
        setForecastCalendarId(previousTimePeriod);
        setTerritoryId(previousTerritoryId);
        setCurrentForecastYear(Number(prevCurryear));
        localStorage.setItem('Territory', previousTerritoryId);
        localStorage.setItem('Forecast', previousForecastId);
        localStorage.setItem('curYear', prevCurryear);
        localStorage.setItem('TimePeriod', previousTimePeriod);
      }
    }
  }, [paramCalendarId, paramForecastId, paramTerritoryId, paramYear, paramUser, role]);

  useEffect(() => {
    ForecastService.GetForecasts({ page: 1, pageSize: 100, search: '' })
      .then(({ data }) => {
        setForecasts(data.forecasts);
        const currentForecastId =
          paramForecastId && paramCalendarId && paramTerritoryId && paramYear ? paramForecastId : forecastId;
        setIsForecastClosed(
          data.forecasts?.find((option: any) => option.forecastId === currentForecastId)?.status === 'Closed',
        );
        setHasForecast(data.forecasts.length !== 0);
        if (data.forecasts.length === 0) {
          localStorage.setItem('previousCurrentYear', '');
          localStorage.setItem('previousTerritory', '');
          localStorage.setItem('previousTimePeriod', '');
          localStorage.setItem('previousForecast', '');
          setTerritoryId('');
          setForecastId('');
          setForecastCalendarId('');
          localStorage.setItem('Territory', '');
          localStorage.setItem('Forecast', '');
          localStorage.setItem('curYear', '');
          localStorage.setItem('TimePeriod', '');
        }
      })
      .catch((error) => console.log(error));
  }, [forecastUpdated]);

  useEffect(() => {
    //let quarterIndex = 1;
    let yearIndex = 1;
    const currentForecast = forecasts.find((item) => item.forecastId === forecastId);
    if (currentForecast) {
      // const startDate = new Date(currentForecast.startDate);
      // const endDate = new Date(currentForecast.endDate);

      // const startYear = startDate.getFullYear();
      // const endYear = endDate.getFullYear();

      const quarterOptions: any = [];
      const monthOptions: any = [];
      const yearOptions: any = [];
      const fullOptions: any = [];
      const forecastCalendars = currentForecast.forecastCalendars.sort(
        (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
      );
      forecastCalendars.map((cal) => {
        const startDate = new Date(cal.startDate);
        const startYear = startDate.getUTCFullYear();
        // const endDate = new Date(cal.endDate);
        // const endYear = endDate.getUTCFullYear();
        // const startMonthIndex = startDate.getMonth(); // Zero-based index
        // const startMonthName = MONTHS[startMonthIndex];
        // const endMonthIndex = endDate.getMonth();
        // const endMonthName = MONTHS[endMonthIndex];
        // ALLOW ONLY YEARLY TYPE
        switch (cal.periodType) {
          /*          case periodTypes.QUARTER:
                     quarterOptions.push({
                       name: `Q${quarterIndex}: ${startYear}`,
                       forecastCalendarId: cal.forecastCalendarId,
                     });
                     quarterIndex++;
                     break;
                   case periodTypes.MONTH:
                     monthOptions.push({
                       name: `${startMonthName} ${startYear}`,
                       forecastCalendarId: cal.forecastCalendarId,
                     });
                     break; */
          case PERIOD_TYPES.YEAR:
            yearOptions.push({
              name: `Year ${yearIndex}: ${startYear}`,
              year: startYear,
              forecastCalendarId: cal.forecastCalendarId,
            });
            yearIndex++;
            break;
          /*           case periodTypes.FULL:
                      fullOptions.push({
                        name: `${startMonthName} ${startYear} - ${endMonthName} ${endYear}`,
                        forecastCalendarId: cal.forecastCalendarId,
                      });
                      break; */

          default:
            break;
        }
      });
      const orderedOptions: any = [...quarterOptions, ...monthOptions, ...yearOptions, ...fullOptions];
      setTimePeriodOptions(orderedOptions);
      if (orderedOptions.length === 1) {
        setShowPeriods(false);
        setForecastCalendarId(orderedOptions[0].forecastCalendarId);
        setCurrentForecastYear(orderedOptions[0]?.year);
        localStorage.setItem('curYear', orderedOptions[0]?.year);
        localStorage.setItem('TimePeriod', orderedOptions[0].forecastCalendarId);
        if (!isParamData) {
          localStorage.setItem('previousCurrentYear', orderedOptions[0]?.year);
          localStorage.setItem('previousTimePeriod', orderedOptions[0].forecastCalendarId);
        }
      } else {
        setShowPeriods(true);
      }
    }
  }, [forecasts, isParamData, forecastId]);

  return (
    <Navbar variant="light" expand className="navbar-bg">
      <span
        className="sidebar-toggle d-flex"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <i className="hamburger align-self-center" />
      </span>

      {!isLeft && (
        <div className="row">
          <Form className="col">
            <InputGroup className="input-group-navbar flex-nowrap">
              <label className="col-form-label me-2">Forecast:</label>
              <NavbarSelect
                items={forecasts}
                scope="Forecast"
                placeholder="Select Forecast"
                isParamData={isParamData}
              />
            </InputGroup>
          </Form>

          <Form className="col">
            <InputGroup className="input-group-navbar flex-nowrap">
              <label className="col-form-label me-2">Region:</label>
              <TerritorySelect placeholder="Select District/Region" isParamData={isParamData} />
            </InputGroup>
          </Form>

          <Form className="col">
            <InputGroup className="input-group-navbar flex-nowrap" style={{ width: 'max-content' }}>
              <label className="col-form-label me-2">Time Period:</label>
              <NavbarSelect
                items={timePeriodOptions}
                disabled={!showPeriods}
                scope="TimePeriod"
                placeholder="Select Time Period"
                isParamData={isParamData}
              />
            </InputGroup>
          </Form>
        </div>
      )}
      <Navbar.Collapse>
        <Nav className="navbar-align">
          <NavbarUser />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
