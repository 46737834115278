export const capitalized = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const buildTreeOptionsFromTerritories = (territories: any[]) => {
  const treeOptions: any = territories.map((territory) => {
    return {
      key: territory.territoryId,
      label: capitalized(territory.name ?? (territory.shortName as string)),
      value: territory.territoryId,
      ...(territory?.regions?.length > 0 && { children: buildTreeOptionsFromTerritories(territory.regions) }),
    };
  });
  return treeOptions;
};

export const findTerritoryFromTree = (arr: any, territoryId: string) => {
  for (const obj of arr) {
    if (obj.territoryId === territoryId) {
      return obj;
    }
    if (obj.children) {
      const result: any = findTerritoryFromTree(obj.children, territoryId);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

export const findTerritoriesFromTreeByType = (arr: any, type: string) => {
  const result: any = [];

  const traverse = (nodes: any) => {
    nodes.forEach((node: any) => {
      if (node.type === type) {
        result.push(node);
      }
      if (node.children && node.children.length > 0) {
        traverse(node.children);
      }
    });
  };

  traverse(arr);
  return result;
};
