export class ConfigService {
  constructor() {}

  static GetConfig() {
    return {
      lowGrowthColor: 'bpp-danger',
      mediumGrowthColor: 'bpp-warning',
      highGrowthColor: 'bpp-success',
      growthMin: -1,
      growthMax: 1,
    };
  }
}
