import React, { useEffect, useState } from 'react';
import DownloadSelector from '../../../components/DownloadSelector';
import Select from 'react-select';
import { hideLoading, showLoading } from '../../../lib/uiService';
import AdminService from '../../../services/admin.service';
import useErrorHandling from '../../../hooks/useErrorHandling';
import { formatNum } from '../../../utils/formatNum';
import { toast } from 'react-toastify';
import { roundRetailPrice } from '../../../utils/calculation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import ForecastService from '../../../services/forecast.service';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

const PriceCalculator: React.FC = () => {
  const { setError } = useErrorHandling();
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedProduct, setSlectedProduct] = useState([]);
  const [productsWithHistory, setProductsWithHistory] = useState([]);
  const [openResultModal, setOpenResultModal] = useState(false);
  const [brands, setBrands] = useState([]);
  const [brandsWithLabels, setBrandsWithLabels] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [insuranceValue, setInsuranceValue] = useState('0.25');
  const [existingProducts, setExistingProducts] = useState([]);
  const [productsList, setProductsList] = useState<any>([]);
  const row: any = [];
  const downloadColumnHeaders = [
    'Name',
    'Warehouse',
    'COGs',
    'Freight',
    'ABV/FET',
    'Landed COGs',
    'Margin',
    'FOB',
    'Warehouse Fees',
    'PSI Fees',
    'Markup Margin',
    'Markup DA Margin',
    'Dist. Margin',
    'Dist. Price Cs',
    'Dist. Price Btl',
    'DA',
    'Retail Markup',
    '$ / Oz',
    'Retail Price',
    'SRP',
  ];

  useEffect(() => {
    showLoading();
    AdminService.GetWarehouses()
      .then(({ data }) => {
        setWarehouses(data);
      })
      .catch((error) => {
        console.log(error);
        setError({ status: error.response.status || 500 });
      });
    AdminService.GetLabelsWithBrand()
      .then(({ data }) => {
        const labelsData = data.labels.map((label: any) => {
          return {
            ...label,
            labelName: label.name,
            value: label.brandFamily.brand.brandId,
            label: label.brandFamily.brand.name + ' > ' + label.name,
          };
        });
        labelsData.unshift({ label: 'Brands', value: '' });
        setBrandsWithLabels(labelsData);
        hideLoading();
      })
      .catch((error) => {
        console.log(error);
        setError({ status: error.response.status || 500 });
      });

    ForecastService.GetBrands()
      .then(({ data }) => {
        const brands = data.brands.map((brand: any) => Object.assign({ label: brand.name, value: brand.brandId }));
        brands.unshift({ label: 'Brands', value: '' });
        setBrands(brands);
        hideLoading();
      })
      .catch((error) => {
        setError({ status: error?.response?.status || 500 });
        hideLoading();
      });
    AdminService.GetProductsAndLabels({
      page: 1,
      pageSize: Number.MAX_SAFE_INTEGER,
    })
      .then(({ data }) => {
        const finalData = data.products.map((item: any) => ({
          value: item.productId,
          ...item,
          label: item.description,
          postFob: item.productPrice?.price || 0,
        }));

        setExistingProducts(finalData);
        hideLoading();
      })
      .catch((error: any) => {
        console.log(error);
        setError({ status: error.response.status || 500 });
        hideLoading();
      });
  }, []);
  const handleInputChange = (index: number, field: string, value: any, isNumber: boolean) => {
    if (isNumber) {
      if (!/^-?\d*\.?\d*$/.test(value) || Number(value) < 0) {
        return;
      } else {
        value = value.length === 0 ? '' : value;
      }
    }

    const newProductsList = [...productsList];
    newProductsList[index][field] = value;
    updateValues(index, newProductsList, field);
  };

  const updateValues = (index: number, newProductsList: any, field: string) => {
    const { quantity, volume } = parseProductSpecificName(newProductsList[index].label);
    if (field === 'label' && (!quantity || !volume)) {
      setProductsList(newProductsList);
      return;
    }
    const insurance = Number(newProductsList[index].insurance) || Number(insuranceValue);
    const finalQuantity = Number(newProductsList[index].quantity) || quantity || 6;
    const finalVolume = Number(newProductsList[index].volume) || volume || 750;
    const warehouseFees =
      warehouses.find((item) => item.warehouseId === Number(newProductsList[index].warehouseId))?.shortName === 'NJ'
        ? 1.64
        : 1.97;
    const federalExciseTax =
      (Number(newProductsList[index].abv || 0) / 80) * 2.14 * finalQuantity * (finalVolume / 750);
    const landedCogs =
      Number(newProductsList[index].cogs || 0) +
      Number(newProductsList[index].freight || 0) +
      federalExciseTax +
      Number(insurance || 0);

    let preFeeFOB = landedCogs / (1 - 1 * (Number(newProductsList[index].margin || 0) / 100));
    const postFob =
      field === 'postFob'
        ? newProductsList[index].postFob
        : !newProductsList[index].isFrozen
          ? preFeeFOB + Number(warehouseFees || 0) + Number(newProductsList[index].psiFees || 0)
          : newProductsList[index].postFob;

    //if isFrozen is true adjust the margin to maintain the fixed postFob
    if (newProductsList[index].isFrozen) {
      preFeeFOB = postFob - Number(warehouseFees || 0) - Number(newProductsList[index].psiFees || 0);
      const newMargin = ((preFeeFOB - landedCogs) / preFeeFOB) * 100;
      newProductsList[index].margin = floorToTwoDecimals(newMargin);
    }

    const distributorBottlePrice =
      Number(newProductsList[index].distributorMargin || 0) === 0
        ? Math.ceil(postFob)
        : postFob / (1 - 1 * (Number(newProductsList[index].distributorMargin) / 100)) || '';
    const distributorCasePrice =
      (Number(distributorBottlePrice) - Number(newProductsList[index].distributorAllowance || 0)) / finalQuantity || '';
    const costPerOz = Number(distributorCasePrice) / 25.36 || '';
    const markupMarg =
      Number(postFob || 0) -
      Number(newProductsList[index].cogs || 0) -
      Number(federalExciseTax || 0) -
      Number(insurance || 0) -
      Number(newProductsList[index].freight || 0) -
      Number(newProductsList[index].psiFees || 0) -
      Number(warehouseFees || 0);
    const markupMargDa = Number(markupMarg || 0) - Number(newProductsList[index].federalExciseTax || 0) || '';
    const retailPrice =
      Number(distributorCasePrice) * (1 + 1 * (Number(newProductsList[index].retailMarkup) / 100)) || '';
    const suggestedRetailPrice = roundRetailPrice(Number(retailPrice)) || '';

    newProductsList[index].costPerOz = floorToTwoDecimals(costPerOz);
    newProductsList[index].markupMarg = floorToTwoDecimals(markupMarg);
    newProductsList[index].markupMargDa = floorToTwoDecimals(markupMargDa);
    newProductsList[index].distributorCasePrice = floorToTwoDecimals(distributorCasePrice);
    newProductsList[index].distributorBottlePrice = floorToTwoDecimals(distributorBottlePrice);
    newProductsList[index].retailPrice = floorToTwoDecimals(retailPrice);
    newProductsList[index].suggestedRetailPrice = floorToTwoDecimals(suggestedRetailPrice);
    newProductsList[index].federalExciseTax = floorToTwoDecimals(federalExciseTax);
    newProductsList[index].landedCogs = floorToTwoDecimals(landedCogs);
    newProductsList[index].preFeeFOB = floorToTwoDecimals(preFeeFOB);
    newProductsList[index].postFob = floorToTwoDecimals(Math.ceil(postFob));
    newProductsList[index].warehouseFees = floorToTwoDecimals(warehouseFees);
    setProductsList(newProductsList);
  };

  const floorToTwoDecimals = (value: any) => {
    const num = Number(value);
    const threshold = 1e-5;

    if (Math.abs(num) < threshold) {
      return 0;
    }

    return Math.floor(num * 100) / 100;
  };

  const parseProductSpecificName = (input: string) => {
    const regex = /(\d+)\/(\d+)[mM][lL]|(\d+)[mM][lL]\/(\d+)/;
    const match = input.match(regex);
    if (match) {
      const quantity = match[1] || match[4];
      const volume = match[2] || match[3];
      return { quantity: Number(quantity), volume: Number(volume) };
    }
    return { quantity: 0, volume: 0 };
  };

  const submitApprovals = () => {
    const productHasEmptyValue = productsList.some((obj) =>
      Object.entries(obj).some(([key, value]) => {
        return key !== 'labelId' && key !== 'bbpProductNumber' && key !== 'distributorAllowance' && value === '';
      }),
    );
    if (productHasEmptyValue) {
      setErrorMessage('All fields must be completed.');
    } else {
      showLoading();
      setErrorMessage('');
      AdminService.RequestApprovalForMultipleProducts({ products: productsList, insurance: insuranceValue })
        .then(({ data }) => {
          toast.success('Changes succesfully requested');
          if (data?.productsWithHistory.length !== 0) {
            setOpenResultModal(true);
            setProductsWithHistory(data?.productsWithHistory);
          }
          setProductsList([]);
          hideLoading();
        })
        .catch((error) => {
          hideLoading();
          console.log(error);
          setError({ status: error.response.status || 500 });
        });
    }
  };

  const getFreigthFees = (warehouseId, brandId, index) => {
    AdminService.GetFreightFees({ warehouseId, brandId })
      .then(({ data }) => {
        const newProductsList = [...productsList];
        newProductsList[index].freight = Number(data.fee);
        updateValues(index, newProductsList, 'freight');
      })
      .catch((error) => {
        console.log(error);
        setError({ status: error.response.status || 500 });
      });
  };

  return (
    <div className="container-fluid">
      <h3 className="text-dark mb-4">Product Price Calculator</h3>
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 text-nowrap">
              <div className="mb-3">
                <div className="input-group w-75">
                  <span className="input-group-text">Insurance</span>
                  <input
                    className="form-control"
                    type="text"
                    value={insuranceValue}
                    onChange={(e) => {
                      if (!/^-?\d*\.?\d*$/.test(e.target.value) || Number(e.target.value) < 0) {
                        return;
                      } else {
                        const newInsuranceValue = e.target.value;
                        const previousInsuranceValue = insuranceValue;
                        setProductsList((prevData) => {
                          return prevData.map((prod) => {
                            const prevInsuranceValue = Number(previousInsuranceValue) || 0;
                            const currentInsuranceValue = Number(newInsuranceValue) || 0;
                            const { quantity, volume } = parseProductSpecificName(prod.label);
                            const insurance =
                              Number(prod.insurance) || Number(currentInsuranceValue) - Number(prevInsuranceValue);
                            const finalQuantity = Number(prod.quantity) || quantity || 6;
                            const finalVolume = Number(prod.volume) || volume || 750;
                            const landedCogs = Number(prod.landedCogs || 0) + insurance;
                            const preFeeFOB = landedCogs / (1 - 1 * (Number(prod.margin || 0) / 100));
                            const postFob = preFeeFOB + Number(prod.warehouseFees || 0) + Number(prod.psiFees || 0);
                            const federalExciseTax =
                              (Number(prod.abv || 0) / 80) * 2.14 * finalQuantity * (finalVolume / 750);
                            const distributorCasePrice =
                              Number(prod.distributorMargin || 0) === 0
                                ? Math.ceil(postFob)
                                : postFob / (1 - 1 * (Number(prod.distributorMargin) / 100)) || '';
                            const distributorBottlePrice =
                              (Number(distributorCasePrice) - Number(prod.federalExciseTax || 0)) / finalQuantity || '';
                            const markupMarg =
                              Number(postFob || 0) -
                              Number(prod.cogs || 0) -
                              Number(federalExciseTax || 0) -
                              Number(insurance || 0) -
                              Number(prod.freight || 0) -
                              Number(prod.psiFees || 0) -
                              Number(prod.warehouseFees || 0);
                            return {
                              ...prod,
                              landedCogs,
                              preFeeFOB,
                              postFob,
                              psiFees: 1.97,
                              federalExciseTax,
                              distributorCasePrice,
                              distributorBottlePrice,
                              markupMarg: floorToTwoDecimals(markupMarg),
                            };
                          });
                        });
                        setInsuranceValue(newInsuranceValue.length === 0 ? '' : newInsuranceValue);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="d-flex">
                <Select
                  isSearchable={true}
                  value={selectedProduct}
                  onChange={async (option: any) => {
                    setSlectedProduct(option);
                    const productExist = productsList.some((item: any) => item.value === option.value);
                    if (!productExist) {
                      const list = [];
                      await AdminService.GetAvailableWarehousePerFreight({ brandId: option.brandId })
                        .then(({ data }) => {
                          data.warehouses.map((item) => {
                            list.push({
                              ...item,
                              value: item.warehouseId,
                              label: item.shortName,
                            });
                          });
                        })
                        .catch((error) => {
                          console.log(error);
                          setError({ status: error.response.status || 500 });
                        });
                      setProductsList([
                        ...productsList,
                        {
                          ...option,
                          psiFees: 1.97,
                          availableWarehouses: list,
                          warehouseId: '',
                          isFrozen: true,
                          isExistingProduct: true,
                        },
                      ]);
                    }
                  }}
                  placeholder={'Products'}
                  options={existingProducts}
                  styles={{
                    container: (baseStyles) => ({
                      ...baseStyles,
                      width: '250px',
                    }),
                    menu: (baseStyles) => ({
                      ...baseStyles,
                      zIndex: '1000',
                    }),
                  }}
                />
                <button
                  className="btn btn-secondary ms-3"
                  onClick={() => {
                    const finalQuantity = 6;
                    const landedCogs = Number(insuranceValue);
                    const preFeeFOB = landedCogs / (1 - 1 * (0 / 100));
                    const postFob = Math.ceil(preFeeFOB);
                    const distributorCasePrice = Math.ceil(postFob);
                    const distributorBottlePrice = Number(distributorCasePrice) / finalQuantity || '';
                    const markupMarg = Number(postFob || 0);
                    setProductsList([
                      ...productsList,
                      {
                        label: '',
                        landedCogs,
                        preFeeFOB,
                        postFob,
                        distributorBottlePrice,
                        distributorCasePrice,
                        warehouseId: warehouses[0].warehouseId,
                        psiFees: 1.97,
                        markupMarg,
                        isExistingProduct: false,
                      },
                    ]);
                  }}
                  type="button"
                >
                  Add New Product
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="text-md-end dataTables_filter" id="dataTable_filter-1">
                <DownloadSelector
                  row={row}
                  fileName="Product Calculator"
                  downloadColumnHeaders={downloadColumnHeaders}
                  prepareDataFunction={() => {
                    productsList.forEach((item: any) => {
                      row.push([
                        { v: item.label, t: 's' },
                        { v: item.warehouse, t: 's' },
                        { v: item.cogs, t: 's' },
                        { v: item.abv, t: 's' },
                        {
                          v: item.landedCogs,
                          t: 's',
                        },
                        {
                          v: item.margin,
                          t: 's',
                        },
                        {
                          v: item.fob,
                          t: 's',
                        },
                        { v: item.warehouseFees, t: 's' },
                        { v: item.psiFees, t: 's' },
                        {
                          v: item.markupMarg,
                          t: 's',
                        },
                        {
                          v: item.markupMargDa,
                          t: 's',
                        },
                        {
                          v: item.distributorMargin,
                          t: 's',
                        },
                        {
                          v: item.distributorCasePrice,
                          t: 's',
                        },
                        {
                          v: item.distributorBottlePrice,
                          t: 's',
                        },
                        {
                          v: item.da,
                          t: 's',
                        },
                        {
                          v: item.retailMarkup,
                          t: 's',
                        },
                        {
                          v: item.costPerOz,
                          t: 's',
                        },
                        {
                          v: item.retailPrice,
                          t: 's',
                        },
                        {
                          v: item.suggestedRetailPrice,
                          t: 's',
                        },
                      ]);
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="table-responsive" style={{ fontSize: '11px' }}>
            <div className="table-responsive table mt-2" id="dataTable-1" role="grid" aria-describedby="dataTable_info">
              <table className="table table-striped table-sm my-0" id="dataTable">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th className="text-center">Warehouse</th>
                    <th className="text-center">COGs</th>
                    <th className="text-center">Freight</th>
                    <th className="text-center">ABV/FET</th>
                    <th className="text-center">
                      Landed <br />
                      COGs
                    </th>
                    <th className="text-center">Margin</th>
                    <th className="text-center">FOB</th>
                    <th className="text-center">
                      Warehouse <br /> Fees
                    </th>
                    <th className="text-center">
                      PSI <br /> Fees
                    </th>
                    <th className="text-center">Markup</th>
                    <th className="text-center">
                      Dist. <br />
                      Margin
                    </th>
                    <th className="text-center">Dist. Price</th>
                    <th className="text-center">DA</th>
                    <th className="text-center">
                      Retail <br />
                      Markup
                    </th>
                    <th className="text-center">$ / Oz</th>
                    <th className="text-center">Retail Price</th>
                    <th className="text-center">SRP</th>
                  </tr>
                </thead>
                <tbody>
                  {productsList.map((prod: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td className="text-secondary">
                          <div className="d-flex" style={!prod.isExistingProduct ? { paddingTop: '30px' } : {}}>
                            <select
                              className="form-select"
                              style={{ width: '120px' }}
                              value={prod.brandId}
                              onChange={(option: any) => {
                                if (option.target.value) {
                                  const selectedBrand =
                                    (prod.isNewLabel ? brands : brandsWithLabels).find(
                                      (item) => item.value === option.target.value,
                                    ) || {};
                                  handleInputChange(index, 'brandId', option.target.value, false);
                                  if (!prod.isNewLabel) {
                                    handleInputChange(index, 'labelId', selectedBrand.labelId, false);
                                  }
                                  AdminService.GetAvailableWarehousePerFreight({ brandId: option.target.value })
                                    .then(({ data }) => {
                                      const list = data.warehouses.map((item) => ({
                                        ...item,
                                        value: item.warehouseId,
                                        label: item.shortName,
                                      }));
                                      const newProductsList = [...productsList];
                                      newProductsList[index].availableWarehouses = list;
                                      newProductsList[index].warehouseId = '';
                                      setProductsList(newProductsList);
                                    })
                                    .catch((error) => {
                                      console.log(error);
                                      setError({ status: error.response.status || 500 });
                                    });
                                }
                              }}
                            >
                              {(prod.isNewLabel ? brands : brandsWithLabels).map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <input
                              type="text"
                              style={{ width: '110px' }}
                              className="form-control"
                              value={prod.label}
                              placeholder="Description"
                              onChange={(e) => {
                                handleInputChange(index, 'description', e.target.value, false);
                                handleInputChange(index, 'label', e.target.value, false);
                              }}
                            />
                          </div>
                          {!prod.isExistingProduct && (
                            <div style={{ marginLeft: '2px', marginTop: '5px' }} className="d-flex align-items-center">
                              <label
                                className="form-label"
                                style={{ marginBottom: 0, marginRight: '5px' }}
                                htmlFor="isNewLabel"
                              >
                                Create New Label
                              </label>
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: 40, height: 20, cursor: 'pointer' }}
                                  role="switch"
                                  id="isNewLabel"
                                  checked={prod.isNewLabel}
                                  onClick={() => {
                                    handleInputChange(index, 'isNewLabel', !prod.isNewLabel, false);
                                    handleInputChange(index, 'brandId', '', false);
                                    handleInputChange(index, 'labelId', '', false);
                                  }}
                                />
                              </div>
                              {prod.isNewLabel && (
                                <OverlayTrigger
                                  placement={'right'}
                                  overlay={
                                    <Tooltip id={`tooltip-right`} className="tooltip-container">
                                      <div style={{ width: '400px', padding: '5px' }}>
                                        <div style={{ textAlign: 'left' }}>
                                          The new label will have the name of the product&apos;s description up to the
                                          product&apos;s quantity and volume specification.
                                        </div>
                                      </div>
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    style={{ fontSize: '15px' }}
                                    className="mx-3"
                                    icon={faQuestionCircle}
                                  />
                                </OverlayTrigger>
                              )}
                            </div>
                          )}
                        </td>
                        <td className="text-secondary">
                          <select
                            className="form-select"
                            style={{ minWidth: '70px' }}
                            value={prod.warehouseId}
                            onChange={(option: any) => {
                              handleInputChange(index, 'warehouseId', option.target.value, false);
                              if (prod.brandId && option.target.value !== '') {
                                getFreigthFees(option.target.value, prod.brandId, index);
                              }
                            }}
                          >
                            <option value={''}>-</option>
                            {prod?.availableWarehouses &&
                              prod?.availableWarehouses.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                        <td className="text-secondary" style={{ padding: '5px' }}>
                          <input
                            type="text"
                            style={{ width: '50px' }}
                            className="form-control"
                            value={prod.cogs}
                            onChange={(e) => {
                              handleInputChange(index, 'cogs', e.target.value, true);
                            }}
                          />
                        </td>
                        <td className="text-secondary" style={{ padding: '5px' }}>
                          <input
                            type="text"
                            style={{ width: '50px' }}
                            value={prod.freight}
                            className="form-control"
                            onChange={(e) => {
                              handleInputChange(index, 'freight', e.target.value, true);
                            }}
                          />
                        </td>
                        <td className="text-secondary" style={{ padding: '5px' }}>
                          <div style={{ marginTop: '14px' }}>
                            <label className="form-label" style={{ display: 'inline' }}>
                              ABV:{' '}
                            </label>
                            <input
                              type="text"
                              style={{
                                width: '35px',
                                fontSize: '11px',
                                paddingLeft: '5px',
                                paddingRight: '5px',
                                paddingTop: '3px',
                                paddingBottom: '3px',
                                display: 'inline',
                              }}
                              value={prod.abv}
                              className="form-control"
                              onChange={(e) => {
                                handleInputChange(index, 'abv', e.target.value, true);
                              }}
                            />
                            <br />
                            FET: {prod.federalExciseTax || '-'}
                          </div>
                        </td>
                        <td className="text-secondary" style={{ padding: '5px' }}>
                          ${formatNum(Number(prod.landedCogs || 0)) || '-'}
                        </td>
                        <td className="text-secondary" style={{ padding: '5px' }}>
                          <input
                            disabled={prod.isFrozen}
                            type="text"
                            style={{ width: '50px' }}
                            className="form-control"
                            value={prod.margin}
                            onChange={(e) => {
                              handleInputChange(index, 'margin', e.target.value, true);
                            }}
                          />
                        </td>
                        <td className="text-secondary" style={{ padding: '5px', minWidth: '80px' }}>
                          Post-Fees:
                          <div className="d-flex flex-row align-items-center">
                            <input
                              disabled={prod.isFrozen}
                              type="text"
                              style={{ width: '50px' }}
                              value={prod.postFob}
                              className="form-control me-2"
                              onChange={(e) => {
                                handleInputChange(index, 'postFob', e.target.value, true);
                              }}
                            />
                            <FontAwesomeIcon
                              onClick={() => {
                                const newProductsList = [...productsList];
                                newProductsList[index]['isFrozen'] = !newProductsList[index]['isFrozen'];
                                setProductsList(newProductsList);
                              }}
                              style={{ color: prod.isFrozen ? '#959595' : '#343434', cursor: 'pointer' }}
                              icon={prod.isFrozen ? faLock : faLockOpen}
                            />
                          </div>
                          <div style={{ width: '85px' }}>
                            Pre Fees: ${formatNum(Number(prod.preFeeFOB || 0)) || '-'}
                          </div>
                        </td>
                        <td className="text-secondary" style={{ padding: '5px' }}>
                          <input
                            type="text"
                            style={{ width: '50px' }}
                            value={prod.warehouseFees}
                            className="form-control"
                            onChange={(e) => handleInputChange(index, 'warehouseFees', e.target.value, true)}
                          />
                        </td>
                        <td className="text-secondary" style={{ padding: '5px' }}>
                          <input
                            type="text"
                            style={{ width: '50px' }}
                            className="form-control"
                            value={prod.psiFees}
                            onChange={(e) => handleInputChange(index, 'psiFees', e.target.value, true)}
                          />
                        </td>
                        <td className="text-secondary" style={{ padding: '5px' }}>
                          Markup: {prod.markupMarg || '-'}
                          <br />
                          Markup After DA: {prod.markupMargDa || '-'}
                        </td>
                        <td className="text-secondary" style={{ padding: '5px' }}>
                          <input
                            type="text"
                            style={{ width: '50px' }}
                            value={prod.distributorMargin}
                            className="form-control"
                            onChange={(e) => handleInputChange(index, 'distributorMargin', e.target.value, true)}
                          />
                        </td>
                        <td className="text-secondary" style={{ padding: '5px' }}>
                          Cs: ${formatNum(Number(prod.distributorCasePrice || 0)) || '-'}
                          <br />
                          Btl: ${formatNum(Number(prod.distributorBottlePrice || 0)) || '-'}
                        </td>
                        <td className="text-secondary" style={{ padding: '5px' }}>
                          <input
                            type="text"
                            style={{ width: '50px' }}
                            value={prod.distributorAllowance}
                            className="form-control"
                            onChange={(e) => handleInputChange(index, 'distributorAllowance', e.target.value, true)}
                          />
                        </td>
                        <td className="text-secondary" style={{ padding: '5px' }}>
                          <input
                            type="text"
                            style={{ width: '50px' }}
                            value={prod.retailMarkup}
                            className="form-control"
                            onChange={(e) => handleInputChange(index, 'retailMarkup', e.target.value, true)}
                          />
                        </td>
                        <td className="text-secondary" style={{ padding: '5px' }}>
                          ${formatNum(Number(prod.costPerOz || 0)) || '-'}
                        </td>
                        <td className="text-secondary" style={{ padding: '5px' }}>
                          ${formatNum(Number(prod.retailPrice || 0)) || '-'}
                        </td>
                        <td className="text-secondary" style={{ padding: '5px' }}>
                          ${formatNum(Number(prod.suggestedRetailPrice || 0)) || '-'}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div>
              <p className="text-danger" style={{ float: 'right' }}>
                {errorMessage}
              </p>
            </div>
            <div className="col">
              <button
                className="btn btn-primary"
                type="button"
                style={{ float: 'right' }}
                disabled={productsList.length === 0}
                onClick={submitApprovals}
              >
                Request Approvals
              </button>
            </div>
          </div>
          <Modal show={openResultModal} onHide={() => setOpenResultModal(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Products with pending approval</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {productsWithHistory.map((str, index) => (
                <h6 key={index}>
                  The product &quot;{str}&quot; has pending approvals and cannot be updated until all changes have been
                  resolved.
                </h6>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {
                  setOpenResultModal(false);
                }}
              >
                Accept
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default PriceCalculator;
