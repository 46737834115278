import { hideLoading } from '../../../lib/uiService';
import { findTerritoryFromTree } from '../../../utils/buildTreeOption';

export const handleTerritoryChangeWarning = (message: string, setWarningMessage: (x: string) => void) => {
  setWarningMessage(message);
  hideLoading();
};

export const findFirstChild = (parent: any, excludeId: string) => {
  return parent.children.find((child: any) => child.territoryId !== excludeId);
};

export const getTerritoryObject = (territories: any, territoryId: string) => {
  return findTerritoryFromTree(territories, territoryId);
};
export const updateParentIdForState = async (
  territory: any,
  selectedTerritory: any,
  parentOfParentLevelOne: any,
  parentOfParentLevelTwo: any,
) => {
  switch (selectedTerritory.type) {
    case 'Region':
      territory.parentId = parentOfParentLevelOne.data.parentId;
      break;
    case 'Division':
      territory.parentId = parentOfParentLevelTwo.data.parentId;
      break;
    case 'National':
      territory.parentId = null;
      break;
    default:
      break;
  }
};

const errorMessage = 'There is no available parent territory for this change.';

export const updateParentIdForRegion = async (
  territories: any,
  territory: any,
  selectedTerritory: any,
  objectFromNode: any,
  setWarningMessage: (x: string) => void,
) => {
  if (objectFromNode.children.length !== 0) {
    handleTerritoryChangeWarning('There is no available parent territory for this change', setWarningMessage);
    return;
  }

  switch (selectedTerritory.type) {
    case 'State': {
      const divisionParent = getTerritoryObject(territories, selectedTerritory.parentId);
      const firstRegion = findFirstChild(divisionParent, selectedTerritory.territoryId);
      if (firstRegion) {
        territory.parentId = firstRegion.territoryId;
        setWarningMessage('');
      } else {
        handleTerritoryChangeWarning(errorMessage, setWarningMessage);
      }
      break;
    }
    case 'Division':
      territory.parentId = objectFromNode.parentId;
      break;
    case 'National':
      territory.parentId = null;
      break;
    default:
      break;
  }
};

export const updateParentIdForDivision = async (
  territories: any,
  territory: any,
  selectedTerritory: any,
  objectFromNode: any,
  setWarningMessage: (x: string) => void,
) => {
  if (objectFromNode.children.length !== 0) {
    handleTerritoryChangeWarning('The territory cannot have children in order to change types', setWarningMessage);
    return;
  }

  switch (selectedTerritory.type) {
    case 'State': {
      const nationalParent = getTerritoryObject(territories, selectedTerritory.parentId);
      const firstDivision = findFirstChild(nationalParent, selectedTerritory.territoryId);
      const firstRegion = firstDivision.children[0];
      if (firstRegion) {
        territory.parentId = firstRegion.territoryId;
        setWarningMessage('');
      } else {
        handleTerritoryChangeWarning(errorMessage, setWarningMessage);
      }
      break;
    }
    case 'Region': {
      const nationalParent = getTerritoryObject(territories, selectedTerritory.parentId);
      const firstDivision = findFirstChild(nationalParent, selectedTerritory.territoryId);
      if (firstDivision) {
        territory.parentId = firstDivision.territoryId;
        setWarningMessage('');
      } else {
        handleTerritoryChangeWarning(
          'The National of this territory does not have an extra Division. Please, use the manual toggle and drop if you wish to change the type of this territory to State.',
          setWarningMessage,
        );
      }
      break;
    }
    case 'National':
      territory.parentId = null;
      break;
    default:
      break;
  }
};

export const updateParentIdForNational = async (
  territories: any,
  territory: any,
  selectedTerritory: any,
  objectFromNode: any,
  setWarningMessage: (x: string) => void,
) => {
  if (objectFromNode.children.length !== 0) {
    handleTerritoryChangeWarning('The territory cannot have children in order to change types', setWarningMessage);
    return;
  }

  switch (selectedTerritory.type) {
    case 'State': {
      const nationalParent = territories.find(
        (item: any) =>
          item.parentId === null && item.territoryId !== selectedTerritory.territoryId && item.children.length !== 0,
      );
      const firstDivision = nationalParent?.children[0];
      const firstRegion = firstDivision.children[0];
      if (firstRegion) {
        territory.parentId = firstRegion.territoryId;
        setWarningMessage('');
      } else {
        handleTerritoryChangeWarning(errorMessage, setWarningMessage);
      }
      break;
    }
    case 'Region': {
      const nationalParent = territories.find(
        (item: any) =>
          item.parentId === null && item.territoryId !== selectedTerritory.territoryId && item.children.length !== 0,
      );
      const firstDivision = nationalParent?.children[0];
      if (firstDivision) {
        territory.parentId = firstDivision.territoryId;
        setWarningMessage('');
      } else {
        handleTerritoryChangeWarning(errorMessage, setWarningMessage);
      }
      break;
    }
    case 'Division': {
      const nationalParent = territories.find(
        (item: any) =>
          item.parentId === null && item.territoryId !== selectedTerritory.territoryId && item.children.length !== 0,
      );
      if (nationalParent) {
        territory.parentId = nationalParent.territoryId;
        setWarningMessage('');
      } else {
        handleTerritoryChangeWarning(errorMessage, setWarningMessage);
      }
      break;
    }
    default:
      break;
  }
};
