import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

import reduceChildRoutes from './reduceChildRoutes';
import { IDashboardItem } from './dashboardItems';

interface ISidebarNavList {
  items: IDashboardItem[];
}

const SidebarNavList: React.FC<ISidebarNavList> = (props) => {
  const { items } = props;
  const router = useLocation();
  const currentRoute = router.pathname;

  const childRoutes = items.reduce(
    (elements: ReactElement[], item: IDashboardItem) => reduceChildRoutes({ elements, item, currentRoute }),
    [],
  );

  return <ul className="sidebar-nav">{childRoutes}</ul>;
};

export default SidebarNavList;
