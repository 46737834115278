import Axios from '../lib/axios';

interface IAnalysisRequest {
  years?: string[];
  forecastIds?: string[];
  brands?: string[];
  territoryIds: string[] | null;
  queryType: string;
  subQueryType: string;
}

const GetSalesAnalysis = (body: IAnalysisRequest) => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/analysis', {
    params: body,
  });
};

const AnalysisService = {
  GetSalesAnalysis,
};

export default AnalysisService;
