import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/scss/_app.scss';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { SidebarProvider } from './providers/SidebarProvider';
import { AuthProvider } from './providers/AuthProvider';
import { ForecastProvider } from './providers/ForecastProvider';
import Loading from './components/Loading';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://1c244ad7c48701a1c38d305bf0cddf2e@o4507029329608704.ingest.us.sentry.io/4507029435252738',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <AuthProvider>
      <ForecastProvider>
        <SidebarProvider>
          <App />
          <Loading />
        </SidebarProvider>
      </ForecastProvider>
    </AuthProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
