import axios from 'axios';

const Axios = axios.create({
  responseType: 'json',
});

Axios.interceptors.request.use((options) => {
  options.withCredentials = true;
  return options;
});

Axios.interceptors.response.use((response) => response.data);

export type DebounceOption = {
  debounceTime?: number;
};

export const getWithDebouncer = (option?: DebounceOption) => {
  const cache = {};
  const debounceTime = option?.debounceTime || 5000;
  return (url, opt) => {
    const key = JSON.stringify({ url, opt });
    if (!cache[key]) {
      cache[key] = {};
      cache[key].promise = new Promise((resolve, reject) => {
        cache[key].resolve = resolve;
        cache[key].reject = reject;
        setTimeout(() => {
          cache[key] = null;
        }, debounceTime);
        Axios.get(url, opt).then(resolve).catch(reject);
      });
    }
    return cache[key].promise;
  };
};

export default Axios;
