import { faCircleXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import FeeHistory from './History';
import { FeeTypes } from '../../../utils/roles';
import { v4 as uuidv4 } from 'uuid';

const WarehouseFees = ({
  warehouseFees,
  setWarehouseFees,
  warehouses,
  createdWarehouseFees,
  setCreatedWarehouseFees,
  updatedWarehouseFees,
  setUpdatedWarehouseFees,
  history,
  submit,
  hasWarehouseHistory,
  deletedWarehouseFees,
  setDeletedWarehouseFees,
}: {
  warehouseFees: any;
  setWarehouseFees: (x: any) => void;
  warehouses: any;
  createdWarehouseFees;
  setCreatedWarehouseFees;
  updatedWarehouseFees;
  setUpdatedWarehouseFees;
  history: any;
  submit: (x: boolean) => void;
  hasWarehouseHistory: boolean;
  deletedWarehouseFees;
  setDeletedWarehouseFees;
}) => {
  const [WarehouseTabIndex, setWarehouseTabIndex] = useState(1);
  const [newWarehouseFee, setNewWarehouseFee] = useState({
    warehouse: '',
    fee: '',
    warehouseFeeId: '',
    warehouseId: '',
    feeType: FeeTypes.warehouse,
  });

  const availableWarehouses = warehouses.filter(
    (warehouse) => !warehouseFees.some((fee) => fee.warehouseId === warehouse.warehouseId),
  );

  const handleFieldChange = (warehouseFeeId: string, newValue: any, wasCreated: boolean, prevData: any) => {
    const regex = /^(\d+)?(\.\d{0,2})?$/;

    if (regex.test(newValue)) {
      const existingChange = warehouseFees.find((change: any) => change.warehouseFeeId === warehouseFeeId);

      if (existingChange) {
        const updatedChanges = warehouseFees.map((change: any) =>
          change.warehouseFeeId === warehouseFeeId ? { ...change, fee: newValue } : change,
        );
        setWarehouseFees(updatedChanges);

        if (wasCreated) {
          const existingChangeCreatedData = createdWarehouseFees.find(
            (change: any) => change.warehouseFeeId === warehouseFeeId,
          );

          if (existingChangeCreatedData) {
            const updatedCreatedChanges = createdWarehouseFees.map((change: any) =>
              change.warehouseFeeId === warehouseFeeId ? { ...change, fee: newValue } : change,
            );
            setCreatedWarehouseFees(updatedCreatedChanges);
          } else {
            setCreatedWarehouseFees([...createdWarehouseFees, { ...existingChange, fee: newValue }]);
          }
          const filteredDeletedData = deletedWarehouseFees.filter(
            (item1) => !createdWarehouseFees.some((item2) => item1.warehouseFeeId === item2.warehouseFeeId),
          );
          setDeletedWarehouseFees(filteredDeletedData);
        } else {
          const existingChangeEditedData = updatedWarehouseFees.find(
            (change: any) => change.warehouseFeeId === warehouseFeeId,
          );

          if (existingChangeEditedData) {
            const updatedEditedChanges = updatedWarehouseFees.map((change: any) =>
              change.warehouseFeeId === warehouseFeeId ? { ...change, fee: newValue, actionType: 'Updated' } : change,
            );
            setUpdatedWarehouseFees(updatedEditedChanges);
          } else {
            setUpdatedWarehouseFees([
              ...updatedWarehouseFees,
              { ...existingChange, fee: newValue, actionType: 'Updated' },
            ]);
          }
        }
      } else {
        setWarehouseFees([...warehouseFees, { ...prevData, fee: newValue }]);
      }
    }
  };

  const addNewWarehouseFees = () => {
    if (
      (newWarehouseFee.warehouse !== '' || newWarehouseFee.feeType !== FeeTypes.warehouse) &&
      newWarehouseFee.fee !== '' &&
      Number(newWarehouseFee.fee) !== 0
    ) {
      const warehouseFeeId = uuidv4();
      setWarehouseFees((prevData) => {
        const newData = [
          {
            ...newWarehouseFee,
            warehouseFeeId: warehouseFeeId,
            warehouseId: newWarehouseFee.feeType === FeeTypes.warehouse ? newWarehouseFee.warehouseId : '',
            wasCreated: true,
          },
          ...prevData,
        ];
        return newData;
      });
      setCreatedWarehouseFees((prevData) => {
        const data = prevData;
        data.push({ ...newWarehouseFee, warehouseFeeId, wasCreated: true });
        return data;
      });
      setNewWarehouseFee({
        warehouse: '',
        fee: '',
        warehouseFeeId: '',
        warehouseId: '',
        feeType: FeeTypes.warehouse,
      });
    }
  };

  return (
    <div className="col">
      <div>
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item" role="presentation">
            <a
              className={`nav-link ${WarehouseTabIndex === 1 && 'active'}`}
              role="tab"
              data-bs-toggle="tab"
              aria-selected={WarehouseTabIndex === 1}
              onClick={() => setWarehouseTabIndex(1)}
            >
              Warehouse Fees
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className={`nav-link ${WarehouseTabIndex === 2 && 'active'}`}
              role="tab"
              data-bs-toggle="tab"
              aria-selected={WarehouseTabIndex === 2}
              onClick={() => setWarehouseTabIndex(2)}
            >
              Warehouse History
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div className={`tab-pane ${WarehouseTabIndex == 1 && 'active show'}`} role="tabpanel" id="tab-1">
            <div className="row mt-3">
              <div className="col">
                <div className="table-responsive" style={{ height: '358px', overflowY: 'scroll' }}>
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Warehouse</th>
                        <th>Fees</th>
                      </tr>
                    </thead>
                    <tbody>
                      {warehouseFees.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ width: '50%' }}>
                              {item.feeType === FeeTypes.warehouse
                                ? 'Warehouse'
                                : item.feeType === FeeTypes.psi
                                  ? 'PSI'
                                  : 'Insurance'}
                            </td>
                            <td style={{ width: '50%' }}>
                              {item.feeType === FeeTypes.warehouse ? item.warehouse : '-'}
                            </td>
                            <td className="d-flex">
                              <input
                                type="text"
                                value={item.fee}
                                onChange={(event) =>
                                  handleFieldChange(item.warehouseFeeId, event.target.value, item.wasCreated, item)
                                }
                              ></input>
                              {item.feeType === FeeTypes.warehouse ? (
                                <button
                                  className="btn btn-danger mx-2"
                                  type="button"
                                  onClick={() => {
                                    const feeExist = deletedWarehouseFees.some(
                                      (subItem: any) => subItem.warehouseId === item.warehouseId,
                                    );
                                    if (!feeExist) {
                                      setDeletedWarehouseFees(
                                        item.wasCreated
                                          ? deletedWarehouseFees
                                          : (prevItems: any) => [...prevItems, { ...item, actionType: 'Deleted' }],
                                      );
                                      setUpdatedWarehouseFees(
                                        updatedWarehouseFees.filter((item1) => item1.warehouseId !== item.warehouseId),
                                      );
                                      setCreatedWarehouseFees(
                                        createdWarehouseFees.filter((item1) => item1.warehouseId !== item.warehouseId),
                                      );
                                    }
                                    setWarehouseFees((prevItems: any) =>
                                      prevItems.filter((subItem: any) => subItem.warehouseId !== item.warehouseId),
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon icon={faCircleXmark} style={{ fontSize: '13px' }} />
                                </button>
                              ) : null}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4">
                <select
                  className="form-select"
                  value={newWarehouseFee.feeType}
                  onChange={(e) => {
                    setNewWarehouseFee({
                      ...newWarehouseFee,
                      feeType: Number(e.target.value),
                    });
                  }}
                >
                  <option value={FeeTypes.warehouse}>Warehouse Fee</option>
                  {!warehouseFees.some((item) => item.feeType === FeeTypes.psi) && (
                    <option value={FeeTypes.psi}>PSI Fee</option>
                  )}
                  {!warehouseFees.some((item) => item.feeType === FeeTypes.insurance) && (
                    <option value={FeeTypes.insurance}>Insurance Fee</option>
                  )}
                </select>
              </div>
              <div className="col-xl-4">
                <select
                  className="form-select"
                  value={newWarehouseFee.warehouse}
                  disabled={newWarehouseFee.feeType === FeeTypes.insurance || newWarehouseFee.feeType === FeeTypes.psi}
                  onChange={(e) => {
                    setNewWarehouseFee({
                      ...newWarehouseFee,
                      warehouse: e.target.value,
                      warehouseId: warehouses.find((item) => item.name === e.target.value).warehouseId,
                    });
                  }}
                >
                  <option value={''}>Select Warehouse</option>
                  {availableWarehouses.map((item, index) => {
                    return (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-xl-2">
                <input
                  type="text"
                  placeholder="Fee"
                  value={newWarehouseFee.fee}
                  className="form-control me-2"
                  onChange={(e) => {
                    const newValue: any = e.target.value;
                    const regex = /^(\d+)?(\.\d{0,2})?$/;

                    if (regex.test(newValue)) {
                      setNewWarehouseFee({ ...newWarehouseFee, fee: String(newValue) });
                    }
                  }}
                ></input>
              </div>
              <div className="col">
                <button className="btn btn-primary" type="button" onClick={addNewWarehouseFees}>
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            </div>
          </div>
          <div className={`tab-pane ${WarehouseTabIndex == 2 && 'active show'}`} role="tabpanel" id="tab-2">
            <FeeHistory isFreight={false} history={history} />
          </div>
          <hr />
        </div>
      </div>
      <button className="btn float-end btn-primary" type="button" onClick={() => submit(false)}>
        {hasWarehouseHistory ? 'Update request' : 'Submit for Approval'}
      </button>
    </div>
  );
};

export default WarehouseFees;
