import React from 'react';

export default function TableBody(props: { renderRow: (row: any, index: number) => React.ReactNode; rows: any[] }) {
  const { renderRow, rows, ...restProps } = props;
  return (
    <tbody {...restProps}>
      {rows?.map((row, index) => <React.Fragment key={index}>{renderRow(row, index)}</React.Fragment>)}
    </tbody>
  );
}
