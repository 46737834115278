import React from 'react';

interface Option {
  label: string;
  value: string;
  disabled?: boolean;
}

interface IBasicRadioToggleButtonGroup {
  groupName: string;
  options: Option[];
  onSelectOption: (x: any) => void;
  selectedValue: string;
}

const BasicRadioToggleButtonGroup: React.FC<IBasicRadioToggleButtonGroup> = ({
  groupName,
  options,
  onSelectOption,
  selectedValue,
}) => {
  return (
    <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
      {options.map(({ label, value, disabled }) => (
        <React.Fragment key={value}>
          <input
            type="radio"
            className="btn-check"
            name={groupName}
            id={value}
            autoComplete="off"
            disabled={disabled}
            value={value}
            onChange={(e) => {
              onSelectOption(e.target.value);
            }}
            checked={selectedValue == value}
          />
          <label className="btn btn-outline-primary" htmlFor={value}>
            {label}
          </label>
        </React.Fragment>
      ))}
    </div>
  );
};

export default BasicRadioToggleButtonGroup;
