import Axios from '../lib/axios';

const LoginCheck = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/login_check');
};

const GetRedirectingRoutes = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/redirect-routes');
};

const LogOut = () => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/logout');
};

const AuthService = {
  LoginCheck,
  LogOut,
  GetRedirectingRoutes,
};

export default AuthService;
