import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { hideLoading } from '../lib/uiService';
import { usePrevLocation } from './usePrevLocation';

interface ErrorData {
  status: number;
}

const useErrorHandling = () => {
  const [error, setError] = useState<ErrorData | null>(null);
  const location = useLocation();
  usePrevLocation(location);
  const navigate = useNavigate();

  useEffect(() => {
    const handleRedirect = () => {
      if (error) {
        localStorage.setItem('status', error.status as unknown as string);
        hideLoading();
        switch (error.status) {
          case 404:
            navigate('/404');
            break;
          case 403:
            navigate('/403');
            break;
          case 401:
            navigate('/login');
            break;
          case 500:
            navigate('/500');
            break;
          default:
            break;
        }
      }
    };

    handleRedirect();

    return () => {
      setError(null);
    };
  }, [error, history]);

  return { error, setError };
};

export default useErrorHandling;
