export class HistoryModel {
  historyId: any = '';
  history: any;
  histories: any[];
  forecastBudgetsMap: any = {};
  forecastProductsMap: any = {};

  constructor(history: any, histories: any[]) {
    this.history = history;
    this.historyId = history?.historyId;
    this.histories = histories;
  }
}

export default HistoryModel;
