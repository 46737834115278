import React from 'react';
import Select from 'react-select';
import AdminService from '../../../services/admin.service';
import useErrorHandling from '../../../hooks/useErrorHandling';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { getNonRepeatedSubstring } from '../../../utils/calculation';

const LabelForm = ({
  editable,
  setEditable,
  errorMessage,
  setErrorMessage,
  setIsLabel,
  labels,
  selectedObject,
  setSelectedObject,
  brandFamilies,
  fetchProducts,
  disabled,
}: {
  editable: boolean;
  disabled: boolean;
  selectedObject: any;
  errorMessage: string;
  setErrorMessage: (x: string) => void;
  labels: any;
  setEditable: (x: boolean) => void;
  setIsLabel: (x: boolean) => void;
  setSelectedObject: (x: any) => void;
  brandFamilies: any;
  fetchProducts: () => void;
}) => {
  const { setError } = useErrorHandling();

  const createLabel = () => {
    if (selectedObject.brandFamilyId === undefined) {
      setErrorMessage('A Brand Family must be selected');
    } else {
      AdminService.CreateLabel(selectedObject)
        .then(() => {
          setSelectedObject({});
          fetchProducts();
          toast.success('Label successfully Created');
        })
        .catch((error) => {
          console.log(error);
          setError({ status: error.response.status || 500 });
        });
    }
  };
  const createProduct = () => {
    setSelectedObject({
      brandFamily: selectedObject.brandFamily,
      brandFamilyId: selectedObject.brandFamilyId,
      label: selectedObject.label,
      labelId: selectedObject.labelId,
      brandId: selectedObject.brandId,
      labelIsActive: selectedObject.labelIsActive,
      productIsActive: true,
      labelSpecificName: getNonRepeatedSubstring(selectedObject.label, selectedObject.brand),
      product: '',
      productId: '',
      prevProductName: `${selectedObject.brand as string} ${selectedObject.label as string}`,
      productSpecificName: '',
    });
    setIsLabel(false);
    setEditable(false);
  };
  const editLabel = () => {
    AdminService.UpdateLabel(selectedObject)
      .then(() => {
        setEditable(false);
        setSelectedObject({});
        fetchProducts();
        toast.success('Label successfully Updated');
      })
      .catch((error) => {
        console.log(error);
        setError({ status: error.response.status || 500 });
      });
  };
  const selectedOption = brandFamilies?.find((option: any) => option.value === selectedObject?.brandFamilyId) || null;

  return (
    <div className="card shadow mb-[40px] p-0">
      <div className="card-header d-flex justify-content-between">
        <h5 className="text-primary m-0 fw-bold pt-1 pb-1 d-flex align-items-center">
          <FontAwesomeIcon icon={faBox} className="me-2" style={{ fontSize: '30px' }} />
          {editable ? 'Edit' : 'Create'} Label
        </h5>
        {editable && (
          <div className="pt-2 px-3 cursor-pointer">
            <FontAwesomeIcon
              icon={faXmark}
              onClick={() => {
                setEditable(false);
                setSelectedObject({ labelIsActive: true });
                setErrorMessage('');
              }}
            />
          </div>
        )}
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col ps-3 col-3">
            <label className="form-label">Label Name</label>
            <div className="d-flex">
              <>
                <input value={selectedObject?.brand ?? ''} disabled={true} type="text" className="w-50" />
                <input
                  disabled={disabled}
                  defaultValue={''}
                  value={selectedObject?.labelSpecificName ?? ''}
                  onChange={(e) => setSelectedObject({ ...selectedObject, labelSpecificName: e.target.value })}
                  type="text"
                  className="w-50"
                />
              </>
            </div>
          </div>
          <div className="col ps-4 col-3">
            <label className="form-label">Brand Family</label>
            <div>
              <Select
                isDisabled={disabled}
                isSearchable={true}
                defaultInputValue=""
                value={selectedOption}
                onChange={(option: any) => {
                  setSelectedObject({ ...selectedObject, brandFamilyId: option.value, brand: option.brand.name });
                }}
                options={brandFamilies}
                className="w-100"
                styles={{
                  container: (baseStyles) => ({
                    ...baseStyles,
                    width: '180px',
                  }),
                  control: (baseStyles) => ({
                    ...baseStyles,
                    minHeight: '27px',
                    borderColor: 'rgb(118, 118, 118)',
                  }),
                  valueContainer: (baseStyles) => ({
                    ...baseStyles,
                    height: '27px',
                    alignItems: 'normal',
                  }),
                  indicatorsContainer: (baseStyles) => ({
                    ...baseStyles,
                    height: '27px',
                  }),
                }}
              />
            </div>
          </div>
          <div className="col ps-4 col-3">
            <label className="form-label" style={{ paddingLeft: '3px' }} htmlFor="isActive">
              Active
            </label>
            <div className="form-check form-switch">
              <input
                disabled={disabled}
                className="form-check-input"
                type="checkbox"
                style={{ width: 40, height: 20, cursor: 'pointer' }}
                role="switch"
                id="isActive"
                checked={selectedObject?.labelIsActive}
                onClick={() => setSelectedObject({ ...selectedObject, labelIsActive: !selectedObject.labelIsActive })}
              />
            </div>
          </div>
        </div>
        <div className="row align-items-end align-content-end align-self-end">
          <p className="text-danger">{errorMessage}</p>
          <div className="col align-items-end align-content-end align-self-end">
            {editable && (
              <>
                <button className="btn btn-secondary" disabled={disabled} onClick={createProduct} type="button">
                  <FontAwesomeIcon icon={faPlus} className="me-1" style={{ fontSize: '13px' }} />
                  Create New Product
                </button>
              </>
            )}
            <button
              disabled={disabled}
              className="btn btn-primary float-end"
              type="button"
              style={{ textAlign: 'right' }}
              onClick={() => {
                if (!selectedObject?.labelSpecificName || selectedObject.labelSpecificName === '') {
                  setErrorMessage('A specific name must be inputed for the label.');
                } else {
                  const labelNameMatches = labels
                    .filter((item: any) => item.labelId !== selectedObject.labelId)
                    .find(
                      (item: any) =>
                        item.labelName.toLowerCase() ===
                        `${selectedObject?.brand.toLowerCase() as string} ${selectedObject?.labelSpecificName.toLowerCase() as string}`,
                    );
                  if (labelNameMatches) {
                    setErrorMessage('The label has to have a unique name.');
                  } else {
                    if (editable) {
                      editLabel();
                    } else {
                      createLabel();
                    }
                  }
                }
              }}
            >
              {editable ? 'Save' : 'Create'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabelForm;
