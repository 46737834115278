import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage, useFormikContext } from 'formik';
import { object, string, date, ref } from 'yup';
import { faCircleXmark, faCopy, faXmark } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Select } from '../../components/Select';
import AdminService from '../../services/admin.service';
import ForecastService from '../../services/forecast.service';
import { ForecastMethod, ForecastType } from '../../lib/types';
import { hideLoading, showLoading } from '../../lib/uiService';
import { useForecast } from '../../providers/ForecastProvider';
import { IForecastToUpdate, IPageToShow } from '.';
import { MONTHS } from '../../constants';

export interface IForecastForm {
  name: string;
  methodAndType: string;
  startDate: Date | string;
  endDate: Date | string;
  calculationPeriod: string;
  weights: any;
  periods: IPeriod;
  lookbackEndDate: Date | string;
  lookbackStartDate: Date | string;
  forecastId?: string;
  lookbackPeriod?: string;
  forecastPeriod?: string;
  forecastCalendars?: any;
  status: string;
  salesDataSource?: string;
}

interface INewForecast {
  pageToShow: IPageToShow;
  asyncForecast: boolean;
  setPageToShow: (x: IPageToShow) => void;
  forecastToUpdate: IForecastToUpdate;
  setFetchAgain: (x: boolean) => void;
  setOpenModal: (x: boolean) => void;
  setForecastToUpdate: (x: any) => void;
}

interface IPeriod {
  [key: string]: any;
}

const ForecastSchema = object().shape({
  name: string().required('Required'),
  methodAndType: string().required('Required'),
  startDate: date().required('Required'),
  endDate: date().required('Required').min(ref('startDate'), 'End Date must be greater than Start Date'),
  forecastPeriod: string().required('Required'),
  lookbackEndDate: date()
    .required('Required')
    .when('startDate', (startDate, schema) => {
      return startDate && schema.max(startDate, 'Lookback end date cannot be greater than Forecast start date');
    }),
  lookbackStartDate: date()
    .required('Required')
    .when('lookbackEndDate', (lookbackEndDate, schema) => {
      return (
        lookbackEndDate && schema.max(lookbackEndDate, 'Lookback start date cannot be greater than Lookback end date')
      );
    }),
  lookbackPeriod: string().required('Required'),
  calculationPeriod: string().required('Required'),
});

const calcularionPeriodLabels = [
  {
    label: 'Quarterly',
    value: '4',
  },
  {
    label: 'Monthly',
    value: '12',
  },
];

const methodTypes = {
  linearWeighted: 'Linear Weighted',
  lienarUnweighted: 'Linear Unweighted',
};

const ForecastForm: React.FC<{
  pageToShow: IPageToShow;
  valuesToUpdate: IForecastForm;
  forecastId: string;
  setFetchAgain: (x: boolean) => void;
  setPageToShow: (x: IPageToShow) => void;
  setForecastToUpdate: (x: any) => void;
}> = ({ pageToShow, valuesToUpdate, forecastId, setFetchAgain, setPageToShow, setForecastToUpdate }) => {
  const { setValues, values, validateForm } = useFormikContext<IForecastForm>();
  const [disableDates, setDisableDates] = useState(true);
  const [disableLookbackDates, setDisableLookbackDates] = useState(true);
  const [weightedValuesError, setWeightedValuesError] = useState(false);
  const [weightedTotal, setWeightedTotal] = useState(0);
  const [isMethodWeighted, setIsMethodWeighted] = useState(true);
  const [periods, setPeriods] = useState<IPeriod>({
    p1: {
      label: `${MONTHS[0]} - ${MONTHS[2]}`,
      value: '',
    },
    p2: {
      label: `${MONTHS[3]} - ${MONTHS[5]}`,
      value: '',
    },
    p3: {
      label: `${MONTHS[6]} - ${MONTHS[8]}`,
      value: '',
    },
    p4: {
      label: `${MONTHS[9]} - ${MONTHS[11]}`,
      value: '',
    },
  });
  const [isOpen, setIsOpen] = useState(false);
  const [forecastName, setForecastName] = useState('');

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const { setForecastUpdated } = useForecast();
  useEffect(() => {
    setIsMethodWeighted(valuesToUpdate.methodAndType === 'Linear Weighted');
    const prevPeriods =
      valuesToUpdate.weights.length === 12
        ? {
            p1: {
              label: MONTHS[0],
              value: valuesToUpdate.weights[0] || 0,
            },
            p2: {
              label: MONTHS[1],
              value: valuesToUpdate.weights[1] || 0,
            },
            p3: {
              label: MONTHS[2],
              value: valuesToUpdate.weights[2] || 0,
            },
            p4: {
              label: MONTHS[3],
              value: valuesToUpdate.weights[3] || 0,
            },
            p5: {
              label: MONTHS[4],
              value: valuesToUpdate.weights[4] || 0,
            },
            p6: {
              label: MONTHS[5],
              value: valuesToUpdate.weights[5] || 0,
            },
            p7: {
              label: MONTHS[6],
              value: valuesToUpdate.weights[6] || 0,
            },
            p8: {
              label: MONTHS[7],
              value: valuesToUpdate.weights[7] || 0,
            },
            p9: {
              label: MONTHS[8],
              value: valuesToUpdate.weights[8] || 0,
            },
            p10: {
              label: MONTHS[9],
              value: valuesToUpdate.weights[9] || 0,
            },
            p11: {
              label: MONTHS[10],
              value: valuesToUpdate.weights[10] || 0,
            },
            p12: {
              label: MONTHS[11],
              value: valuesToUpdate.weights[11] || 0,
            },
          }
        : {
            p1: {
              label: `${MONTHS[0]} - ${MONTHS[2]}`,
              value: valuesToUpdate.weights[0] || 0,
            },
            p2: {
              label: `${MONTHS[3]} - ${MONTHS[5]}`,
              value: valuesToUpdate.weights[1] || 0,
            },
            p3: {
              label: `${MONTHS[6]} - ${MONTHS[8]}`,
              value: valuesToUpdate.weights[2] || 0,
            },
            p4: {
              label: `${MONTHS[9]} - ${MONTHS[11]}`,
              value: valuesToUpdate.weights[3] || 0,
            },
          };
    const lookbackEndDate = valuesToUpdate.lookbackEndDate || values.lookbackEndDate;
    const lookbackStartDate = valuesToUpdate.lookbackStartDate || values.lookbackStartDate;
    const lookbackPeriod = valuesToUpdate.lookbackPeriod || values.lookbackPeriod;
    setPeriods(prevPeriods);
    setValues({
      ...valuesToUpdate,
      periods: prevPeriods,
      lookbackEndDate: moment(lookbackEndDate).utc().format('YYYY-MM-DD'),
      lookbackStartDate: moment(lookbackStartDate).utc().format('YYYY-MM-DD'),
    });
    const enableLookbackInputs = ['5'].includes(lookbackPeriod);
    setDisableLookbackDates(!enableLookbackInputs);
  }, [valuesToUpdate]);

  useEffect(() => {
    const calculateTotal = () => {
      let total = 0;
      for (const key in periods) {
        total += Number(periods[key].value) || 0;
      }
      return total;
    };
    if (values.methodAndType === methodTypes.linearWeighted) {
      const total = calculateTotal();
      setWeightedTotal(total);
      setWeightedValuesError(total !== 100 && values.name !== '');
    } else {
      setWeightedValuesError(false);
    }
  }, [periods, values]);

  useEffect(() => {
    setValues({ ...values, periods });
  }, [periods]);

  const handleDelete = async (id: string) => {
    showLoading();
    try {
      await AdminService.DeleteForecast(id);
      hideLoading();
      setFetchAgain(true);
      setForecastUpdated(true);
      setPageToShow('Create');
      const currentDate = new Date();
      const lookbackStartDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 0);
      const lookbackEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
      setForecastToUpdate({
        forecastId: '',
        name: '',
        methodAndType: 'Linear Weighted',
        startDate: `${new Date().getFullYear() + 1}-01-01`,
        endDate: `${new Date().getFullYear() + 1}-12-31`,
        lookbackPeriod: '1',
        lookbackEndDate: lookbackEndDate.toISOString().split('T')[0],
        lookbackStartDate: lookbackStartDate.toISOString().split('T')[0],
        forecastPeriod: '1',
        calculationPeriod: 'Quarterly',
        periods: {},
        weights: [],
        forecastCalendars: [],
      });
    } catch (error) {
      hideLoading();
      console.log(error);
    }
  };

  const handleDuplicate = async () => {
    showLoading();
    try {
      const newForecastName = `${valuesToUpdate.name}_copy_${new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })}`;
      setForecastName(newForecastName);
      await AdminService.DuplicateForecast(forecastId, newForecastName);
      toggle();

      hideLoading();
      setFetchAgain(true);
      setForecastUpdated(true);
    } catch (error) {
      hideLoading();
      console.log(error);
    }
  };

  const handleForecastPeriodChange = (e: any) => {
    const value = e.target.value;
    let startDate = values.startDate;
    let endDate = values.endDate;
    let disabled = true;

    const currentDate = new Date();
    const endOfYear = new Date(currentDate.getFullYear(), 11, 31);
    const currentYear = new Date().getFullYear();
    const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');

    const next18Months = currentDate.getMonth() + 18;
    const nextYear18Months = currentYear + Math.floor(next18Months / 12);
    const endMonth18Months = (next18Months % 12) + 1;
    const endDay18Months = new Date(nextYear18Months, endMonth18Months, 0).getDate();

    switch (value) {
      case '1':
        startDate = `${currentYear + 1}-01-01`;
        endDate = `${currentYear + 1}-12-31`;
        disabled = true;
        break;
      case '2':
        startDate = currentDate.toISOString().split('T')[0];
        endDate = endOfYear.toISOString().split('T')[0];
        disabled = true;
        break;
      case '3':
        startDate = `${currentYear}-${currentMonth}-01`;
        endDate = `${nextYear18Months}-${endMonth18Months.toString().padStart(2, '0')}-${endDay18Months.toString().padStart(2, '0')}`;
        disabled = true;
        break;
      case '4':
        startDate = `${currentYear + 1}-01-01`;
        endDate = `${currentYear + 2}-12-31`;
        disabled = true;
        break;
      case '5':
        disabled = false;
        break;
      default:
        disabled = false;
        break;
    }
    setDisableDates(disabled);
    setValues({
      ...values,
      forecastPeriod: value,
      startDate,
      endDate,
    });
    validateForm({
      ...values,
      forecastPeriod: value,
      startDate,
      endDate,
    });
  };

  const handleLookbackPeriodChange = (e: any) => {
    const value = e.target.value;
    let lookbackStartDate = values.lookbackStartDate || '';
    let lookbackEndDate = values.lookbackEndDate || '';
    let disabled = true;
    const currentDate = new Date();

    const trailing12StartDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 0);
    const trailing12EndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
    const h1StartDate = new Date(currentDate.getFullYear(), 0, 1);
    const h1EndDate = new Date(currentDate.getFullYear(), 5, 30);
    const lastYearStartDate = new Date(currentDate.getFullYear() - 1, 0, 1);
    const lastYearEndDate = new Date(currentDate.getFullYear() - 1, 11, 31);
    switch (value) {
      case '1':
        lookbackStartDate = trailing12StartDate.toISOString().split('T')[0];
        lookbackEndDate = trailing12EndDate.toISOString().split('T')[0];
        disabled = true;
        break;
      case '2':
        lookbackStartDate = h1StartDate.toISOString().split('T')[0];
        lookbackEndDate = h1EndDate.toISOString().split('T')[0];
        disabled = true;
        break;
      case '3':
        lookbackStartDate = lastYearStartDate.toISOString().split('T')[0];
        lookbackEndDate = lastYearEndDate.toISOString().split('T')[0];
        disabled = true;
        break;
      case '5':
        disabled = false;
        break;

      default:
        disabled = true;
        break;
    }
    setDisableLookbackDates(disabled);
    if (value !== '4') {
      setValues({
        ...values,
        lookbackPeriod: value,
        lookbackStartDate,
        lookbackEndDate,
      });
      validateForm({
        ...values,
        lookbackPeriod: value,
        lookbackStartDate,
        lookbackEndDate,
      });
    }
  };

  const handleMethodTypeChange = (e: any) => {
    const value = e.target.value;
    setIsMethodWeighted(value === methodTypes.linearWeighted);
  };

  const handleCalculationPeriodChange = (e: any) => {
    const { value } = e.target;
    setValues({
      ...values,
      calculationPeriod: value,
    });
    if (Number(value) === 12) {
      setPeriods({
        p1: {
          label: MONTHS[0],
          value: '',
        },
        p2: {
          label: MONTHS[1],
          value: '',
        },
        p3: {
          label: MONTHS[2],
          value: '',
        },
        p4: {
          label: MONTHS[3],
          value: '',
        },
        p5: {
          label: MONTHS[4],
          value: '',
        },
        p6: {
          label: MONTHS[5],
          value: '',
        },
        p7: {
          label: MONTHS[6],
          value: '',
        },
        p8: {
          label: MONTHS[7],
          value: '',
        },
        p9: {
          label: MONTHS[8],
          value: '',
        },
        p10: {
          label: MONTHS[9],
          value: '',
        },
        p11: {
          label: MONTHS[10],
          value: '',
        },
        p12: {
          label: MONTHS[11],
          value: '',
        },
      });
    } else {
      setPeriods({
        p1: {
          label: `${MONTHS[0]} - ${MONTHS[2]}`,
          value: '',
        },
        p2: {
          label: `${MONTHS[3]} - ${MONTHS[5]}`,
          value: '',
        },
        p3: {
          label: `${MONTHS[6]} - ${MONTHS[8]}`,
          value: '',
        },
        p4: {
          label: `${MONTHS[9]} - ${MONTHS[11]}`,
          value: '',
        },
      });
    }
  };

  const updatePeriods = (e: any, fieldName: string, newValue: any) => {
    if (!(newValue.length === 0 || /^-?\d*\.?\d*$/.test(newValue))) {
      e.preventDefault();
      return false;
    } else {
      const updatedPeriods = { ...periods };
      updatedPeriods[fieldName].value = newValue;
      setPeriods(updatedPeriods);
      setValues({ ...values, periods: updatedPeriods });
    }
  };

  const renderPeriods = () => {
    const items = [];
    const length = Object.keys(periods).length;
    if (length !== 0) {
      for (let i = 1; i <= length; i++) {
        const fieldName = `p${i}`;
        items.push(
          <div key={i} className="d-flex justify-content-between align-items-baseline">
            <p>{periods[fieldName].label}</p>
            <Field
              value={periods[fieldName].value}
              id={periods[fieldName].label}
              name={fieldName}
              disabled={values.status === 'Closed'}
              onChange={(e: any) => updatePeriods(e, fieldName, e.target.value)}
              type="text"
              className="w-25 form-control"
            />
          </div>,
        );
      }
    }
    return items;
  };

  const [dataSourceOptions, $dataSourceOptions] = React.useState<any[]>([]);
  React.useEffect(() => {
    ForecastService.GetSalesLookbackSources().then((res) => {
      const dataSourceOptions = res.data?.map((item: any) => {
        return {
          label: item.displayName,
          value: item.connectorName,
        };
      });
      $dataSourceOptions(dataSourceOptions);
    });
  }, []);

  return (
    <Form>
      <div className="card-body">
        <div className="container m-0" style={{ minWidth: '100%' }}>
          <div className="row">
            <div className="col-md-3">
              <div className="d-flex mb-2 flex-column">
                <label htmlFor="name" className="col-form-label">
                  Forecast Name:&nbsp;
                </label>
                <Field
                  id="name"
                  name="name"
                  type="text"
                  className="form-control"
                  // style={{ width: '150px' }}
                  disabled={values.status === 'Closed'}
                />
              </div>
              <div className="col-xl-3">
                <div style={{ fontSize: '10px', color: 'red' }}>
                  <ErrorMessage name="name" />
                </div>
              </div>
              <div className="d-flex mb-2 flex-column">
                <label htmlFor="name" className="col-form-label">
                  Status:&nbsp;
                </label>
                <select
                  className="form-select"
                  disabled={pageToShow === 'Create'}
                  defaultValue={'Created'}
                  value={values.status}
                  onChange={(e) => {
                    setValues({ ...values, status: e.target.value });
                  }}
                >
                  {pageToShow === 'Create' ? (
                    <option value="Created">Created</option>
                  ) : (
                    <>
                      <option value="Created">Created</option>
                      <option value="Closed">Closed</option>
                    </>
                  )}
                </select>
              </div>
              {dataSourceOptions?.length > 0 && (
                <div className="d-flex mb-2 flex-column">
                  <label htmlFor="name" className="col-form-label">
                    Lookback Data Source:
                  </label>
                  <select
                    className="form-select"
                    value={values.salesDataSource || dataSourceOptions[0]?.value}
                    onChange={(e) => {
                      setValues({ ...values, salesDataSource: e.target.value });
                    }}
                  >
                    {dataSourceOptions.map((item) => {
                      return (
                        <option key={`key_${item.value}`} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
            <div className="p-0" style={{ width: '1px', height: '300px', backgroundColor: 'rgb(237 237 237)' }}></div>
            <div className="col-md-4">
              <div className="d-flex mb-2 flex-column mx-3">
                <label className="col-form-label" htmlFor="calculationPeriod">
                  Forecast Period:&nbsp;
                </label>
                <select
                  className="form-select"
                  disabled={values.status === 'Closed'}
                  value={values.forecastPeriod}
                  onChange={handleForecastPeriodChange}
                >
                  <option value="1">Next Year</option>
                  <option value="2">Rest of year</option>
                  <option value="3">Next 18 months</option>
                  <option value="4">Next 2 years</option>
                  <option value="5">Custom</option>
                </select>
              </div>
              <div className="d-flex mb-2 mx-3 flex-column">
                <label className="col-form-label" htmlFor="startDate">
                  Start Date:&nbsp;
                </label>
                <Field
                  id="startDate"
                  name="startDate"
                  type="date"
                  disabled={disableDates || values.status === 'Closed'}
                  className="form-control"
                />
              </div>
              <div className="col">
                <div className="mx-3" style={{ fontSize: '10px', color: 'red' }}>
                  <ErrorMessage name="startDate" />
                </div>
              </div>
              <div className="d-flex mb-2 mx-3 flex-column">
                <label className="col-form-label" htmlFor="endDate">
                  End Date:&nbsp;
                </label>
                <Field
                  id="endDate"
                  className="form-control"
                  name="endDate"
                  type="date"
                  disabled={disableDates || values.status === 'Closed'}
                />
              </div>
              <div className="col">
                <div className="mx-3" style={{ fontSize: '10px', color: 'red' }}>
                  <ErrorMessage name="endDate" />
                </div>
              </div>
              <div className="d-flex mb-2 mx-3 mt-4 flex-column">
                <label className="col-form-label" htmlFor="calculationPeriod">
                  Lookback Period:&nbsp;
                </label>
                <select
                  className="form-select"
                  disabled={values.status === 'Closed'}
                  value={values.lookbackPeriod}
                  onChange={handleLookbackPeriodChange}
                >
                  <option value="1">Trailing 12 months</option>
                  <option value="2">H1 of Current Year</option>
                  <option value="3">Last Year</option>
                  <option value="4"></option>
                  <option value="5">Custom</option>
                </select>
              </div>
              <div className="d-flex mb-2 mx-3 flex-column">
                <label className="col-form-label" htmlFor="lookbackStartDate">
                  Lookback Start:&nbsp;
                </label>
                <Field
                  id="lookbackStartDate"
                  name="lookbackStartDate"
                  type="date"
                  disabled={disableLookbackDates || values.status === 'Closed'}
                  className="form-control"
                />
              </div>
              <div className="col">
                <div className="mx-3" style={{ fontSize: '10px', color: 'red' }}>
                  <ErrorMessage name="lookbackStartDate" />
                </div>
              </div>
              <div className="d-flex mb-2 mx-3 flex-column">
                <label className="col-form-label" htmlFor="lookbackEndDate">
                  Lookback End:&nbsp;
                </label>
                <Field
                  id="lookbackEndDate"
                  name="lookbackEndDate"
                  type="date"
                  disabled={disableLookbackDates || values.status === 'Closed'}
                  className="form-control"
                />
              </div>
              <div className="col">
                <div className="mx-3" style={{ fontSize: '10px', color: 'red' }}>
                  <ErrorMessage name="lookbackEndDate" />
                </div>
              </div>
            </div>
            <div className="p-0" style={{ width: '1px', height: '300px', backgroundColor: 'rgb(237 237 237)' }}></div>
            <div className="col-md-4">
              <div className="d-flex mb-2 flex-column">
                <label className="col-form-label" htmlFor="calculationPeriod">
                  Calculation Period:&nbsp;
                </label>
                <select
                  className="form-select"
                  disabled={pageToShow !== 'Create' || values.status === 'Closed'}
                  value={values.calculationPeriod}
                  onChange={handleCalculationPeriodChange}
                >
                  {calcularionPeriodLabels.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-flex mb-2 flex-column">
                <label className="col-form-label" htmlFor="type">
                  Forecast Type:&nbsp;
                </label>
                <select
                  className="form-select"
                  disabled={true}
                  value={values.methodAndType}
                  onChange={handleMethodTypeChange}
                >
                  <option value={methodTypes.linearWeighted}>Linear - Weighted</option>
                  <option value={methodTypes.lienarUnweighted}>Linear - Unweighted</option>
                </select>
              </div>
              <div className="col">
                <div style={{ fontSize: '10px', color: 'red' }}>
                  <ErrorMessage name="methodAndType" />
                </div>
              </div>
              {isMethodWeighted && (
                <div className="">
                  <div className="d-flex justify-content-between border-bottom mb-2">
                    <p className="font-weight-bold mb-0">Time Period</p>
                    <p className="font-weight-bold mb-0">Weight</p>
                  </div>
                  {renderPeriods()}
                </div>
              )}
              {weightedValuesError && (
                <p className="text-danger">
                  The weights have to add up to 100. The current total amount is {weightedTotal}. You must distribute{' '}
                  {100 - weightedTotal}.
                </p>
              )}
            </div>
          </div>
        </div>

        {pageToShow === 'Update' ? (
          <div className="row align-items-end align-content-end align-self-end">
            <div className="col align-items-end align-content-end align-self-end">
              <button
                disabled={weightedValuesError}
                className="btn btn-primary float-end"
                type="submit"
                style={{ textAlign: 'right' }}
              >
                Update
              </button>
              <button
                className="btn btn-primary me-2"
                type="button"
                style={{ textAlign: 'right' }}
                onClick={() => handleDuplicate()}
              >
                <FontAwesomeIcon icon={faCopy} />
                &nbsp;Duplicate
              </button>
              <button
                className="btn btn-danger"
                type="button"
                style={{ textAlign: 'right' }}
                onClick={() => handleDelete(forecastId)}
              >
                <FontAwesomeIcon icon={faCircleXmark} />
                &nbsp;Delete
              </button>
            </div>
          </div>
        ) : (
          <div className="row align-items-end align-content-end align-self-end">
            <div className="col align-items-end align-content-end align-self-end">
              <button
                disabled={weightedValuesError}
                className="btn btn-primary float-end"
                type="submit"
                style={{ textAlign: 'right' }}
              >
                Create
              </button>
            </div>
          </div>
        )}

        <Modal show={isOpen} onHide={() => toggle()} centered>
          <Modal.Header closeButton>Copy completed</Modal.Header>
          <Modal.Body className="text-center m-3">
            <p className="mb-0">
              Copy <b>{forecastName}</b> completed!
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => toggle()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Form>
  );
};
const NewForecast: React.FC<INewForecast> = ({
  pageToShow,
  forecastToUpdate,
  asyncForecast,
  setPageToShow,
  setFetchAgain,
  setOpenModal,
  setForecastToUpdate,
}) => {
  const { forecastId, ...initialValues } = forecastToUpdate;
  const { setForecastUpdated } = useForecast();

  const onSubmit = async (values: IForecastForm) => {
    const calculationPeriodLabel =
      calcularionPeriodLabels.find((item) => item.value === String(values.calculationPeriod))?.label ||
      values.calculationPeriod;
    showLoading();
    try {
      if (pageToShow === 'Create') {
        if (asyncForecast) {
          AdminService.CreateForecast({
            data: {
              name: values.name,
              method: values.methodAndType.split(' ')[0] as ForecastMethod,
              type: values.methodAndType.split(' ')[1] as ForecastType,
              startDate: new Date(values.startDate),
              endDate: new Date(values.endDate),
              lookbackStartDate: new Date(values.lookbackStartDate),
              lookbackEndDate: new Date(values.lookbackEndDate),
              calculationPeriod: calculationPeriodLabel,
              status: values.status,
              ...(values.salesDataSource ? { salesDataSource: values.salesDataSource } : {}),
              weights:
                values.methodAndType === 'Linear Weighted'
                  ? Object.values(values.periods).map((item: any) => Number(item.value) || 0)
                  : calculationPeriodLabel === 'Quarterly'
                    ? [25, 25, 25, 25]
                    : [8.333, 8.333, 8.333, 8.333, 8.333, 8.333, 8.333, 8.333, 8.333, 8.333, 8.333, 8.333],
            },
            asyncForecast,
          }).then(() => {
            clearForecast();
            setOpenModal(true);
            hideLoading();
            setFetchAgain(true);
          });
        } else {
          await AdminService.CreateForecast({
            data: {
              name: values.name,
              method: values.methodAndType.split(' ')[0] as ForecastMethod,
              type: values.methodAndType.split(' ')[1] as ForecastType,
              startDate: new Date(values.startDate),
              endDate: new Date(values.endDate),
              lookbackStartDate: new Date(values.lookbackStartDate),
              lookbackEndDate: new Date(values.lookbackEndDate),
              calculationPeriod: calculationPeriodLabel,
              status: values.status,
              ...(values.salesDataSource ? { salesDataSource: values.salesDataSource } : {}),
              weights:
                values.methodAndType === 'Linear Weighted'
                  ? Object.values(values.periods).map((item: any) => Number(item.value) || 0)
                  : calculationPeriodLabel === 'Quarterly'
                    ? [25, 25, 25, 25]
                    : [8.333, 8.333, 8.333, 8.333, 8.333, 8.333, 8.333, 8.333, 8.333, 8.333, 8.333, 8.333],
            },
            asyncForecast,
          });
          clearForecast();
        }
      } else {
        await AdminService.UpdateForecast({
          forecastId,
          name: values.name,
          method: values.methodAndType.split(' ')[0] as ForecastMethod,
          type: values.methodAndType.split(' ')[1] as ForecastType,
          startDate: values.startDate,
          endDate: values.endDate,
          lookbackStartDate: new Date(values.lookbackStartDate),
          lookbackEndDate: new Date(values.lookbackEndDate),
          calculationPeriod: calculationPeriodLabel,
          status: values.status,
          ...(values.salesDataSource ? { salesDataSource: values.salesDataSource } : {}),
          weights:
            values.methodAndType === 'Linear Weighted'
              ? Object.values(values.periods).map((item: any) => Number(item.value) || 0)
              : calculationPeriodLabel === 'Quarterly'
                ? [25, 25, 25, 25]
                : [8.333, 8.333, 8.333, 8.333, 8.333, 8.333, 8.333, 8.333, 8.333, 8.333, 8.333, 8.333],
        });
        const weights = values?.forecastCalendars
          .map((calendar: any) => {
            if (calendar.periodType === 'Quarter' || calendar.periodType === 'Month') {
              return calendar?.forecastWeight?.weight || 0;
            }
            return null;
          })
          .filter((weight: any) => weight !== null);
        const updateForecast = {
          ...values,
          weights: weights,
          calculationPeriod: Number(values.calculationPeriod) === 4 ? 'Quarterly' : 'Monthly',
        };
        setForecastToUpdate(updateForecast);
      }
      hideLoading();
      clearForecast();
      setFetchAgain(true);
      setForecastUpdated(true);
    } catch (error) {
      hideLoading();
      console.log(error);
    }
  };

  const clearForecast = () => {
    const currentDate = new Date();
    const lookbackStartDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 0);
    const lookbackEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
    setForecastToUpdate({
      forecastId: '',
      name: '',
      methodAndType: 'Linear Weighted',
      startDate: `${new Date().getFullYear() + 1}-01-01`,
      endDate: `${new Date().getFullYear() + 1}-12-31`,
      lookbackPeriod: '1',
      lookbackEndDate: lookbackEndDate.toISOString().split('T')[0],
      lookbackStartDate: lookbackStartDate.toISOString().split('T')[0],
      forecastPeriod: '1',
      calculationPeriod: 'Quarterly',
      periods: {},
      weights: [],
      forecastCalendars: [],
      salesDataSource: '',
    });
    setPageToShow('Create');
  };

  return (
    <div className="card shadow" style={{ padding: '0px', paddingBottom: '0px', marginBottom: '40px' }}>
      <div className="card-header py-3">
        {pageToShow === 'Create' ? (
          <h5 className="text-primary m-0 fw-bold" style={{ fontSize: '1.25rem' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="1em"
              height="1em"
              fill="currentColor"
              className="me-2"
              style={{ fontSize: '30px' }}
            >
              <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z"></path>
            </svg>
            Create New Forecast
          </h5>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}>
            <h5 className="text-primary m-0 fw-bold" style={{ fontSize: '1.25rem' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width="1em"
                height="1em"
                fill="currentColor"
                className="me-2"
                style={{ fontSize: '30px' }}
              >
                <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z"></path>
              </svg>
              Create New Forecast
            </h5>{' '}
            <FontAwesomeIcon icon={faXmark} onClick={clearForecast} />
          </div>
        )}
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={ForecastSchema}
        validateOnChange={false}
        validateOnBlur
      >
        <ForecastForm
          pageToShow={pageToShow}
          valuesToUpdate={initialValues}
          forecastId={forecastId}
          setForecastToUpdate={setForecastToUpdate}
          setPageToShow={setPageToShow}
          setFetchAgain={setFetchAgain}
        />
      </Formik>
    </div>
  );
};

export default NewForecast;
