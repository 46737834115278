import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { ApprovalStatus, CurrencyTypes } from '../../../../constants';

const names = {
  productSpecificName: 'Description',
  federalExciseTax: 'FET',
  quantity: 'Quantity',
  volume: 'Volume',
  bbpProductNumber: 'PSI Product Key',
  label: 'Label',
  cogs: 'Cogs',
  margin: 'Margin',
  markup: 'Markup',
  freight: 'Freight',
  abv: 'ABV',
  landedCogs: 'Landed Cogs',
  insurance: 'Insurance',
  warehouseFees: 'Warehouse Fees',
  psiFees: 'Psi Fees',
  natlFob: 'Natl Fob',
  preFeeFOB: 'Pre-Fees FOB',
  distributorAllowance: 'Distributor Allowance',
  currencyType: 'Currency',
  distributorMargin: 'Distributor Margin',
  distributorCasePrice: 'Distributor Case Price',
  distributorBottlePrice: 'Distributor Bottle Price',
  retailMarkup: 'Retail Markup',
  retailPrice: 'Retail Price',
  suggestedRetailPrice: 'Suggested Retail Price',
  productType: 'Product Type',
  productSubType: 'Product Sub Type',
  vintage: 'Vintage',
  containerType: 'Container Type',
  unitWeight: 'Unit Weight',
  palletWeight: 'Pallet Weight',
  layersPerPallet: 'Layers Per Pallet',
  casesPerLayer: 'Cases Per Layer',
  casesPerPallet: 'Cases Per Pallet',
  length: 'Length',
  width: 'Width',
  height: 'Height',
  weight: 'Weight',
  colaTtbId: 'Cola TTB ID',
  upc: 'UPC Code',
  nabcaCode: 'NABCA Code',
  sccCode: 'SCC Code',
  bdnCode: 'BDN Code',
  isComplianceProduct: 'Is Compliance Product',
  isDiscontinued: 'Is Discontinued',
  isActive: 'Is Active',
  description: 'Description',
  sku: 'SKUs',
};

const ProductHistory = ({ productHistory }: { productHistory: any }) => {
  const [expandedItems, setExpandedItems] = useState({});
  const toggleExpand = (index) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  return (
    <div>
      <div>
        <div className="row mt-3">
          <div className="col" style={{ paddingLeft: '20px' }}>
            {productHistory.length === 0 && <h4>There is no history for this Product yet.</h4>}
            {productHistory.map((item, index) => {
              const date = moment(item.createdAt);
              const formattedDate = date.format('MMMM DD, hh:mm A');
              const isExpanded = expandedItems[index];
              const allDiffs = item.difference
                ? [
                    ...Object.entries(item.difference.historyDiff),
                    ...Object.entries(item.difference.priceDiff),
                    ...Object.entries(item.difference.propertyDiff),
                    ...Object.entries(item.difference.relationsDiff),
                  ]
                : [];
              return (
                <div className="row" key={index}>
                  <div className="col col-1" style={{ width: '20px' }}>
                    <FontAwesomeIcon
                      icon={isExpanded ? faAngleDown : faAngleRight}
                      className="my-2"
                      onClick={() => toggleExpand(index)}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  <div className="col">
                    <div>
                      <span>
                        <strong>{item.approvalStatus}</strong>:&nbsp;{formattedDate}
                      </span>
                    </div>
                    <div>
                      {item.approvals.length === 0 ? (
                        <span className={`badge bg-danger me-2`}>No approvals</span>
                      ) : (
                        item.approvals.map((approval, index) => (
                          <span
                            key={index}
                            className={`badge bg-${approval.approvalStatus === ApprovalStatus.REJECTED ? 'danger' : Number(item.approvalCount) === Number(process.env.REACT_APP_PRICING_APPROVAL_COUNT) && !approval.weasRejected ? 'success' : 'warning'} me-2`}
                          >
                            {approval?.user?.firstName} {approval?.user?.lastName}
                          </span>
                        ))
                      )}
                    </div>
                    {isExpanded && item.difference && (
                      <div>
                        <h5 className="mt-2 pt-2" style={{ borderTop: '1px dotted #cdcdcd' }}>
                          Changed Fields:
                        </h5>
                        <div className="table-responsive col-5" style={{ fontSize: '11px' }}>
                          <table className="table table-striped table-hover table-sm">
                            <thead>
                              <tr>
                                <th>Field</th>
                                <th>Previous</th>
                                <th>New</th>
                              </tr>
                            </thead>
                            <tbody>
                              {allDiffs.map(([key, value]: any, index) => (
                                <tr key={index}>
                                  <td>
                                    {key.includes('tier1Price')
                                      ? `FOB: ${key.replace('tier1Price', '')} Tier 1`
                                      : key.includes('tier2Price')
                                        ? `FOB: ${key.replace('tier2Price', '')} Tier 2`
                                        : names[key] || `FOB: ${key} Price`}
                                  </td>
                                  <td>
                                    {typeof value.oldValue === 'string'
                                      ? value.oldValue
                                      : key.includes('currency')
                                        ? value.oldValue === CurrencyTypes.USD
                                          ? 'USD'
                                          : 'EUR'
                                        : 'New'}
                                  </td>
                                  <td>
                                    {typeof value.newValue === 'string'
                                      ? value.newValue
                                      : key.includes('currency')
                                        ? value.newValue === CurrencyTypes.USD
                                          ? 'USD'
                                          : 'EUR'
                                        : 'Deleted'}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductHistory;
