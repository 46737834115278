import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBell, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

interface IAlert extends Omit<React.HtmlHTMLAttributes<HTMLDivElement>, 'wrapperClass' | 'variant'> {
  wrapperClass?: string;
  variantClass?: string;
  variant?: 'primary' | 'secondary' | 'outline-primary' | 'outline-danger' | 'danger' | 'outline-warning' | 'warning';
  loading?: boolean;
  message: string;
  arrowRight?: boolean;
  show: boolean;
}

const CustomAlert: React.FC<IAlert> = ({ variant = 'primary', show = false, message, ...props }: IAlert) => {
  let variantStyle = '';
  let icon: IconProp = faBell;

  if (variant === 'primary') {
    variantStyle = 'alert-primary';
  } else if (variant === 'outline-primary') {
    variantStyle = 'alert-outline-coloured alert-primary';
  } else if (variant === 'outline-danger') {
    variantStyle = 'alert-outline-coloured alert-danger';
    icon = faBell;
  } else if (variant === 'danger') {
    variantStyle = 'alert-danger';
    icon = faBell;
  } else if (variant === 'outline-warning') {
    variantStyle = 'alert-outline-coloured alert-warning';
    icon = faTriangleExclamation;
  } else if (variant === 'warning') {
    variantStyle = 'alert-warning';
    icon = faTriangleExclamation;
  }

  const [isShow, setShow] = useState(show);

  return (
    <div>
      {isShow && (
        <div role="alert" className={`fade alert alert-dismissible ${isShow ? 'show' : ''} ${variantStyle}`} {...props}>
          <div>
            <button
              type="button"
              className="btn-close mt-1 py-2 focus-ring focus-ring-light"
              aria-label="Close alert"
              onClick={() => setShow(!isShow)}
            ></button>
          </div>
          <div className="alert-icon rounded-start py-2 d-flex align-items-center">
            <FontAwesomeIcon icon={icon} />
          </div>
          <div className="alert-message py-2 rounded-end text-black mh-50">{message}</div>
        </div>
      )}
    </div>
  );
};

export default CustomAlert;
