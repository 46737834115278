import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Modal, Spinner } from 'react-bootstrap';
import CustomTable from '../../components/common/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload } from '@fortawesome/free-solid-svg-icons';
import AdminService from '../../services/admin.service';
import { hideLoading, showLoading } from '../../lib/uiService';
import { toast } from 'react-toastify';
import { parseVerifyXlsData } from '../../utils/downloadUtils';
import { getQueryFromUrl } from '../../utils/urlUtils';
import useErrorHandling from '../../hooks/useErrorHandling';

const ImportConfirm: React.FC = () => {
  const [importData, setImportData] = useState([]);
  const [isOpen, setOpentModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [modalData, setData] = useState({
    failedRows: [],
    failureCount: 0,
    recordsInserted: 0,
    recordsUpdated: 0,
    totalProcessed: 0,
    updatedRows: [],
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { setError } = useErrorHandling();
  const { state } = location;
  const type = getQueryFromUrl(location.search);

  useEffect(() => {
    readXlsFile(state);
  }, []);

  const readXlsFile = (file: any) => {
    if (!file) {
      toast.warn('Please select a file');
      return;
    }
    showLoading();
    parseVerifyXlsData(file, setImportData);
    hideLoading();
  };

  const getColumn = (header: any[]) => {
    return header.map((val) =>
      Object.assign({
        Header: val,
        id: val,
        Cell: ({ data, row }: { data: any; row: any }) => {
          return (
            <td>
              <span>{data[row.id][val]}</span>
            </td>
          );
        },
      }),
    );
  };

  const onSubmit = () => {
    const formData = new FormData();
    formData.append('file', state);
    setOpentModal(true);
    setLoading(true);
    if (AdminService.importServices[type]) {
      AdminService.importServices[type](formData)
        .then((res) => {
          if (res.data) {
            setData(res.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
          setError(err.status);
        });
    }
  };

  return (
    <>
      <h3 className="text-dark mb-4">Import/Export</h3>
      <Card title="test">
        <div className="p-3 text-center">
          <h5 className="fw-bold m-0 pb-1 pt-1">
            <h3 className="mx-2">Verify Your Import</h3>
          </h5>
        </div>
        <div>
          {importData.map(({ title, header, rows }, i) => {
            const columns = getColumn(header);

            return (
              <div className="p-3" key={i}>
                {importData.length > 1 && <h4>{title}</h4>}
                <CustomTable columns={columns} data={rows} />
              </div>
            );
          })}
        </div>
        <div className="px-3">
          <span className="fw-bold">Bold fields are Required</span>
        </div>
        <div className="p-3 d-flex justify-content-between">
          <Button onClick={() => navigate('/admin/import-export')} variant="primary">
            Go Back
          </Button>
          <Button onClick={onSubmit} variant="primary">
            Finish Import
          </Button>
        </div>
        <Modal show={isOpen} centered>
          <Modal.Header>
            <FontAwesomeIcon size="2x" className="text-primary" icon={faCloudDownload} />
            <span className="mx-2 text-lg fw-bold">{!isLoading ? 'Import Completed' : 'Importing...'}</span>
          </Modal.Header>
          <Modal.Body className="m-3 d-flex w-50 m-auto justify-content-around">
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <>
                <div>
                  <p className="fw-bold text-end">Rows Processed</p>
                  <p className="fw-bold text-end">Records inserted</p>
                  <p className="fw-bold text-end">Records Updated</p>
                  <p className="fw-bold text-end">Rows Updated</p>
                  <p className="fw-bold text-end">Failures</p>
                  <p className="fw-bold text-end">Failed Rows</p>
                </div>
                <div>
                  <p>{modalData.totalProcessed}</p>
                  <p>{modalData.recordsInserted}</p>
                  <p>{modalData.recordsUpdated}</p>
                  <p>{`${modalData.updatedRows.join(', ')}`}</p>
                  <p>{modalData.failureCount}</p>
                  <p>{`${modalData.failedRows.join(', ')}`}</p>
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setOpentModal(!isOpen)}>
              Finish
            </Button>
          </Modal.Footer>
        </Modal>
      </Card>
    </>
  );
};

export default ImportConfirm;
