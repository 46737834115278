import React from 'react';

interface IPagination {
  total: number;
  currentPage: number;
  setPage: (page: number) => void;
}

const Pagination: React.FC<IPagination> = ({ total, currentPage, setPage }) => {
  const totals = Array(total).fill('');
  return (
    <nav aria-label="Page navigation example" className="d-flex justify-content-end">
      <ul className="pagination float-right">
        <li className={`page-item ${currentPage === 1 && 'disabled'}`} onClick={() => setPage(currentPage - 1)}>
          <a className="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </a>
        </li>
        {totals.map((_, idx) => (
          <li className={`page-item ${currentPage === idx + 1 && 'active'}`} key={idx} onClick={() => setPage(idx + 1)}>
            <a className="page-link" href="#">
              {idx + 1}
            </a>
          </li>
        ))}
        <li className={`page-item ${currentPage === total && 'disabled'}`} onClick={() => setPage(currentPage + 1)}>
          <a className="page-link" href="#" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
