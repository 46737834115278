export const getDomainFromWindow = () => {
  // First, check if this function is being called on the frontend. If so, get domain from windown
  if (typeof window !== 'undefined') {
    return window.location.origin;
  }

  return null;
};

export const disableLoginCheckUrl = ['/login', '/', '/403', '/404', '/500'];

export const getQueryFromUrl = (str: string) => {
  return str.substring(1);
};
