import React, { useEffect, useState } from 'react';
import { faBell, faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FreightFees from './FreightFees';
import AdminService from '../../../services/admin.service';
import { hideLoading, showLoading } from '../../../lib/uiService';
import useErrorHandling from '../../../hooks/useErrorHandling';
import ForecastService from '../../../services/forecast.service';
import { useAuth } from '../../../providers/AuthProvider';
import { UserRole } from '../../../lib/types';
import { toast } from 'react-toastify';
import WarehouseFees from './WarehouseFees';

const AdminFee = () => {
  const { setError } = useErrorHandling();
  const { role, id } = useAuth();
  const [freightFees, setFreightFees] = useState([]);
  const [warehouseFees, setWarehouseFees] = useState([]);
  const [hasWarehouseHistory, setHasWarehouseHistory] = useState<any>(false);
  const [hasFreightHistory, setHasFreightHistory] = useState<any>(false);
  const [brands, setBrands] = useState([]);
  const [warehouses, setWarehouses] = useState<any>([]);
  const [history, setHistory] = useState<any>([]);
  const [deletedFreightFees, setDeletedFreightFees] = useState([]);
  const [createdFreightFees, setCreatedFreightFees] = useState([]);
  const [updatedFreightFees, setUpdatedFreightFees] = useState([]);
  const [createdWarehouseFees, setCreatedWarehouseFees] = useState([]);
  const [updatedWarehouseFees, setUpdatedWarehouseFees] = useState([]);
  const [deletedWarehouseFees, setDeletedWarehouseFees] = useState([]);

  useEffect(() => {
    showLoading();
    getFees();
    ForecastService.GetBrands()
      .then(({ data }) => {
        const brands = data.brands.map((brand: any) => Object.assign({ label: brand.name, value: brand.brandId }));
        brands.unshift({ label: 'Brands', value: '' });
        setBrands(brands);
        hideLoading();
      })
      .catch((error) => {
        setError({ status: error?.response?.status || 500 });
        hideLoading();
      });
    AdminService.GetWarehouses()
      .then(({ data }) => {
        setWarehouses(data);
      })
      .catch((error) => {
        console.log(error);
        setError({ status: error.response.status || 500 });
      });
    hideLoading();
  }, []);

  const getFees = () => {
    AdminService.GetAllFees()
      .then(({ data }) => {
        setHasFreightHistory(data.freightLatestHistory);
        setHasWarehouseHistory(data.warehouseLatestHistory);
        setHistory(data.history);
        const freigthData = data.freightFees.map((item) => {
          return {
            brandName: item.brand.name,
            brandId: item.brand.brandId,
            warehouse: item.warehouse.name,
            warehouseId: item.warehouse.warehouseId,
            fee: item.fee,
            freightFeeId: item.freightFeeId,
          };
        });
        const warehouseData = data.warehouseFees.map((item) => {
          return {
            warehouse: item?.warehouse?.name || null,
            warehouseId: item?.warehouse?.warehouseId || null,
            fee: item.fee,
            feeType: item.feeType,
            warehouseFeeId: item.warehouseFeeId,
          };
        });
        setFreightFees(freigthData);
        setWarehouseFees(warehouseData);
        setDeletedFreightFees([]);
        setCreatedFreightFees([]);
        setUpdatedFreightFees([]);
        setCreatedWarehouseFees([]);
        setUpdatedWarehouseFees([]);
        setDeletedWarehouseFees([]);
      })
      .catch((error) => {
        setError({ status: error?.response?.status || 500 });
        hideLoading();
      });
  };

  const submit = (isFreight: boolean) => {
    showLoading();
    if ((isFreight && hasFreightHistory) || (!isFreight && hasWarehouseHistory)) {
      AdminService.UpdateFeeRequest({
        feeHistory: isFreight ? hasFreightHistory : hasWarehouseHistory,
        deletedFreightFees: !isFreight ? [] : deletedFreightFees,
        createdFreightFees: !isFreight ? [] : createdFreightFees,
        updatedFreightFees: !isFreight ? [] : updatedFreightFees,
        createdWarehouseFees: isFreight ? [] : createdWarehouseFees,
        updatedWarehouseFees: isFreight ? [] : updatedWarehouseFees,
        deletedWarehouseFees: isFreight ? [] : deletedWarehouseFees,
        returnFunction: true,
      })
        .then(() => {
          getFees();
          hideLoading();
          toast.success('The request has been updated.');
        })
        .catch((error) => {
          console.log(error);
          setError({ status: error.response.status || 500 });
          hideLoading();
        });
    } else {
      AdminService.RequestFeesApproval({
        deletedFreightFees: !isFreight ? [] : deletedFreightFees,
        createdFreightFees: !isFreight ? [] : createdFreightFees,
        updatedFreightFees: !isFreight ? [] : updatedFreightFees,
        createdWarehouseFees: isFreight ? [] : createdWarehouseFees,
        updatedWarehouseFees: isFreight ? [] : updatedWarehouseFees,
        deletedWarehouseFees: isFreight ? [] : deletedWarehouseFees,
      })
        .then(() => {
          getFees();
          hideLoading();
          toast.success('The changes request has been sent for approval.');
        })
        .catch((error) => {
          console.log(error);
          setError({ status: error.response.status || 500 });
          hideLoading();
        });
    }
  };

  const addAproval = (isFreight: boolean) => {
    showLoading();
    AdminService.AddFeeAproval({
      feeHistory: isFreight ? hasFreightHistory : hasWarehouseHistory,
      deletedFreightFees: !isFreight ? [] : deletedFreightFees,
      createdFreightFees: !isFreight ? [] : createdFreightFees,
      updatedFreightFees: !isFreight ? [] : updatedFreightFees,
      createdWarehouseFees: isFreight ? [] : createdWarehouseFees,
      updatedWarehouseFees: isFreight ? [] : updatedWarehouseFees,
      deletedWarehouseFees: isFreight ? [] : deletedWarehouseFees,
      returnFunction: false,
    })
      .then(({ data }) => {
        getFees();
        hideLoading();
        toast.success(
          data.wasApproved
            ? 'The request has been approved.'
            : 'You have approved these changes. Awaiting more approvals.',
        );
      })
      .catch((error) => {
        console.log(error);
        setError({ status: error?.response?.status || 500 });
        hideLoading();
      });
  };

  return (
    <div className="container-fluid">
      <h3 className="text-dark mb-4">Labels &amp; Products</h3>
      <div className="card shadow">
        <div className="card-header">
          <h5 className="text-primary m-0 fw-bold pt-1 pb-1 d-flex align-items-center">
            <FontAwesomeIcon icon={faBoxOpen} className="me-2" style={{ fontSize: '30px' }} />
            Edit Fees
          </h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col">
              <h3>Freight Fees</h3>
              {hasFreightHistory && (
                <>
                  <div role="alert" className="fade alert-outline alert alert-primary alert-dismissible show">
                    <button type="button" className="btn-close d-none"></button>
                    <div className="alert-icon d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon icon={faBell} />
                    </div>
                    <div className="alert-message">
                      <strong>There are pending edits awaiting your approval.&nbsp;</strong> Click to approve.
                      <button
                        disabled={
                          (!role?.includes(UserRole.PricingAdmin) && !role?.includes(UserRole.Admin)) ||
                          hasFreightHistory?.approvals.some((item) => item.createdBy === id)
                        }
                        className="btn btn-success mx-2"
                        onClick={() => addAproval(true)}
                        style={{ textAlign: 'right' }}
                      >
                        Approve
                      </button>
                      <button
                        disabled={
                          (!role?.includes(UserRole.PricingAdmin) && !role?.includes(UserRole.Admin)) ||
                          hasWarehouseHistory?.approvals.some((item) => item.createdBy === id)
                        }
                        className="btn btn-danger mx-2"
                        onClick={(event) => {
                          AdminService.RejectApproval({
                            referenceId: hasFreightHistory?.approvalReferenceId,
                            isProductApproval: false,
                          })
                            .then(() => {
                              getFees();
                              hideLoading();
                              toast.success('The request has been rejected.');
                            })
                            .catch((e) => {
                              hideLoading();
                              setError(e.status ? e : { status: 500 });
                            });
                        }}
                        style={{ textAlign: 'right' }}
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="col">
              <h3>Park Street Fees</h3>
              {hasWarehouseHistory && (
                <>
                  <div role="alert" className="fade alert-outline alert alert-primary alert-dismissible show">
                    <button type="button" className="btn-close d-none"></button>
                    <div className="alert-icon d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon icon={faBell} />
                    </div>
                    <div className="alert-message">
                      <strong>There are pending edits awaiting your approval.&nbsp;</strong> Click to approve.
                      <button
                        disabled={
                          (!role?.includes(UserRole.PricingAdmin) && !role?.includes(UserRole.Admin)) ||
                          hasWarehouseHistory?.approvals.some((item) => item.createdBy === id)
                        }
                        className="btn btn-success mx-2"
                        onClick={() => addAproval(false)}
                        style={{ textAlign: 'right' }}
                      >
                        Approve
                      </button>
                      <button
                        disabled={
                          (!role?.includes(UserRole.PricingAdmin) && !role?.includes(UserRole.Admin)) ||
                          hasWarehouseHistory?.approvals.some((item) => item.createdBy === id)
                        }
                        className="btn btn-danger mx-2"
                        onClick={(event) => {
                          AdminService.RejectApproval({
                            referenceId: hasWarehouseHistory?.approvalReferenceId,
                            isProductApproval: false,
                          })
                            .then(() => {
                              getFees();
                              hideLoading();
                              toast.success('The request has been rejected.');
                            })
                            .catch((e) => {
                              hideLoading();
                              setError(e.status ? e : { status: 500 });
                            });
                        }}
                        style={{ textAlign: 'right' }}
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row">
            <FreightFees
              freightFees={freightFees}
              setFreightFees={setFreightFees}
              brands={brands}
              warehouses={warehouses}
              deletedFees={deletedFreightFees}
              setDeletedFees={setDeletedFreightFees}
              createdFees={createdFreightFees}
              setCreatedFees={setCreatedFreightFees}
              updatedFees={updatedFreightFees}
              setUpdatedFees={setUpdatedFreightFees}
              history={history}
              submit={submit}
              hasFreightHistory={hasFreightHistory}
            />
            <WarehouseFees
              warehouseFees={warehouseFees}
              setWarehouseFees={setWarehouseFees}
              warehouses={warehouses}
              createdWarehouseFees={createdWarehouseFees}
              setCreatedWarehouseFees={setCreatedWarehouseFees}
              updatedWarehouseFees={updatedWarehouseFees}
              setUpdatedWarehouseFees={setUpdatedWarehouseFees}
              history={history}
              submit={submit}
              hasWarehouseHistory={hasWarehouseHistory}
              deletedWarehouseFees={deletedWarehouseFees}
              setDeletedWarehouseFees={setDeletedWarehouseFees}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminFee;
