import React, { useEffect, useMemo, useState } from 'react';
import { hideLoading, showLoading } from '../../lib/uiService';
import { useForecast } from '../../providers/ForecastProvider';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faSort } from '@fortawesome/free-solid-svg-icons';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import { Table } from 'react-bootstrap';
import ForecastService from '../../services/forecast.service';
import { buildTreeOptionsFromTerritories } from '../../utils/buildTreeOption';
import { CheckTreePicker } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';
import AnalysisService from '../../services/analysis.service';
import LineChart from './LineChart';
import { softRgbaColors } from '../../constants';
import { formatNum, formatNumOneDecimal } from '../../utils/formatNum';
import DownloadSelector from '../../components/DownloadSelector';
import Pagination from '../../components/Pagination';

interface FormValues {
  salesSelected: string[];
  forecastSelected: string[];
  territoriesSelected: string[];
  brandsSelected: string[];
}

const AnalysisPage: React.FC = () => {
  const { hasForecasts } = useForecast();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [currentStartPage, setCurrentStartPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [startPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [count, setCount] = useState<number>(1);
  const [salesOptions, setSalesOptions] = useState([]);
  const [territories, setTerritories] = useState([]);
  const [forecasts, setForecasts] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [brands, setBrands] = useState([]);
  const [salesGraphData, setSalesGraphData] = useState<any>([]);
  const [initialData, setInitialData] = useState([]);
  const [rangeSelectionOne, setRangeSelectionOne] = useState('cases');
  const [rangeSelectionTwo, setRangeSelectionTwo] = useState('totals');
  const [errorMessage, setErrorMessage] = useState('');
  const [values, setValues] = useState<FormValues>({
    salesSelected: [],
    forecastSelected: [],
    territoriesSelected: [],
    brandsSelected: [],
  });

  useEffect(() => {
    if (isLoading && hasForecasts) {
      showLoading();
    } else {
      hideLoading();
    }
  }),
    [isLoading];

  useEffect(() => {
    setIsLoading(true);
    ForecastService.GetYearSales()
      .then(({ data }) => {
        const options: any = data.map((item: any) => {
          return {
            key: item.a,
            label: item.a,
            value: item.a,
          };
        });
        setSalesOptions(options);
      })
      .catch((e) => {
        console.log(e);
      });
    ForecastService.GetForecasts({ page: 1, pageSize: 100, search: '' })
      .then(({ data }) => {
        const options: any = data.forecasts.map((item: any) => {
          return {
            key: item.forecastId,
            label: item.name,
            value: item.forecastId,
          };
        });
        setForecasts(options);
      })
      .catch((error) => console.log(error));
    ForecastService.GetTerritoriesHierarchy()
      .then(({ data }) => {
        const value = buildTreeOptionsFromTerritories(data);
        setTerritories(value);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
    ForecastService.GetBrands()
      .then(({ data }) => {
        const brands = data.brands.map((brand: any) => Object.assign({ label: brand.name, value: brand.brandId }));
        setBrands(brands);
        hideLoading();
      })
      .catch((error) => console.error(error));
    setIsLoading(false);
  }, []);

  const onSelectValues = (label: keyof FormValues, item: any) => {
    setValues({
      ...values,
      [label]: item,
    });
  };

  const sortCell = (rowA: any, rowB: any, position: number) => {
    const valueA = rowA.original?.quarters[position]?.result || 0;
    const valueB = rowB.original?.quarters[position]?.result || 0;
    return valueB - valueA;
  };

  const formatCell = (value: any, position: number) => {
    const result =
      rangeSelectionOne === 'cases'
        ? formatNumOneDecimal(value[position]?.result || 0)
        : `$${formatNum(value[position]?.result || 0)}`;
    return result;
  };

  const columns: any = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'year',
        canSort: true,
      },
      {
        accessor: 'quarters',
        Header: 'Q1',
        id: 'q1',
        sortType: (rowA: any, rowB: any) => {
          return sortCell(rowA, rowB, 0);
        },
        Cell: ({ value }: { value: any }) => {
          return formatCell(value, 0);
        },
        canSort: true,
      },
      {
        accessor: 'quarters',
        id: 'q2',
        Header: 'Q2',
        sortType: (rowA: any, rowB: any) => {
          return sortCell(rowA, rowB, 1);
        },
        Cell: ({ value }: { value: any }) => {
          return formatCell(value, 1);
        },
        canSort: true,
      },
      {
        accessor: 'quarters',
        id: 'q3',
        Header: 'Q3',
        sortType: (rowA: any, rowB: any) => {
          return sortCell(rowA, rowB, 2);
        },
        Cell: ({ value }: { value: any }) => {
          return formatCell(value, 2);
        },
        canSort: true,
      },
      {
        accessor: 'quarters',
        id: 'q4',
        Header: 'Q4',
        sortType: (rowA: any, rowB: any) => {
          return sortCell(rowA, rowB, 3);
        },
        Cell: ({ value }: { value: any }) => {
          return formatCell(value, 3);
        },
        canSort: true,
      },
      {
        accessor: 'quarters',
        id: 'totals',
        Header: 'Total',
        Cell: ({ data, row }: { data: any; row: any }) => {
          const rowData = data[row.id];
          const total =
            Number(rowData.quarters[0]?.result) +
            Number(rowData.quarters[1]?.result) +
            Number(rowData.quarters[2]?.result) +
            Number(rowData.quarters[3]?.result);

          return rangeSelectionOne === 'cases' ? formatNumOneDecimal(total || 0) : `$${formatNum(total || 0)}`;
        },
        canSort: true,
      },
    ];
  }, [salesData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter } = useTable(
    {
      columns: columns,
      data: useMemo(() => salesData, [salesData]),
    },
    useGlobalFilter,
    useSortBy,
  );

  const onChangeRangeSelectionOne = (e: any) => {
    setRangeSelectionOne(e.target.id);
  };

  const onChangeRangeSelectionTwo = (e: any) => {
    setRangeSelectionTwo(e.target.id);
    setErrorMessage('');
  };

  const getAnalysisData = async () => {
    if (rangeSelectionTwo === 'brand' && !values.brandsSelected.length) {
      setErrorMessage('At least one brand is required to analize by Brand.');
    } else if (values.forecastSelected.length || values.salesSelected.length) {
      setIsLoading(true);
      setErrorMessage('');
      AnalysisService.GetSalesAnalysis({
        years: values.salesSelected.map((item: any) => item.value) || [],
        forecastIds: values.forecastSelected.map((item: any) => item.value) || [],
        territoryIds: values.territoriesSelected,
        queryType: rangeSelectionTwo,
        subQueryType: rangeSelectionOne,
        brands: values.brandsSelected.map((item: any) => item.value),
      })
        .then(({ data }) => {
          let colorNumber = 0;
          setCount(data.length);
          setTotalPages(Math.ceil(data.length / pageSize));
          const graphData = data.map((item: any, index: number) => {
            const color = softRgbaColors[index] || softRgbaColors[colorNumber];
            if (!softRgbaColors[index]) colorNumber++;
            return {
              label: (rangeSelectionOne === 'cases' ? 'Cases Sold of ' : 'Revenue from ') + item.year,
              data: item.quarters.map((quart: any) => quart.result),
              backgroundColor: color,
              borderColor: color,
              tension: 0.1,
            };
          });
          const tableData = data.map((item: any) => ({
            ...item,
            year: (rangeSelectionOne === 'cases' ? 'Cases Sold of ' : 'Revenue from ') + item.year,
          }));
          setSalesGraphData(graphData);
          setSalesData(tableData);
          setInitialData(tableData);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setErrorMessage('A forecast or a sales year is required to create analysis.');
    }
  };

  const row: any = [];
  const downloadColumnHeaders = ['Name', 'Q1', 'Q2', 'Q3', 'Q4', 'Totals'];

  return (
    <>
      <h3 className="text-dark mb-4">Case and Revenue Analysis</h3>
      <div className="row">
        <div className="col">
          <div className="card">
            {salesGraphData.length === 0 ? (
              <div className="card-body" style={{ backgroundColor: '#DDDDDD', height: '400px' }}>
                <div className="h-100 d-flex justify-content-center align-items-center">
                  <p className="text-center" style={{ fontSize: '24px' }}>
                    <strong>
                      <span style={{ color: 'rgb(73, 80, 87)' }}>
                        Select options from the right to display graph and table
                      </span>
                    </strong>
                  </p>
                </div>
              </div>
            ) : (
              <div className="card-body">
                <LineChart data={salesGraphData} type={rangeSelectionOne} />
              </div>
            )}
          </div>
        </div>
        <div className="col col-4">
          <div className="card shadow p-0 mt-40">
            <div className="card-header py-3">
              <p className="text-primary m-0 fw-bold">Graph options</p>
            </div>
            <div className="card-body pt-0">
              <div className="row mb-3">
                <div className="col">
                  <div className="btn-group w-100" aria-label="Basic radio toggle button grou">
                    <p>Sales:</p>
                  </div>
                  <Select
                    defaultValue={values.salesSelected}
                    isMulti
                    name="sales"
                    closeMenuOnSelect={false}
                    options={salesOptions}
                    styles={{
                      multiValueLabel: (styles) => {
                        return { ...styles, color: 'white', fontSize: '100%', backgroundColor: '#3f80ea' };
                      },
                      multiValue: (styles) => {
                        return { ...styles, color: 'white', fontSize: '100%', backgroundColor: '#3f80ea' };
                      },
                      multiValueRemove: (styles) => {
                        return { ...styles, color: 'white', backgroundColor: '#3f80ea' };
                      },
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(item: any) => onSelectValues('salesSelected', item)}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="btn-group w-100">
                    <p>Forecasts</p>
                  </div>
                  <Select
                    defaultValue={values.forecastSelected}
                    isMulti
                    name="forecast"
                    closeMenuOnSelect={false}
                    styles={{
                      multiValueLabel: (styles) => {
                        return { ...styles, color: 'white', fontSize: '100%', backgroundColor: '#3f80ea' };
                      },
                      multiValue: (styles) => {
                        return { ...styles, color: 'white', fontSize: '100%', backgroundColor: '#3f80ea' };
                      },
                      multiValueRemove: (styles) => {
                        return { ...styles, color: 'white', backgroundColor: '#3f80ea' };
                      },
                    }}
                    options={forecasts}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(item: any) => onSelectValues('forecastSelected', item)}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="btn-group w-100">
                  <p>Territories</p>
                </div>
                <div
                  style={{
                    overflow: 'hidden',
                    zIndex: 0,
                  }}
                >
                  <CheckTreePicker
                    defaultValue={values.territoriesSelected}
                    data={territories}
                    searchable={false}
                    onChange={(item: any) => onSelectValues('territoriesSelected', item)}
                    placeholder="Select..."
                    style={{ zIndex: 0, width: '280px' }}
                    menuStyle={{ height: '240px' }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="btn-group w-100" aria-label="Basic radio toggle button grou">
                    <input type="radio" className="btn-check" autoComplete="off" />
                    <label
                      id="cases"
                      className={`form-label btn ${rangeSelectionOne === 'cases' ? 'btn-primary' : 'btn-outline-primary'} w-50`}
                      onClick={onChangeRangeSelectionOne}
                    >
                      By Cases
                    </label>
                    <input type="radio" className="btn-check" autoComplete="off" />
                    <label
                      id="revenue"
                      className={`form-label btn ${rangeSelectionOne === 'revenue' ? 'btn-primary' : 'btn-outline-primary'} w-50`}
                      onClick={onChangeRangeSelectionOne}
                    >
                      By Revenue
                    </label>
                  </div>
                  <div className="btn-group w-100" aria-label="Basic radio toggle button grou">
                    <input type="radio" className="btn-check" autoComplete="off" />
                    <label
                      id="totals"
                      className={`form-label btn ${rangeSelectionTwo === 'totals' ? 'btn-primary' : 'btn-outline-primary'} w-50`}
                      onClick={onChangeRangeSelectionTwo}
                    >
                      By Totals
                    </label>
                    <input type="radio" className="btn-check" autoComplete="off" />
                    <label
                      id="brand"
                      className={`form-label btn ${rangeSelectionTwo === 'brand' ? 'btn-primary' : 'btn-outline-primary'} w-50`}
                      onClick={onChangeRangeSelectionTwo}
                    >
                      By Brand
                    </label>
                  </div>
                </div>
              </div>
              {rangeSelectionTwo === 'brand' && (
                <div className="row mb-3">
                  <div className="col">
                    <div className="btn-group w-100">
                      <p>Brands</p>
                    </div>
                    <Select
                      defaultValue={values.brandsSelected}
                      isMulti
                      name="forecast"
                      closeMenuOnSelect={false}
                      styles={{
                        multiValueLabel: (styles) => {
                          return { ...styles, color: 'white', fontSize: '100%', backgroundColor: '#3f80ea' };
                        },
                        multiValue: (styles) => {
                          return { ...styles, color: 'white', fontSize: '100%', backgroundColor: '#3f80ea' };
                        },
                        multiValueRemove: (styles) => {
                          return { ...styles, color: 'white', backgroundColor: '#3f80ea' };
                        },
                      }}
                      options={brands}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(item: any) => onSelectValues('brandsSelected', item)}
                    />
                  </div>
                </div>
              )}
              <div>
                {errorMessage && <p className="text-danger">{errorMessage}</p>}
                <button
                  onClick={getAnalysisData}
                  className="btn btn-primary float-end"
                  type="submit"
                  style={{ textAlign: 'right' }}
                >
                  Run
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow" style={{ marginBottom: '44px' }}>
        <div className="card-header py-3">
          <p className="text-primary m-0 fw-bold">Graph Data</p>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 text-nowrap">
              <div id="dataTable_length-1" className="dataTables_length" aria-controls="dataTable">
                <label className="form-label">
                  Show&nbsp;
                  <select
                    className="d-inline-block form-select form-select-sm"
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                      setPage(1);
                      setTotalPages(Math.ceil(count / Number(e.target.value)));
                      setSalesData(initialData.slice(0, Number(e.target.value)));
                    }}
                  >
                    <option value="10" selected>
                      10
                    </option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  &nbsp;
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-end justify-content-md-end dataTables_filter">
                <label className="form-label d-flex justify-content-md-end">
                  <DownloadSelector
                    row={row}
                    fileName="Sales Analysis"
                    downloadColumnHeaders={downloadColumnHeaders}
                    prepareDataFunction={() => {
                      initialData.forEach((item: any) => {
                        const total =
                          Number(item.quarters[0]?.result) +
                          Number(item.quarters[1]?.result) +
                          Number(item.quarters[2]?.result) +
                          Number(item.quarters[3]?.result);
                        row.push([
                          { v: item.year, t: 's' },
                          {
                            v:
                              rangeSelectionOne === 'cases'
                                ? formatNumOneDecimal(item.quarters[0]?.result || 0)
                                : `$${formatNum(item.quarters[0]?.result || 0)}`,
                            t: 's',
                          },
                          {
                            v:
                              rangeSelectionOne === 'cases'
                                ? formatNumOneDecimal(item.quarters[1]?.result || 0)
                                : `$${formatNum(item.quarters[1]?.result || 0)}`,
                            t: 's',
                          },
                          {
                            v:
                              rangeSelectionOne === 'cases'
                                ? formatNumOneDecimal(item.quarters[2]?.result || 0)
                                : `$${formatNum(item.quarters[2]?.result || 0)}`,
                            t: 's',
                          },
                          {
                            v:
                              rangeSelectionOne === 'cases'
                                ? formatNumOneDecimal(item.quarters[3]?.result || 0)
                                : `$${formatNum(item.quarters[3]?.result || 0)}`,
                            t: 's',
                          },
                          {
                            v:
                              rangeSelectionOne === 'cases'
                                ? formatNumOneDecimal(total || 0)
                                : `$${formatNum(total || 0)}`,
                            t: 's',
                          },
                        ]);
                      });
                    }}
                  />
                  &nbsp;
                </label>
                <label className="form-label">
                  <input
                    type="search"
                    className="form-control form-control-sm"
                    aria-controls="dataTable"
                    placeholder="Search"
                    onChange={(e) => setGlobalFilter(e.target.value)}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="table-responsive table mt-2" id="dataTable-1" role="grid" aria-describedby="dataTable_info">
            <Table striped className="table-sm" bordered {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={index} className="table-header-gray">
                    {headerGroup.headers.map((column, index) => {
                      return (
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          key={index}
                          className="table-head-font-size"
                        >
                          {column.render('Header')}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                              ) : (
                                <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                              )
                            ) : (
                              <FontAwesomeIcon icon={faSort} className="ms-2" />
                            )}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row: any, i) => {
                  prepareRow(row);
                  return (
                    <React.Fragment key={i}>
                      <tr {...row.getRowProps()} id="1" className="table-body-font-size">
                        {row.cells.map((cell: any, index: number) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              key={index}
                              style={{ border: 'none' }}
                              className={'text-dark align-middle'}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div className="row">
            <div className="col-md-6 align-self-center">
              <p id="dataTable_info-3" className="dataTables_info" role="status" aria-live="polite">
                Showing {pageSize > count ? count : pageSize} of {count}
              </p>
            </div>
            <div className="col-md-6 mb-2">
              <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                <ul className="pagination">
                  <li className={'page-item cursor-pointer' + (page - 1 > 0 ? '' : ' disabled')}>
                    <div
                      className="page-link"
                      aria-label="Previous"
                      onClick={() => {
                        if (page > 1) {
                          const newPage = page - 1;
                          setPage(newPage);
                          if (page === currentStartPage) {
                            setCurrentStartPage(Math.max(currentStartPage - 1, 1));
                          }
                          const indexOfLastItem = newPage * pageSize;
                          const indexOfFirstItem = indexOfLastItem - pageSize;
                          setSalesData(initialData.slice(indexOfFirstItem, indexOfLastItem));
                        }
                      }}
                    >
                      <span aria-hidden="true">«</span>
                    </div>
                  </li>
                  <Pagination
                    currentStartPage={currentStartPage}
                    totalPages={totalPages}
                    startPage={startPage}
                    pageSize={pageSize}
                    count={count}
                    page={page}
                    setPage={setPage}
                    setData={setSalesData}
                    initialData={initialData}
                    setCurrentStartPage={setCurrentStartPage}
                  />
                  <li className={'page-item cursor-pointer' + (page + 1 <= totalPages ? '' : ' disabled')}>
                    <div
                      className="page-link"
                      aria-label="Next"
                      onClick={() => {
                        const newPage = page + 1;
                        setCurrentStartPage(currentStartPage + 1);
                        setPage(newPage);
                        const indexOfLastItem = newPage * pageSize;
                        const indexOfFirstItem = indexOfLastItem - pageSize;
                        setSalesData(initialData.slice(indexOfFirstItem, indexOfLastItem));
                      }}
                    >
                      <span aria-hidden="true">»</span>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalysisPage;
