import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { UploadBonusCard } from '../../components/SalesBonus/Upload';
import { hideLoading, showLoading } from '../../lib/uiService';
import CustomTable from '../../components/common/Table';
import salesCompService from '../../services/sales.bonus.service';
import { formatDate } from '../../utils/formatDate';
import useErrorHandling from '../../hooks/useErrorHandling';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const UploadSalesBonus: React.FC = () => {
  const [importFile, setFile] = useState<File>();
  const [salesCompList, setSalesCompList] = useState<any[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { setError } = useErrorHandling();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [salesCompId, setSalesCompId] = useState('');

  const handleFile = (e: any) => {
    const file = e.target.files && e.target.files[0];
    if (!file) {
      setFile(undefined);
      toast.warn('Please select a file');
      return;
    }
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (fileExtension !== 'xlsx') {
      toast.warn('File type must be .xlsx');
      return;
    }
    setFile(file);
  };

  useEffect(() => {
    getSalesComps();
  }, [location, setSalesCompList]);

  const getSalesComps = () => {
    showLoading();
    salesCompService
      .GetSalesComp()
      .then(({ data }) => {
        if (data.length > 0) {
          const tempSalesCompList = data.map((comp: any) => {
            const startDate = formatDate(
              { month: '2-digit', day: '2-digit', year: 'numeric' },
              new Date(comp.startDate),
            );
            const endDate = formatDate({ month: '2-digit', day: '2-digit', year: 'numeric' }, new Date(comp.endDate));
            return { period: `${startDate ?? ''} - ${endDate ?? ''}`, salesCompId: comp.salesCompId };
          });
          setSalesCompList(tempSalesCompList);
        } else {
          setSalesCompList([]);
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ status: error.response.status });
      });
    hideLoading();
  };

  const onDeleteSalesComp = async (salesCompId: string) => {
    try {
      await salesCompService.DeleteSalesComp(salesCompId);
      getSalesComps();
    } catch (error) {
      console.error(error);
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'No',
        id: 'no',
        Cell: ({ row }: { row: { id: number } }) => {
          return <td>{Number(row.id) + 1}</td>;
        },
      },
      {
        Header: 'Bonus Period',
        id: 'period',
        accessor: 'period',
        canSort: true,
        Cell: ({ data, row }: { data: Array<{ period: string; salesCompId: string }>; row: { id: number } }) => {
          const { period, salesCompId } = data[row.id];
          return (
            <td onClick={() => navigate(`/sales-bonus/detail/${salesCompId}`)} style={{ cursor: 'pointer' }}>
              <span className="text-decoration-underline" style={{ cursor: 'pointer' }}>
                {period}
              </span>
            </td>
          );
        },
      },
      {
        Header: '',
        id: 'action',
        canSort: false,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const { salesCompId } = data[row.id];
          return (
            <td>
              <button
                className="btn btn-primary btn-sm me-3"
                onClick={() => {
                  salesCompService
                    .SendBonusEmails({ salesCompId })
                    .then(() => {
                      toast.success('Emails successfully sent');
                    })
                    .catch((err) => {
                      console.error(err);
                      setError(err.status);
                    });
                }}
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </button>
              <button
                className="btn btn-danger btn-sm"
                type="button"
                onClick={() => {
                  setSalesCompId(salesCompId);
                  setIsModalOpen(true);
                }}
              >
                <FontAwesomeIcon icon={faClose} />
              </button>
            </td>
          );
        },
      },
    ];
  }, [salesCompList]);

  return (
    <div className="col-4 m-auto">
      <h3 className="text-dark mb-4">Upload Sales Bonus</h3>
      <div className="row">
        <UploadBonusCard
          title="Upload Monthly Sales Bonus Data"
          importBody={
            <InputGroup className="mt-3">
              <Form.Control type="file" onChange={(e) => handleFile(e)} />
            </InputGroup>
          }
          exportBody={
            <>
              <div className="d-flex justify-content-end mt-3">
                <Button
                  disabled={!importFile}
                  onClick={() => navigate('/sales-bonus/review', { state: importFile })}
                  variant="primary"
                  id="button-addon2"
                >
                  Review
                </Button>
              </div>
              {salesCompList.length > 0 && (
                <div className="mt-3">
                  <CustomTable columns={columns} data={salesCompList} />
                </div>
              )}
            </>
          }
        />
      </div>
      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} centered>
        <Modal.Header closeButton>Confirm Action</Modal.Header>
        <Modal.Body className="text-center m-3">
          <h6>Are you sure you want to delete? This will remove all data related to this Sales Bonus period</h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={async () => {
              onDeleteSalesComp(salesCompId);
              setIsModalOpen(false);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UploadSalesBonus;
