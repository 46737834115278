import React from 'react';
import { ForecastCalculator } from 'forecast-calculator';
import ForecastService from '../services/forecast.service';

const useForecastCalculator = () => {
  const [forecastCalculator, $forecastCalculator] = React.useState<any>(null);

  return {
    forecastCalculator,
    loadForecastCalculator: async (forecastId, calendarId?, territoryId?, isExec?) => {
      // loadForecastCalculator
      const forecastMetaData = await ForecastService.GetForecastCalculatorMetaData({
        forecastId,
        territoryId,
        calendarId,
        isExec,
      });
      const territories = forecastMetaData?.data?.territories || [];
      const calendars = forecastMetaData?.data?.calendars;
      const forecastBudgets = forecastMetaData?.data?.forecastBudgets;
      const forecastProducts = forecastMetaData?.data?.forecastProducts;
      const forecastBudgetProjections = forecastMetaData?.data?.forecastBudgetProjections;
      const forecastProductProjections = forecastMetaData?.data?.forecastProductProjections;

      const brands = forecastMetaData?.data?.brands || [];
      const forecastCalculator = new ForecastCalculator({ forecastId, territories, calendars, brands });
      if (forecastBudgets) {
        forecastCalculator.loadForecastBudgets(forecastBudgets);
      }
      if (forecastProducts) {
        forecastCalculator.loadForecastProducts(forecastProducts);
      }

      // load forecastBudgetProjections
      if (forecastBudgetProjections) {
        forecastCalculator.loadForecastBudgetProjections(forecastBudgetProjections);
      }

      // load forecastProductProjections
      if (forecastProductProjections) {
        forecastCalculator.loadForecastProductProjections(forecastProductProjections);
      }

      $forecastCalculator(forecastCalculator);
      return forecastCalculator;
    },
  };
};

export default useForecastCalculator;
