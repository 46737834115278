import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { roundRetailPrice } from '../../../../utils/calculation';
import AdminService from '../../../../services/admin.service';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import { CurrencyTypes } from '../../../../constants';

const ProductDetail = ({
  labels,
  disabled,
  warehousesFees,
}: {
  labels: any;
  disabled: boolean;
  warehousesFees: any;
}) => {
  const { setFieldValue, values } = useFormikContext<any>();
  const { setError } = useErrorHandling();
  const [availableWarehoses, setAvailableWarehoses] = useState([]);
  const [disableFees, setDisableFees] = useState(false);

  useEffect(() => {
    if (values.brandId) {
      AdminService.GetAvailableWarehousePerFreight({ brandId: values.brandId })
        .then(({ data }) => {
          const highestFeeObject = data.freight.reduce((prev, current) => {
            return Number(current.fee) > Number(prev.fee) ? current : prev;
          }, data.freight[0]);
          if ((!values.freight || values.freight === '') && highestFeeObject?.fee) {
            updateValues('freight', Number(highestFeeObject?.fee), true);
          }
          const list = data.warehouses.map((item) => ({
            ...item,
            value: item.warehouseId,
            label: item.shortName,
          }));
          list.push({ label: 'Manual', value: '' });
          setDisableFees(values.warehouseId !== '');
          setAvailableWarehoses(list);
        })
        .catch((error) => {
          console.log(error);
          setError({ status: error.response.status || 500 });
        });
    }
  }, [values.brandId]);

  useEffect(() => {
    if (values.brandId && values.warehouseId) {
      AdminService.GetFreightFees({ warehouseId: values.warehouseId, brandId: values.brandId })
        .then(({ data }) => {
          updateValues('freight', Number(data.fee));
        })
        .catch((error) => {
          console.log(error);
          setError({ status: error.response.status || 500 });
        });
    }
  }, [values.brandId, values.warehouseId]);

  const parseProductSpecificName = (input: string) => {
    const regex = /(\d+)\/(\d+)[mM][lL]|(\d+)[mM][lL]\/(\d+)/;
    const match = input.match(regex);
    if (match) {
      const quantity = match[1] || match[4];
      const volume = match[2] || match[3];
      return { quantity: parseInt(quantity, 10), volume: parseInt(volume, 10) };
    }
    return { quantity: '', volume: '' };
  };

  const selectedOptionLabel = labels?.find((option: any) => option.value === values?.labelId) || null;
  const selectedOptionWarehouse = availableWarehoses?.find(
    (option: any) => option.warehouseId === values?.warehouseId,
  ) || { label: 'Manual', value: '' };

  const updateValues = (field: string, fieldValue: any, checkNumber = true) => {
    setFieldValue(field, fieldValue);
    if (!isNaN(fieldValue) || !checkNumber) {
      const value = checkNumber ? Number(fieldValue) : fieldValue;
      const cogs = field === 'cogs' ? value : Number(values.cogs || 0);
      const retailMarkup = field === 'retailMarkup' ? value : Number(values.retailMarkup || 0);
      const freight = field === 'freight' ? value : Number(values.freight || 0);
      const margin = field === 'margin' ? value : Number(values.margin || 0);
      const warehouseFees =
        field === 'warehouseId' && value !== ''
          ? Number(warehousesFees.find((item) => item.warehouseId === value)?.fee)
          : 1.97;
      const psiFees = 1.24;
      const distributorMargin = field === 'distributorMargin' ? value : Number(values.distributorMargin || 0);
      const insurance = field === 'insurance' ? value : Number(values.insurance);
      const finalQuantity = field === 'quantity' ? value : Number(values.quantity) || 6;
      const finalVolume = field === 'volume' ? value : Number(values.volume) || 750;
      const distributorAllowance = field === 'distributorAllowance' ? value : Number(values.distributorAllowance);
      const federalExciseTax =
        field === 'federalExciseTax'
          ? value
          : (Number(values.abv || 0) / 80) * 2.14 * finalQuantity * (finalVolume / 750);
      const landedCogs = field === 'landedCogs' ? value : cogs + freight + federalExciseTax + Number(insurance || 0);
      let preFeeFOB = field === 'preFeeFOB' ? value : landedCogs / (1 - 1 * (margin / 100));

      const natlFob =
        field === 'natlFob' ? value : values.isFrozen ? values.natlFob : preFeeFOB + warehouseFees + psiFees;

      //if isFrozen is true adjust the margin to maintain the fixed natlFob
      if (values.isFrozen) {
        preFeeFOB = field === 'preFeeFOB' ? value : natlFob - warehouseFees - psiFees;
        const newMargin =
          field === 'margin' ? value : preFeeFOB === 0 ? 0 : ((preFeeFOB - landedCogs) / preFeeFOB) * 100;
        setFieldValue(`margin`, floorToTwoDecimals(newMargin));
      }

      const distributorBottlePrice =
        field === 'distributorBottlePrice'
          ? value
          : distributorMargin === 0
            ? Math.ceil(natlFob)
            : natlFob / (1 - 1 * (Number(distributorMargin) / 100)) || '';
      const distributorCasePrice =
        field === 'distributorCasePrice'
          ? value
          : finalQuantity === 0
            ? 0
            : (Number(distributorBottlePrice) - distributorAllowance) / finalQuantity || '';
      const markup =
        field === 'markup'
          ? value
          : Number(natlFob || 0) -
            cogs -
            Number(federalExciseTax || 0) -
            Number(insurance || 0) -
            freight -
            psiFees -
            warehouseFees;
      const retailPrice =
        field === 'retailPrice' ? value : Number(distributorCasePrice) * (1 + 1 * (Number(retailMarkup) / 100)) || '';
      const suggestedRetailPrice =
        field === 'suggestedRetailPrice' ? value : roundRetailPrice(Number(retailPrice)) || '';

      setFieldValue(`natlFob`, floorToTwoDecimals(Math.ceil(natlFob)));
      setFieldValue(`markup`, floorToTwoDecimals(markup));
      setFieldValue(`distributorBottlePrice`, floorToTwoDecimals(distributorBottlePrice));
      setFieldValue(`distributorCasePrice`, floorToTwoDecimals(distributorCasePrice));
      setFieldValue(`retailPrice`, floorToTwoDecimals(retailPrice));
      setFieldValue(`suggestedRetailPrice`, floorToTwoDecimals(suggestedRetailPrice));
      setFieldValue(`federalExciseTax`, floorToTwoDecimals(federalExciseTax));
      setFieldValue(`landedCogs`, floorToTwoDecimals(landedCogs));
      setFieldValue(`preFeeFOB`, floorToTwoDecimals(preFeeFOB));
      setFieldValue(`psiFees`, floorToTwoDecimals(psiFees));
      setFieldValue(`warehouseFees`, floorToTwoDecimals(warehouseFees));
    }
  };

  const floorToTwoDecimals = (value: any) => {
    return Math.floor(Number(value) * 100) / 100;
  };

  return (
    <div>
      <div className="row mt-3">
        <div className="col col-4">
          <label className="form-label">Description</label>
          <div className="d-flex">
            <Field name="label" disabled={true} type="text" className="form-control" />
            <Field
              disabled={disabled}
              name="productSpecificName"
              type="text"
              className="form-control"
              onChange={(e: any) => {
                const inputValue = e.target.value;
                setFieldValue('productSpecificName', inputValue);
                const { quantity, volume } = parseProductSpecificName(inputValue);
                setFieldValue('quantity', quantity);
                setFieldValue('volume', volume);
              }}
            />
          </div>
          <ErrorMessage name="productSpecificName" component="p" className="text-danger" />
        </div>
        <div className="col col-4">
          <label className="form-label">Label</label>
          <div>
            <Select
              isDisabled={disabled}
              isSearchable={true}
              defaultInputValue=""
              value={selectedOptionLabel}
              className="w-100"
              onChange={(option: any) => {
                const brandId = labels.find((item) => item.labelId === option.value).brandFamily.brand.brandId;
                setFieldValue('labelId', option.value);
                setFieldValue('brandId', brandId);
                setFieldValue(
                  'prevProductName',
                  `${option.brandFamily.brand.name as string} ${option.labelName as string}`,
                );
              }}
              options={labels}
              styles={{
                container: (baseStyles) => ({
                  ...baseStyles,
                  width: '180px',
                }),
                control: (baseStyles) => ({
                  ...baseStyles,
                  minHeight: '27px',
                  borderColor: 'rgb(118, 118, 118)',
                }),
                valueContainer: (baseStyles) => ({
                  ...baseStyles,
                  height: '27px',
                  alignItems: 'normal',
                }),
                indicatorsContainer: (baseStyles) => ({
                  ...baseStyles,
                  height: '27px',
                }),
              }}
            />
          </div>
        </div>
        <div className="col col-4">
          <label className="form-label">Display Fees From:</label>
          <div>
            <Select
              isDisabled={disabled}
              defaultInputValue=""
              value={selectedOptionWarehouse}
              className="w-100"
              onChange={(option: any) => {
                setDisableFees(option.value !== '');
                updateValues('warehouseId', option.value, false);
              }}
              options={availableWarehoses}
              styles={{
                container: (baseStyles) => ({
                  ...baseStyles,
                  width: '180px',
                }),
                control: (baseStyles) => ({
                  ...baseStyles,
                  minHeight: '27px',
                  borderColor: 'rgb(118, 118, 118)',
                }),
                valueContainer: (baseStyles) => ({
                  ...baseStyles,
                  height: '27px',
                  alignItems: 'normal',
                }),
                indicatorsContainer: (baseStyles) => ({
                  ...baseStyles,
                  height: '27px',
                }),
              }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="row mt-3">
        <div className="col ps-3 col-4" style={{ borderRight: '1px solid #EEEEEE' }}>
          <div className="row">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                COGs
              </label>
              <Field
                onChange={(e) => {
                  updateValues('cogs', e.target.value);
                }}
                disabled={disabled}
                name="cogs"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Currency
              </label>
              <Field as="select" name="currencyType" className="form-select">
                <option value={CurrencyTypes.USD}>USD</option>
                <option value={CurrencyTypes.EUR}>EUR</option>
              </Field>
            </div>
          </div>
          <ErrorMessage name="cogs" component="p" className="text-danger" />
          <div className="row">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Freight/Clearance
              </label>
              <Field
                onChange={(e) => {
                  updateValues('freight', e.target.value);
                }}
                disabled={disabled || disableFees}
                name="freight"
                type="text"
                className="form-control"
              />
            </div>
            <ErrorMessage name="freight" component="p" className="text-danger" />
          </div>
          <div className="row">
            <div className="col">
              <div>
                <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                  Insurance
                </label>
                <Field
                  onChange={(e) => {
                    updateValues('insurance', e.target.value);
                  }}
                  disabled={disabled}
                  name="insurance"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <ErrorMessage name="insurance" component="p" className="text-danger" />
          </div>
          <div className="row">
            <div className="col">
              <div className="mt-2">
                <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                  Landed COGs
                </label>
                <Field
                  onChange={(e) => {
                    updateValues('landedCogs', e.target.value);
                  }}
                  disabled={disabled}
                  name="landedCogs"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <ErrorMessage name="landedCogs" component="p" className="text-danger" />
          </div>
          <div className="row">
            <div className="col">
              <div>
                <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                  Warehouse Fees
                </label>
                <Field
                  onChange={(e) => {
                    updateValues('warehouseFees', e.target.value);
                  }}
                  disabled={disabled || disableFees}
                  name="warehouseFees"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <ErrorMessage name="warehouseFees" component="p" className="text-danger" />
          </div>
          <div className="row">
            <div className="col">
              <div>
                <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                  PSI Fees
                </label>
                <Field
                  onChange={(e) => {
                    updateValues('psiFees', e.target.value);
                  }}
                  disabled
                  name="psiFees"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <ErrorMessage name="psiFees" component="p" className="text-danger" />
          </div>
        </div>
        <div className="col ps-4 col-4" style={{ borderRight: '1px solid #EEEEEE' }}>
          <div className="row">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Nat&apos;l FOB
              </label>
              <div className="d-flex flex-row align-items-center">
                <Field
                  onChange={(e) => {
                    updateValues('natlFob', e.target.value);
                  }}
                  disabled={values.isFrozen || disabled}
                  name="natlFob"
                  type="text"
                  className="form-control"
                />
                <FontAwesomeIcon
                  onClick={() => {
                    setFieldValue('isFrozen', !values.isFrozen);
                  }}
                  className="ms-1"
                  style={{ color: values.isFrozen ? '#959595' : '#343434', cursor: 'pointer' }}
                  icon={values.isFrozen ? faLock : faLockOpen}
                />
              </div>
            </div>
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Pre-Fees FOB
              </label>
              <Field
                onChange={(e) => {
                  updateValues('preFeeFOB', e.target.value);
                }}
                disabled={disabled}
                name="preFeeFOB"
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Tier 1
              </label>
              <Field name="tier1Price" type="text" className="form-control" />
            </div>
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Tier 2
              </label>
              <Field name="tier2Price" type="text" className="form-control" />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ErrorMessage name="tier1Price" component="p" className="text-danger" />
            </div>
            <div className="col">
              <ErrorMessage name="tier2Price" component="p" className="text-danger" />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Markup
              </label>
              <Field
                onChange={(e) => {
                  updateValues('markup', e.target.value);
                }}
                disabled={disabled}
                name="markup"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Margin
              </label>
              <div className="col d-flex">
                <Field
                  onChange={(e) => {
                    updateValues('margin', e.target.value);
                  }}
                  disabled={values.isFrozen || disabled}
                  name="margin"
                  type="text"
                  className="form-control"
                />
                <span className="input-group-text">%</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ErrorMessage name="markup" component="p" className="text-danger" />
            </div>
            <div className="col">
              <ErrorMessage name="margin" component="p" className="text-danger" />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Case Quantity
              </label>
              <Field
                onChange={(e) => {
                  updateValues('quantity', e.target.value);
                }}
                disabled={disabled}
                name="quantity"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Volume/mL
              </label>
              <Field
                onChange={(e) => {
                  updateValues('volume', e.target.value);
                }}
                disabled={disabled}
                name="volume"
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ErrorMessage name="quantity" component="p" className="text-danger" />
            </div>
            <div className="col">
              <ErrorMessage name="volume" component="p" className="text-danger" />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                FET
              </label>
              <Field
                onChange={(e) => {
                  updateValues('federalExciseTax', e.target.value);
                }}
                disabled={disabled}
                name="federalExciseTax"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                ABV
              </label>
              <div className="col d-flex">
                <Field
                  onChange={(e) => {
                    updateValues('abv', e.target.value);
                  }}
                  disabled={disabled}
                  name="abv"
                  type="text"
                  className="form-control"
                />
                <span className="input-group-text">%</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ErrorMessage name="federalExciseTax" component="p" className="text-danger" />
            </div>
            <div className="col">
              <ErrorMessage name="abv" component="p" className="text-danger" />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Depletion Allowance
              </label>
              <Field
                onChange={(e) => {
                  updateValues('distributorAllowance', e.target.value);
                }}
                disabled={disabled}
                name="distributorAllowance"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Distributor Margin
              </label>
              <Field
                name="distributorMargin"
                onChange={(e) => {
                  updateValues('distributorMargin', e.target.value);
                }}
                disabled={disabled}
                type="text"
                className="form-control"
              />
            </div>
            <div className="row">
              <div className="col">
                <ErrorMessage name="distributorAllowance" component="p" className="text-danger" />
              </div>
              <div className="col">
                <ErrorMessage name="distributorMargin" component="p" className="text-danger" />
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                PSI Product Key
              </label>
              <Field disabled={disabled} name="bbpProductNumber" type="text" className="form-control" />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row mt-2">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Dist Case $
              </label>
              <Field
                onChange={(e) => {
                  updateValues('distributorCasePrice', e.target.value);
                }}
                disabled={disabled}
                name="distributorCasePrice"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Dist Btl $
              </label>
              <Field
                onChange={(e) => {
                  updateValues('distributorBottlePrice', e.target.value);
                }}
                disabled={disabled}
                name="distributorBottlePrice"
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ErrorMessage name="distributorBottlePrice" component="p" className="text-danger" />
            </div>
            <div className="col">
              <ErrorMessage name="distributorCasePrice" component="p" className="text-danger" />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Retail Markup
              </label>
              <Field
                onChange={(e) => {
                  updateValues('retailMarkup', e.target.value);
                }}
                disabled={disabled}
                name="retailMarkup"
                type="text"
                className="form-control"
              />
            </div>
            <ErrorMessage name="retailMarkup" component="p" className="text-danger" />
          </div>
          <div className="row mt-2">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Retail Price
              </label>
              <Field
                onChange={(e) => {
                  updateValues('retailPrice', e.target.value);
                }}
                disabled={disabled}
                name="retailPrice"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                SRP
              </label>
              <Field
                onChange={(e) => {
                  updateValues('suggestedRetailPrice', e.target.value);
                }}
                disabled={disabled}
                name="suggestedRetailPrice"
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ErrorMessage name="retailPrice" component="p" className="text-danger" />
            </div>
            <div className="col">
              <ErrorMessage name="suggestedRetailPrice" component="p" className="text-danger" />
            </div>
          </div>
          <div className="d-flex flex-column">
            <label className="form-label mt-2">Discontinued?</label>
            <div className="d-flex align-items-center">
              <div className="form-check form-switch">
                <input
                  disabled={disabled}
                  className="form-check-input"
                  type="checkbox"
                  style={{ width: 40, height: 20, cursor: 'pointer' }}
                  role="switch"
                  id="isDiscontinued"
                  checked={values?.isDiscontinued}
                  onChange={() => {
                    setFieldValue('isDiscontinued', !values.isDiscontinued);
                  }}
                />
              </div>
              <OverlayTrigger
                placement={'right'}
                overlay={
                  <Tooltip id={`tooltip-right`}>
                    A discontinued product is one that is STILL seen in a forecast as long as there were actual sales in
                    the period previous to a forecast. Its revenue IS accounted for as long as there are sales. If there
                    are no sales, the product will no longer show in future forecasts.
                  </Tooltip>
                }
              >
                <FontAwesomeIcon style={{ fontSize: '15px' }} className="mx-3" icon={faQuestionCircle} />
              </OverlayTrigger>
            </div>
            <label className="form-label mt-2">Active?</label>
            <div className="d-flex align-items-center">
              <div className="form-check form-switch">
                <input
                  disabled={disabled}
                  className="form-check-input"
                  type="checkbox"
                  style={{ width: 40, height: 20, cursor: 'pointer' }}
                  role="switch"
                  id="isActive"
                  checked={values?.productIsActive}
                  onChange={() => {
                    setFieldValue('productIsActive', !values.productIsActive);
                  }}
                />
              </div>
              <OverlayTrigger
                placement={'right'}
                overlay={
                  <Tooltip id={`tooltip-right`}>
                    An inactive product is NOT seen in any new forecasts regardless of previous sales. Its revenue is
                    NOT accounted for.
                  </Tooltip>
                }
              >
                <FontAwesomeIcon style={{ fontSize: '15px' }} className="mx-3" icon={faQuestionCircle} />
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
