import React, { useEffect } from 'react';
import { useAuth } from '../providers/AuthProvider';
import { Navigate, useNavigate } from 'react-router-dom';
import { ReactComponent as BackbarLogo } from '../assets/img/backbar-logo-black.svg';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { UserRole } from '../lib/types';
import { getRedirectUrl } from '../utils/roles';

const LogIn: React.FC = () => {
  const { isLoggedIn, role } = useAuth();
  const path = localStorage.getItem('path');
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      const checkRedirect = async () => {
        const redirectUrl = await getRedirectUrl(role, path);
        navigate(redirectUrl);
      };
      checkRedirect();
    }
  }, [isLoggedIn]);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
      <BackbarLogo className="mb-4" />
      <h3>Welcome back!</h3>
      <p style={{ fontSize: '14px' }}>Sign in to your account to continue</p>
      <div className="w-25 p-4 bg-white d-flex flex-column justify-content-center align-items-center ">
        <Button
          className="w-100"
          variant="google"
          onClick={() => {
            window.location.href = process.env.REACT_APP_SERVER_ROOT + '/login/federated/google';
          }}
        >
          <FontAwesomeIcon icon={faGoogle} />
          <span className="ml-3"> Sign in with google </span>
        </Button>
      </div>
    </div>
  );
};

export default LogIn;
