import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Avatar from 'react-avatar';
import { Card } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import AdminService from '../../services/admin.service';
import { hideLoading, showLoading } from '../../lib/uiService';
import useErrorHandling from '../../hooks/useErrorHandling';
import useDebounce from '../../hooks/useDebounce';
import CustomTable from '../../components/common/Table';
import Pagination from '../../components/common/Pagination';
import CustomSelect from '../../components/common/CustomSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPenToSquare, faPlus, faUsers } from '@fortawesome/free-solid-svg-icons';

const UserList: React.FC = () => {
  const [users, setUserList] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const pageOptions = [
    { label: 'All', value: Number.MAX_SAFE_INTEGER },
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];
  const [rowPerPage, setRowPerPage] = useState(pageOptions[0].value);
  const navigate = useNavigate();
  const location = useLocation();
  const { setError } = useErrorHandling();

  const hadlePageToShow = (value: any) => {
    setRowPerPage(Number(value));
  };

  const onCreateUser = () => {
    navigate(`/admin/user/create`);
  };

  const onEditUser = (userId: string) => {
    navigate(`/admin/user/${userId}`);
  };

  const onDeleteUser = async (userId: string) => {
    try {
      await AdminService.DeleteUser(userId);
      fetchList(page, search, rowPerPage);
    } catch (error) {
      console.error(error);
    }
  };

  const columns: any = useMemo(() => {
    return [
      {
        id: 'logo',
        Cell: ({
          data,
          row,
        }: {
          data: Array<{ firstName: string; lastName: string; logoUrl: string | null }>;
          row: { id: number };
        }) => {
          const fullName = `${data[row.id].firstName} ${data[row.id].lastName}`;
          const { logoUrl } = data[row.id];
          return (
            <td>
              {logoUrl ? (
                <img
                  src={logoUrl}
                  className="avatar img-fluid rounded-circle my-1"
                  alt={fullName}
                  width={30}
                  height={30}
                />
              ) : (
                <Avatar name={fullName} round={true} size="40" />
              )}
            </td>
          );
        },
      },
      {
        Header: 'Name',
        id: 'fullName',
        accessor: 'fullName',
        canSort: true,
        Cell: ({
          data,
          row,
        }: {
          data: Array<{ firstName: string; lastName: string; userId: string }>;
          row: { id: number };
        }) => {
          const fullName = `${data[row.id].firstName} ${data[row.id].lastName}`;
          const { userId } = data[row.id];
          return (
            <td onClick={() => onEditUser(userId)} style={{ cursor: 'pointer' }}>
              <span className="text-decoration-underline" style={{ cursor: 'pointer' }}>
                {fullName}
              </span>
            </td>
          );
        },
      },
      {
        Header: 'E-mail Address',
        id: 'email',
        canSort: false,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const { email } = data[row.id];
          return (
            <td>
              <span>{email}</span>
            </td>
          );
        },
      },
      {
        Header: 'Role',
        id: 'role',
        accessor: 'role',
        canSort: true,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const { roles } = data[row.id];
          return (
            <td>
              <span>{roles.join(', ')}</span>
            </td>
          );
        },
      },
      {
        Header: 'Territory',
        id: 'territory',
        canSort: false,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const territories = data[row.id].territories?.map((region: any) => region.shortName).join(',');
          return (
            <td>
              <span>{territories}</span>
            </td>
          );
        },
      },
      {
        Header: 'Active',
        id: 'active',
        accessor: 'active',
        canSort: true,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const { isActive } = data[row.id];
          return (
            <td className="align-items-center ">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" readOnly checked={isActive} />
              </div>
            </td>
          );
        },
      },
      {
        Header: '',
        id: 'action',
        canSort: false,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const { userId } = data[row.id];
          return (
            <td>
              <button className="btn btn-primary btn-sm me-1" type="button" onClick={() => onEditUser(userId)}>
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
              <button className="btn btn-danger btn-sm" type="button" onClick={() => onDeleteUser(userId)}>
                <FontAwesomeIcon icon={faClose} />
              </button>
            </td>
          );
        },
      },
    ];
  }, []);

  useEffect(() => {
    fetchList(1, '', rowPerPage);
  }, [location, setUserList]);

  useEffect(() => {
    fetchList(page, search, rowPerPage);
  }, [page, rowPerPage]);

  const fetchList = (page: number, searchString?: string, pageSize?: any) => {
    showLoading();

    AdminService.GetUsers({
      page,
      pageSize,
      search: searchString,
    })
      .then(({ data }) => {
        setUserList(data.users);
        setTotalCount(data.totalCount);
        setPageCount(Math.ceil(data.totalCount / rowPerPage));
        hideLoading();
      })
      .catch((error) => {
        console.log(error);
        hideLoading();
        setError(error.status);
      });
  };

  const debouncedFetchList = useDebounce(fetchList, 500);

  const handleSearch = useCallback(
    (value: string) => {
      setSearch(value);

      if (pageCount < 2) {
        return;
      }

      if (value.length === 0 || value.length > 2) {
        debouncedFetchList(page, value, rowPerPage);
      }
    },
    [pageCount, search],
  );

  return (
    <>
      <h3 className="text-dark mb-4">Users</h3>
      <Card title="test">
        <div className="p-3">
          <h5 className="text-primary fw-bold m-0 pb-1 pt-1 d-flex align-items-center ">
            <FontAwesomeIcon size="2x" icon={faUsers} />
            <span className="mx-2">Current Users</span>
          </h5>
        </div>
        <div className="row p-3">
          <div className="col-md-6 text-nowrap d-flex">
            <div className="mb-2">
              <label className="col-form-label" htmlFor="showperpage">
                Show:&nbsp;
              </label>
              <CustomSelect
                defaultValue={'All'}
                options={pageOptions}
                onSelectOption={hadlePageToShow}
                setPage={setPage}
              />
            </div>
            <div className="mb-2 mx-5">
              <button
                className="btn btn-primary float-end d-flex align-items-center"
                type="button"
                onClick={onCreateUser}
              >
                <FontAwesomeIcon className="text-white" icon={faPlus} />
                &nbsp;Create New User
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="text-md-end dataTables_filter">
              <label className="form-label">
                <input
                  type="search"
                  className="form-control form-control-sm"
                  aria-controls="dataTable"
                  placeholder="Search"
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="p-3">
          <CustomTable columns={columns} data={users} search={search} />
        </div>
        {users.length > 0 && (
          <div className="row px-3">
            <div className="col-md-6">
              <p id="dataTable_info-3" className="dataTables_info" role="status" aria-live="polite">
                Showing {users.length} of {totalCount}
              </p>
            </div>
            <div className="col-md-6">
              <Pagination total={pageCount} currentPage={page} setPage={setPage} />
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

export default UserList;
