import React, { useState, useEffect } from 'react';
import { faList } from '@fortawesome/free-solid-svg-icons';
import ForecastService from '../../services/forecast.service';
import { useForecast } from '../../providers/ForecastProvider';
import { Modal, Button, Form, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IForecast } from '../../lib/types';
import TerritorySelect from '../navbar/TerritorySelect';
import { PERIOD_TYPES } from '../../constants';
import CheckModalSelect from './CheckModalSelect';

const ForecastCheckModal = ({ open, setSOpen }: { open: boolean; setSOpen: (val: boolean) => void }) => {
  const [forecasts, setForecasts] = useState<IForecast[]>([]);
  const {
    // forecastId,
    showPeriods,
    setShowPeriods,
    setForecastCalendarId,
    setForecastId,
    setTerritoryId,
    setForecastUpdated,
  } = useForecast();
  const [timePeriodOptions, setTimePeriodOptions] = useState([]);
  const [savedValues, setSavedValues] = useState({
    forecastId: '',
    territoryId: '',
    forecastCalendarId: '',
  });
  const currentForecast = forecasts?.find((item) => item.forecastId === savedValues.forecastId);

  useEffect(() => {
    ForecastService.GetForecasts({ page: 1, pageSize: 100, search: '' })
      .then(({ data }) => {
        setForecasts(data.forecasts);
        setForecastUpdated(false);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    //let quarterIndex = 1;
    let yearIndex = 1;
    if (currentForecast) {
      // const startDate = new Date(currentForecast.startDate);
      // const endDate = new Date(currentForecast.endDate);

      const quarterOptions: any = [];
      const monthOptions: any = [];
      const yearOptions: any = [];
      const fullOptions: any = [];
      const forecastCalendars = currentForecast.forecastCalendars.sort(
        (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
      );
      forecastCalendars.map((cal) => {
        const startDate = new Date(cal.startDate);
        const startYear = startDate.getUTCFullYear();
        // const endDate = new Date(cal.endDate);
        // const endYear = endDate.getUTCFullYear();
        // const startMonthIndex = startDate.getMonth(); // Zero-based index
        // const startMonthName = MONTHS[startMonthIndex];
        // const endMonthIndex = endDate.getMonth();
        // const endMonthName = MONTHS[endMonthIndex];

        // ALLOW ONLY YEARLY TYPE
        switch (cal.periodType) {
          /*          case periodTypes.QUARTER:
                     quarterOptions.push({
                       name: `Q${quarterIndex}: ${startYear}`,
                       forecastCalendarId: cal.forecastCalendarId,
                     });
                     quarterIndex++;
                     break;
                   case periodTypes.MONTH:
                     monthOptions.push({
                       name: `${startMonthName} ${startYear}`,
                       forecastCalendarId: cal.forecastCalendarId,
                     });
                     break; */
          case PERIOD_TYPES.YEAR:
            yearOptions.push({
              name: `Year ${yearIndex}: ${startYear}`,
              year: startYear,
              forecastCalendarId: cal.forecastCalendarId,
            });
            yearIndex++;
            break;
          /*           case periodTypes.FULL:
                      fullOptions.push({
                        name: `${startMonthName} ${startYear} - ${endMonthName} ${endYear}`,
                        forecastCalendarId: cal.forecastCalendarId,
                      });
                      break; */

          default:
            break;
        }
      });
      const orderedOptions: any = [...quarterOptions, ...monthOptions, ...yearOptions, ...fullOptions];
      setTimePeriodOptions(orderedOptions);
      if (orderedOptions.length === 1) {
        setShowPeriods(false);
        setForecastCalendarId(orderedOptions[0].forecastCalendarId);
        setSavedValues({ ...savedValues, forecastCalendarId: orderedOptions[0].forecastCalendarId });
        localStorage.setItem('TimePeriod', orderedOptions[0].forecastCalendarId);
        localStorage.setItem('previousTimePeriod', orderedOptions[0].forecastCalendarId);
        localStorage.setItem('previousCurrentYear', orderedOptions[0].year);
      } else {
        setShowPeriods(true);
      }
    }
  }, [currentForecast, savedValues.forecastId, forecasts]);

  return (
    <>
      <Modal show={open} onHide={() => setSOpen(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon className="mx-2 text-primary" icon={faList} />
            Set Forecast and Territory
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="justify-content-center mb-3">
              <Col xs={4} className="text-end">
                <Form.Label>
                  <strong>Select Forecast</strong>
                </Form.Label>
              </Col>
              <Col xs={6}>
                <Form>
                  <CheckModalSelect
                    items={forecasts}
                    scope="Forecast"
                    placeholder="Select Forecast"
                    setSavedValues={setSavedValues}
                    savedValues={savedValues}
                  />
                </Form>
              </Col>
            </Row>
            <Row className="justify-content-center mb-3">
              <Col xs={4} className="text-end">
                <Form.Label>
                  <strong>Select Region</strong>
                </Form.Label>
              </Col>
              <Col xs={6}>
                <Form>
                  <TerritorySelect placeholder="Select District/Region" setSavedValues={setSavedValues} isModal />
                </Form>
              </Col>
            </Row>
            <Row className="justify-content-center mb-3">
              <Col xs={4} className="text-end">
                <Form.Label>
                  <strong>Select Time Period</strong>
                </Form.Label>
              </Col>
              <Col xs={6}>
                <Form>
                  <CheckModalSelect
                    items={timePeriodOptions}
                    scope="TimePeriod"
                    placeholder="Select Time Period"
                    setSavedValues={setSavedValues}
                    disabled={!showPeriods}
                    savedValues={savedValues}
                  />
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              if (savedValues.forecastId && savedValues.territoryId && savedValues.forecastCalendarId) {
                setForecastId(savedValues.forecastId);
                setTerritoryId(savedValues.territoryId);
                setForecastCalendarId(savedValues.forecastCalendarId);
                localStorage.setItem('previousForecast', savedValues.forecastId);
                localStorage.setItem('previousTerritory', savedValues.territoryId);
                localStorage.setItem('previousTimePeriod', savedValues.forecastCalendarId);
                setForecastUpdated(true);
                setSOpen(false);
              }
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ForecastCheckModal;
