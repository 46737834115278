import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { Formik, Form, ErrorMessage, useFormikContext } from 'formik';
import Avatar from 'react-avatar';
import { object, string, array, number } from 'yup';
import { Card, InputGroup } from 'react-bootstrap';
import { hideLoading, showLoading } from '../../lib/uiService';
import { IRole, ITerritoryOption, IUserData } from '../../lib/types';
import ForecastService from '../../services/forecast.service';
import AdminService from '../../services/admin.service';
import useErrorHandling from '../../hooks/useErrorHandling';
import { CheckTreePicker } from 'rsuite';
import { SelectPicker } from 'rsuite';
import { buildTreeOptionsFromTerritories } from '../../utils/buildTreeOption';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { toast } from 'react-toastify';

const UserForm: React.FC<{ userId: any; userData: IUserData; roles: any; users: any; territories: any }> = ({
  userId,
  userData,
  roles,
  users,
  territories,
}) => {
  const { setValues, values } = useFormikContext<IUserData>();

  const [, setActive] = useState(userData.isActive);

  useEffect(() => {
    setValues(userData);
  }, [userData]);

  const onSelectRole = (role: any) => {
    setValues({
      ...values,
      roles: role,
      roleIds: role,
    });
  };

  const onSelectManager = (managerId: any) => {
    setValues({
      ...values,
      manager: [users.find((item) => item.value === managerId)],
      managerId: managerId,
    });
  };

  const onSelectTerritory = (territory: any) => {
    setValues({
      ...values,
      territories: territory,
    });
  };

  const handleSwitch = (e: any) => {
    setActive(e.target.checked);
    setValues({
      ...values,
      isActive: e.target.checked,
    });
  };

  const fullName = useMemo(() => {
    return `${values.firstName} ${values.lastName || ''}`;
  }, [values.firstName, values.lastName]);

  return (
    <Form>
      <h3 className="text-dark mb-4">Profile</h3>
      <div className={userId === 'create' ? 'w-75 m-auto ' : 'row mb-3'}>
        {userId !== 'create' && (
          <div className="col-lg-4">
            <Card>
              <div className="text-center shadow my-3">
                <div className="m-auto">
                  {values.logoUrl ? (
                    <img
                      width={100}
                      height={100}
                      src={values.logoUrl}
                      className="img-fluid rounded-circle me-1"
                      alt="logo"
                    />
                  ) : (
                    <div className="d-flex justify-content-center ">
                      <Avatar name={fullName} round={true} />
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </div>
        )}
        <div className="col">
          <Card>
            <p className="text-primary px-2 mx-3 pt-3 fw-bold">User Settings</p>
            <div className="row mx-2 px-1">
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="firstName" className="col-form-label">
                    First Name
                  </label>
                  <div>
                    <input
                      id="firstName"
                      name="firstName"
                      value={values.firstName}
                      type="text"
                      className="w-100 p-1 form-control form-control-lg"
                      onChange={(e: any) => setValues({ ...values, firstName: e.target.value })}
                    />
                    <div style={{ fontSize: '10px', color: 'red' }}>
                      <ErrorMessage name="firstName" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="lastName" className="col-form-label">
                    Last Name
                  </label>
                  <div>
                    <input
                      id="lastName"
                      name="lastName"
                      value={values.lastName}
                      type="text"
                      className="w-100 p-1 form-control form-control-lg"
                      onChange={(e: any) => setValues({ ...values, lastName: e.target.value })}
                    />
                    <div style={{ fontSize: '10px', color: 'red' }}>
                      <ErrorMessage name="lastName" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-2 px-1">
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="email" className="col-form-label">
                    Email Address
                  </label>
                  <div>
                    <input
                      id="email"
                      name="email"
                      value={values.email}
                      type="text"
                      className="w-100 p-1 form-control form-control-lg"
                      onChange={(e: any) => setValues({ ...values, email: e.target.value })}
                    />
                    <div style={{ fontSize: '10px', color: 'red' }}>
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="mb-3"></div>
              </div>
              <hr />
            </div>
            <div>
              <p className="text-primary px-2 mx-3 pt-3 fw-bold">User Roles</p>
              <div className="mb-2 mx-2 px-1">
                <label className="col-form-label mx-2 px-1" htmlFor="showperpage">
                  {userId === 'create' ? 'Assign new Role' : 'Currently assigned Roles'}
                </label>
                <div className="px-2 m-1">
                  <Form className="col">
                    <InputGroup className="input-group flex-nowrap">
                      <CheckTreePicker
                        defaultExpandAll
                        value={values.roleIds}
                        data={roles}
                        searchable={false}
                        onChange={onSelectRole}
                        placeholder="Select Role"
                        className="w-100"
                        menuStyle={{ zIndex: 5000, height: '240px' }}
                      />
                    </InputGroup>
                  </Form>
                  <div style={{ fontSize: '10px', color: 'red' }}>
                    <ErrorMessage name="roleId" />
                  </div>
                </div>
                <hr />
              </div>
            </div>
            <div>
              <p className="text-primary px-2 mx-3 pt-3 fw-bold">Manager</p>
              <div className="mb-2 mx-2 px-1">
                <label className="col-form-label mx-2 px-1" htmlFor="showperpage">
                  {!values.manager ? 'Assign new Manager' : 'Currently assigned Manager'}
                </label>
                <div className="px-2 m-1">
                  <SelectPicker
                    data={users}
                    onChange={onSelectManager}
                    searchable={true}
                    value={values.managerId}
                    placeholder="Select Manager"
                    className="w-100"
                    menuStyle={{ zIndex: 5000, height: '240px' }}
                  />
                  <div style={{ fontSize: '10px', color: 'red' }}>
                    <ErrorMessage name="managerId" />
                  </div>
                </div>
                <hr />
              </div>
            </div>
            <div>
              <p className="text-primary px-2 mx-3 pt-3 fw-bold">Assigned Territories</p>
              <div className="mb-2 mx-2 px-1">
                <label className="col-form-label mx-2 px-1" htmlFor="showperpage">
                  Select Territories
                </label>
                <div className="px-2 m-1">
                  <Form className="col">
                    <InputGroup className="input-group flex-nowrap">
                      <CheckTreePicker
                        defaultExpandAll
                        value={values.territories}
                        data={territories}
                        searchable={false}
                        onChange={onSelectTerritory}
                        placeholder="Select District/Region"
                        className="w-100"
                        menuStyle={{ zIndex: 5000, height: '240px' }}
                      />
                    </InputGroup>
                  </Form>
                  <div style={{ fontSize: '10px', color: 'red' }}>
                    <ErrorMessage name="territories" />
                  </div>
                </div>
                <hr />
                {userId !== 'create' && (
                  <div className="form-check form-switch m-3">
                    <input
                      className="form-check-input mb-2"
                      type="checkbox"
                      style={{ width: 40, height: 20 }}
                      role="switch"
                      id="flexSwitchCheckDefault"
                      checked={values.isActive}
                      onChange={(e) => handleSwitch(e)}
                    />
                    <label className="form-check-label px-2 pt-1" htmlFor="flexSwitchCheckDefault">
                      Active
                    </label>
                  </div>
                )}
              </div>
              <div className="my-4 d-flex justify-content-center">
                <button className="btn btn-primary btn-md" type="submit">
                  {userId === 'create' ? 'Create User' : 'Save Settings'}
                </button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Form>
  );
};

const User: React.FC = () => {
  const [userData, setData] = useState<IUserData>({
    firstName: '',
    lastName: '',
    email: '',
    roleIds: [],
    managerId: null,
    isActive: false,
    territories: [],
  });
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [territories, setTerritories] = useState<ITerritoryOption[]>([]);
  const { setError } = useErrorHandling();

  const { userId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (userId !== 'create') {
      getUser(userId);
    }
  }, [userId]);

  useEffect(() => {
    showLoading();
    AdminService.GetUsers({ page: 1, pageSize: Number.MAX_SAFE_INTEGER })
      .then(({ data }) => {
        const managers = data.users
          .filter((user) => user.isActive && user.userId !== userId)
          .map((user: IUserData) =>
            Object.assign({ label: `${user.firstName} ${user?.lastName ?? ''}`, value: user.userId }),
          );
        setUsers(managers);
      })
      .catch((error) => {
        console.log(error);
        setError(error.status);
      });
    ForecastService.GetTerritoriesHierarchy()
      .then(({ data }) => {
        const value = buildTreeOptionsFromTerritories(data);
        setTerritories(value);
      })
      .catch((error) => console.log(error));
    AdminService.GetRoles()
      .then(({ data }) => {
        const roles = data.map((role: IRole) => Object.assign({ label: role.name, value: role.roleId }));
        setRoles(roles);
        hideLoading();
      })
      .catch((error) => console.log(error));
    hideLoading();
  }, []);

  const getUser = (userId: any) => {
    showLoading();
    AdminService.GetUser(userId)
      .then(({ data }) => {
        const user = Object.assign(data, {
          ...(data?.managerId && {
            manager: [data?.managerId],
          }),
          roleIds: data.roles.map((item) => item.role?.roleId),
          roles: data.roles.map((item) => {
            return { label: item.role?.name, value: item.role?.roleId };
          }),
          territories: data.territories.map((region: any) => region.territoryId),
        });
        setData(user);
        hideLoading();
      })
      .catch((error) => {
        console.log(error);
        hideLoading();
        setError(error.status);
      });
  };

  const validation = object().shape({
    firstName: string().required('Required'),
    lastName: string().required('Required'),
    email: string().email('Invalid email').required('Required'),
    roleIds: array().min(1, 'You must add at least one role').required('Required'),
    territories: array().min(1, 'You must add at least one region').required('Required'),
  });

  const onSubmit = async (values: any) => {
    try {
      const user = {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        email: values.email.trim(),
        isActive: values.isActive,
        roleIds: values.roleIds,
        territoryIds: values.territories,
        managerId: values.managerId,
      };

      if (userId === 'create') {
        await AdminService.CreatUser(user);
        navigate(`/admin/user`);
      } else {
        await AdminService.EditUser(userId, user);
        navigate(`/admin/user`);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.error);
    }
  };

  return (
    <Formik initialValues={userData} validationSchema={validation} onSubmit={onSubmit}>
      <UserForm userData={userData} userId={userId} roles={roles} users={users} territories={territories} />
    </Formik>
  );
};

export default User;
