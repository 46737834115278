import React, { useEffect, useState } from 'react';
import NewForecast, { IForecastForm } from './NewForecast';
import CurrentForecasts from './CurrentForecasts';
import { ForecastStatusType, IForecastCalendars } from '../../lib/types';
import { Button, Modal } from 'react-bootstrap';
import { hideLoading, showLoading } from '../../lib/uiService';
import FeatureService from '../../services/feature.service';
import useErrorHandling from '../../hooks/useErrorHandling';

export type IPageToShow = 'Create' | 'Update';
export interface IForecastToUpdate extends IForecastForm {
  forecastId: string;
  status: ForecastStatusType;
  forecastPeriod: string;
  lookbackPeriod: string;
  lookbackEndDate: string | Date;
  lookbackStartDate: string | Date;
  weights: any;
  forecastCalendars: IForecastCalendars[];
}

const AdminForecast: React.FC = () => {
  const [isOpen, setOpenModal] = useState<boolean>(false);
  const [fetchAgain, setFetchAgain] = useState<boolean>(true);
  const [pageToShow, setPageToShow] = useState<IPageToShow>('Create');
  const currentDate = new Date();
  const lookbackStartDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 0);
  const lookbackEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
  const [forecastToUpdate, setForecastToUpdate] = useState<IForecastToUpdate>({
    forecastId: '',
    name: '',
    methodAndType: 'Linear Weighted',
    startDate: `${new Date().getFullYear() + 1}-01-01`,
    endDate: `${new Date().getFullYear() + 1}-12-31`,
    lookbackPeriod: '1',
    lookbackEndDate: lookbackEndDate.toISOString().split('T')[0],
    lookbackStartDate: lookbackStartDate.toISOString().split('T')[0],
    forecastPeriod: '1',
    calculationPeriod: 'Quarterly',
    periods: {},
    status: ForecastStatusType.Pending,
    weights: [],
    forecastCalendars: [],
  });
  const [asyncForecast, setAsyncForecast] = useState(false);
  const { setError } = useErrorHandling();

  useEffect(() => {
    showLoading();
    FeatureService.GetValue('asyncForecast')
      .then(({ data }) => {
        const { value } = data;
        setAsyncForecast(value === 'true');
      })
      .catch((error: any) => {
        setError(error.response);
      });
    hideLoading();
  }, []);

  return (
    <>
      <h3 className="text-dark mb-4" style={{ fontSize: '1.75rem' }}>
        Forecast
      </h3>
      <NewForecast
        asyncForecast={asyncForecast}
        pageToShow={pageToShow}
        forecastToUpdate={forecastToUpdate}
        setPageToShow={setPageToShow}
        setFetchAgain={setFetchAgain}
        setOpenModal={setOpenModal}
        setForecastToUpdate={setForecastToUpdate}
      />
      <CurrentForecasts
        setPageToShow={setPageToShow}
        setForecastToUpdate={setForecastToUpdate}
        fetchAgain={fetchAgain}
        setFetchAgain={setFetchAgain}
      />
      <Modal show={isOpen} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center m-3">
          <p className="mb-0 text-text-black">
            Your forecast has been queued for creation. You will receive an e-mail when it is complete
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setOpenModal(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminForecast;
