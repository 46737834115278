import React, { useEffect, useMemo, useState } from 'react';
import { useTable, useSortBy, useGlobalFilter, useExpanded } from 'react-table';
import { Table } from 'react-bootstrap';

import { hideLoading, showLoading } from '../../lib/uiService';
import { useForecast } from '../../providers/ForecastProvider';
import ForecastService from '../../services/forecast.service';
import { formatNum } from '../../utils/formatNum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faChevronDown, faChevronRight, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import DownloadSelector from '../../components/DownloadSelector';
import ForecastCheckModal from '../../components/ForecastCheckModal';
import useForecastModal from '../../hooks/useForecastModal';
import useErrorHandling from '../../hooks/useErrorHandling';
import BasicRadioToggleButtonGroup from '../../components/common/BasicRadioToggleButtonGroup';
import { ReferenceType, TerritoryType } from '../../constants';
import { useNavigate, useLocation } from 'react-router-dom';
import Pagination from '../../components/Pagination';
interface IChildRegionForecast {
  name: string;
  priorYearSales: number;
  quantity: number;
  priorYearRevenue: number;
  revenue: number;
  caseGrowth: number;
  revenueGrowth: number;
}

interface IRegionForecast extends IChildRegionForecast {
  children: Array<IChildRegionForecast>;
}

const RegionalForecast: React.FC = () => {
  const { forecastId, hasForecasts, forecastCalendarId, currentForecastYear } = useForecast();
  const { setError } = useErrorHandling();
  const [page, setPage] = useState<number>(1);
  const [currentStartPage, setCurrentStartPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [startPage, setStartPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [regionForecasts, setRegionForecasts] = useState<IRegionForecast[]>([]);
  const [initialData, setInitialData] = useState<IRegionForecast[]>([]);
  const [count, setCount] = useState<number>(0);
  const { showForecastModal, setShowForecastModal } = useForecastModal();
  const [fullData, setFullData] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Initialize territoryType and referenceId with default values from URL query parameters
  const [territoryType, setTerritoryType] = useState(searchParams.get('territoryType') || TerritoryType.Division);
  const [referenceType, setReferenceType] = useState(searchParams.get('referenceType') || ReferenceType.Product);

  useEffect(() => {
    const handlePopstate = () => {
      const territoryType = new URLSearchParams(window.location.search).get('territoryType') || TerritoryType.Division;
      const referenceType = new URLSearchParams(window.location.search).get('referenceType') || ReferenceType.Product;

      setTerritoryType(territoryType);
      setReferenceType(referenceType);
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  useEffect(() => {
    if (forecastId) {
      fetchRegionalForecasts();
      fetchAllData();
    }
  }, [page, pageSize, territoryType, referenceType, forecastId, search, forecastCalendarId]);

  useEffect(() => {
    setStartPage(1);
  }, [pageSize]);

  useEffect(() => {
    setPage(startPage);
  }, [startPage]);

  const initialTotal = {
    name: '',
    priorYearSales: 0,
    quantity: 0,
    caseGrowth: 0.0,
    priorYearRevenue: 0,
    revenue: 0,
    revenueGrowth: 0.0,
  };
  const [grandTotal, setGrandTotal] = useState(initialTotal);

  const fetchRegionalForecasts = async () => {
    showLoading();
    try {
      // make sure forecastCalendarId exist before loading
      const localStorageCalendarId = localStorage.getItem('TimePeriod') || '';
      const calendarId = forecastCalendarId ? forecastCalendarId : localStorageCalendarId;
      if (calendarId) {
        const { data } = await ForecastService.GetRegionalForecast({
          page,
          pageSize,
          forecastId,
          search,
          territoryType,
          referenceType,
          forecastCalendarId: calendarId,
        });
        const { forecasts, count } = data;
        const totalRow = initialTotal;
        const renderingData: Array<IRegionForecast> = Object.keys(forecasts || {}).map((name) => {
          const children = forecasts[name];

          const { priorYearSales, quantity, priorYearRevenue, revenue } = children.reduce(
            (
              total: { priorYearSales: number; quantity: number; priorYearRevenue: number; revenue: number },
              forecast: { priorYearSales: number; quantity: number; priorYearRevenue: number; revenue: number },
            ) => {
              total.priorYearSales += Number(forecast.priorYearSales);
              total.quantity += Number(forecast.quantity);
              total.priorYearRevenue += Number(forecast.priorYearRevenue);
              total.revenue += Number(forecast.revenue);
              return total;
            },
            { priorYearSales: 0, quantity: 0, priorYearRevenue: 0, revenue: 0 },
          );
          totalRow.priorYearSales += priorYearSales;
          totalRow.quantity += quantity;
          totalRow.priorYearRevenue += priorYearRevenue;
          totalRow.revenue += revenue;
          return {
            name,
            priorYearSales: priorYearSales,
            quantity: quantity,
            priorYearRevenue: priorYearRevenue,
            revenue: revenue,
            caseGrowth: priorYearSales ? (quantity / priorYearSales - 1) * 100 : 0,
            revenueGrowth: priorYearRevenue ? (revenue / priorYearRevenue - 1) * 100 : 0,
            children,
          };
        });
        totalRow.caseGrowth = totalRow.priorYearSales ? (totalRow.quantity / totalRow.priorYearSales - 1) * 100 : 0;
        totalRow.revenueGrowth = totalRow.priorYearRevenue
          ? (totalRow.revenue / totalRow.priorYearRevenue - 1) * 100
          : 0;
        setGrandTotal(totalRow);
        setRegionForecasts(renderingData);
        setInitialData(renderingData);
        setCount(count);
        setTotalPages(Math.ceil(count / pageSize));
      }

      hideLoading();
    } catch (error: any) {
      console.log(error);
      setError({ status: error.response.status || 500 });
      hideLoading();
    }
  };

  const fetchAllData = async () => {
    const localStorageCalendarId = localStorage.getItem('TimePeriod') || '';
    const calendarId = forecastCalendarId ? forecastCalendarId : localStorageCalendarId;
    if (calendarId) {
      const { data } = await ForecastService.GetRegionalForecast({
        page: 0,
        pageSize: 0,
        forecastId,
        search: '',
        territoryType,
        referenceType,
        forecastCalendarId: calendarId,
      });
      setFullData(data);
    }
  };

  // values set to any (to not cause errors) because libary's types are not compatible
  const columns: any = useMemo(() => {
    return [
      {
        id: 'name',
        accessor: 'name',
        Header: 'Name',
        canSort: true,
        Cell: ({ row, value }: { row: any; value: any }) => {
          return (
            <div className="d-flex align-items-center">
              <span
                {...row.getToggleRowExpandedProps({
                  className: `ps-${row.depth * 2}`,
                })}
              >
                {row.isExpanded ? (
                  <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
                ) : (
                  <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
                )}
              </span>
              <p className="ms-2 m-0">{value}</p>
            </div>
          );
        },
        width: 1,
      },
      {
        id: 'priorYearSales',
        Header: `${Number(currentForecastYear) - 1} Shipments`,
        accessor: 'priorYearSales',
        width: 1,
        canSort: true,
        Cell: ({ value }: { value: number }) => formatNum(value),
      },
      {
        id: 'quantity',
        Header: `Current Forecast`,
        accessor: 'quantity',
        width: 1,
        canSort: true,
        Cell: ({ value }: { value: number }) => formatNum(value),
      },
      {
        id: 'caseGrowth',
        Header: `Case Growth`,
        accesor: 'caseGrowth',
        width: 1,
        canSort: true,
        sortType: (rowA: any, rowB: any) => {
          const valueA = rowA.original.caseGrowth;
          const valueB = rowB.original.caseGrowth;
          return valueB - valueA;
        },
        Cell: ({ data, row }: { data: any; row: any; value: any }) => {
          return `${formatNum(data[row.id].caseGrowth)}%`;
        },
      },
      {
        id: 'priorYearRevenue',
        Header: `Current Year Projected Rev`,
        accesor: 'priorYearRevenue',
        width: 1,
        canSort: true,
        sortType: (rowA: any, rowB: any) => {
          const valueA = rowA.original.priorYearRevenue;
          const valueB = rowB.original.priorYearRevenue;
          return valueB - valueA;
        },
        Cell: ({ data, row }: { data: any; row: any; value: any }) => {
          return `$${formatNum(data[row.id].priorYearRevenue)}`;
        },
      },
      {
        id: 'revenue',
        Header: `Forecasted Revenue`,
        accessor: 'revenue',
        width: 1,
        canSort: true,
        Cell: ({ value }: { value: number }) => `$${formatNum(value)}`,
      },
      {
        id: 'revenueGrowth',
        Header: `Revenue Growth`,
        accessor: 'revenueGrowth',
        width: 1,
        canSort: true,
        Cell: ({ value }: { value: number }) => `${formatNum(value)}%`,
      },
    ];
  }, [regionForecasts]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: columns,
      data: useMemo(() => regionForecasts, [regionForecasts]),
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
  );

  const downloadColumnHeaders = [
    { v: 'Name', t: 's', s: { font: { bold: true, underline: true } } },
    { v: `${Number(currentForecastYear) - 1} Shipments`, t: 's', s: { font: { bold: true, underline: true } } },
    { v: 'Current Forecast', t: 's', s: { font: { bold: true, underline: true } } },
    { v: 'Case Growth', t: 's', s: { font: { bold: true, underline: true } } },
    { v: 'Prior Year Revenue', t: 's', s: { font: { bold: true, underline: true } } },
    { v: 'Forecasted Revenue', t: 's', s: { font: { bold: true, underline: true } } },
    { v: 'Revenue Growth', t: 's', s: { font: { bold: true, underline: true } } },
  ];

  const row: any = [];
  const selectedHeader = headerGroups[0].headers.find(({ isSorted }) => isSorted);

  return hasForecasts ? (
    <>
      <ForecastCheckModal open={showForecastModal} setSOpen={setShowForecastModal} />
      <h3 className="text-dark mb-4">Regional Summary Forecast</h3>
      <div className="card shadow">
        <div className="card-header py-3">
          <p className="text-primary m-0 fw-bold">Choose Geography & Grouping</p>
        </div>
        <div className="card-body pt-3 pb-4">
          <div className="row flex-column gap-4">
            <BasicRadioToggleButtonGroup
              key="territoryType"
              groupName="territoryType"
              options={Object.keys(TerritoryType).map((territoryType) => ({
                label: territoryType,
                value: territoryType,
              }))}
              onSelectOption={(value) => {
                setPage(1);
                const url = new URL(window.location.href);
                url.searchParams.set('territoryType', value);
                navigate(url.pathname + url.search);
                setTerritoryType(value);
              }}
              selectedValue={territoryType}
            />

            <BasicRadioToggleButtonGroup
              key="referenceType"
              groupName="referenceType"
              options={Object.keys(ReferenceType).map((referenceType) => ({
                label: `By ${referenceType}`,
                value: ReferenceType[referenceType as keyof typeof ReferenceType],
              }))}
              onSelectOption={(value) => {
                setPage(1);
                const url = new URL(window.location.href);
                url.searchParams.set('referenceType', value);
                navigate(url.pathname + url.search);
                setReferenceType(value);
              }}
              selectedValue={referenceType}
            />
          </div>
        </div>
      </div>
      <div className="card shadow">
        <div className="card-header py-3">
          <p className="text-primary m-0 fw-bold">Regional Forecast Breakdown</p>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 text-nowrap">
              <div className="dataTables_length" aria-controls="dataTable">
                <label className="form-label">
                  Show&nbsp;
                  <select
                    className="d-inline-block form-select form-select-sm"
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                      setPage(1);
                    }}
                  >
                    <option value="10" defaultValue="10">
                      10
                    </option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  &nbsp;
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-end justify-content-md-end dataTables_filter">
                <label className="form-label d-flex justify-content-md-end">
                  <DownloadSelector
                    row={row}
                    fileName={`Regional Forecast by ${referenceType === ReferenceType.Brand ? 'Brand' : 'Product'}-${territoryType}`}
                    downloadColumnHeaders={downloadColumnHeaders}
                    prepareDataFunction={async () => {
                      if (!fullData) return;
                      const { forecasts } = fullData;

                      const totalRow = initialTotal;
                      const initialData = Object.keys(forecasts || {}).map((name) => {
                        const children: Array<IChildRegionForecast> = forecasts[name];
                        const { priorYearSales, quantity, priorYearRevenue, revenue } = children.reduce(
                          (
                            total: {
                              priorYearSales: number;
                              quantity: number;
                              priorYearRevenue: number;
                              revenue: number;
                            },
                            forecast: {
                              priorYearSales: number;
                              quantity: number;
                              priorYearRevenue: number;
                              revenue: number;
                            },
                          ) => {
                            total.priorYearSales += Number(forecast.priorYearSales);
                            total.quantity += Number(forecast.quantity);
                            total.priorYearRevenue += Number(forecast.priorYearRevenue);
                            total.revenue += Number(forecast.revenue);
                            return total;
                          },
                          { priorYearSales: 0, quantity: 0, priorYearRevenue: 0, revenue: 0 },
                        );
                        totalRow.priorYearSales += priorYearSales;
                        totalRow.quantity += quantity;
                        totalRow.priorYearRevenue += priorYearRevenue;
                        totalRow.revenue += revenue;
                        return {
                          name,
                          priorYearSales: priorYearSales,
                          quantity: quantity,
                          priorYearRevenue: priorYearRevenue,
                          revenue: revenue,
                          caseGrowth: priorYearSales ? quantity / priorYearSales - 1 : 0,
                          revenueGrowth: priorYearRevenue ? revenue / priorYearRevenue - 1 : 0,
                        };
                      });
                      totalRow.name = 'Grand Total';
                      totalRow.caseGrowth = totalRow.priorYearSales
                        ? totalRow.quantity / totalRow.priorYearSales - 1
                        : 0;
                      totalRow.revenueGrowth = totalRow.priorYearRevenue
                        ? totalRow.revenue / totalRow.priorYearRevenue - 1
                        : 0;

                      initialData.forEach((item: IChildRegionForecast) => {
                        const { name, priorYearSales, quantity, caseGrowth, priorYearRevenue, revenue, revenueGrowth } =
                          item;
                        row.push([
                          { v: name, t: 's' },
                          { v: priorYearSales, t: 'n', s: { numFmt: '#,##0.00' } },
                          { v: quantity, t: 'n', s: { numFmt: '#,##0.00' } },
                          { v: caseGrowth, t: 'n', s: { numFmt: '0.00%' } },
                          { v: priorYearRevenue, t: 'n', s: { numFmt: '$#,##0.00' } },
                          { v: revenue, t: 'n', s: { numFmt: '$#,##0.00' } },
                          { v: revenueGrowth, t: 'n', s: { numFmt: '#,##0.00%' } },
                        ]);
                      });
                      const { name, priorYearSales, quantity, caseGrowth, priorYearRevenue, revenue, revenueGrowth } =
                        totalRow;
                      row.push([
                        {
                          v: name,
                          t: 's',
                          s: {
                            font: { bold: true },
                            border: {
                              top: { style: 'thin' },
                            },
                          },
                        },
                        {
                          v: priorYearSales,
                          t: 'n',
                          s: { font: { bold: true }, border: { top: { style: 'thin' } }, numFmt: '#,##0.00' },
                        },
                        {
                          v: quantity,
                          t: 'n',
                          s: { font: { bold: true }, border: { top: { style: 'thin' } }, numFmt: '#,##0.00' },
                        },
                        {
                          v: caseGrowth,
                          t: 'n',
                          s: { font: { bold: true }, border: { top: { style: 'thin' } }, numFmt: '0.00%' },
                        },
                        {
                          v: priorYearRevenue,
                          t: 'n',
                          s: { font: { bold: true }, border: { top: { style: 'thin' } }, numFmt: '$#,##0.00' },
                        },
                        {
                          v: revenue,
                          t: 'n',
                          s: { font: { bold: true }, border: { top: { style: 'thin' } }, numFmt: '$#,##0.00' },
                        },
                        {
                          v: revenueGrowth,
                          t: 'n',
                          s: { font: { bold: true }, border: { top: { style: 'thin' } }, numFmt: '0.00%' },
                        },
                      ]);
                    }}
                  />
                  &nbsp;
                </label>
                <label className="form-label">
                  <input
                    type="search"
                    className="form-control form-control-sm"
                    aria-controls="dataTable"
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </label>
              </div>
            </div>
          </div>
          <Table striped className="table-sm" bordered {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index} className="table-header-gray">
                  {headerGroup.headers.map((column, index) => {
                    return (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        key={index}
                        colSpan={Number(column.width)}
                        className="table-head-font-size"
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                            ) : (
                              <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                            )
                          ) : (
                            <FontAwesomeIcon icon={faSort} className="ms-2" />
                          )}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row: any, i) => {
                prepareRow(row);
                const forecast = row.original;
                return (
                  <React.Fragment key={i}>
                    <tr {...row.getRowProps()} id="1" className="table-body-font-size">
                      {row.cells.map((cell: any, index: number) => {
                        return (
                          <td {...cell.getCellProps()} key={index} className={'text-dark align-middle'} colSpan={1}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded
                      ? forecast.children
                          .sort((a: IChildRegionForecast, b: IChildRegionForecast) => {
                            if (!selectedHeader) return 0;

                            const { id, isSortedDesc } = selectedHeader;
                            if (id === 'name') {
                              return !isSortedDesc ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name);
                            } else if (id === 'caseGrowth') {
                              const aValue = a.caseGrowth;
                              const bValue = b.caseGrowth;

                              return !isSortedDesc ? bValue - aValue : aValue - bValue;
                            } else if (id === 'priorYearRevenue') {
                              const aValue = a.priorYearRevenue;
                              const bValue = b.priorYearRevenue;

                              return !isSortedDesc ? bValue - aValue : aValue - bValue;
                            } else {
                              const aValue = a[id as keyof IChildRegionForecast] as number;
                              const bValue = b[id as keyof IChildRegionForecast] as number;

                              return !isSortedDesc ? bValue - aValue : aValue - bValue;
                            }
                          })
                          .map((child: any, index: number) => {
                            const {
                              name,
                              priorYearSales,
                              quantity,
                              caseGrowth,
                              priorYearRevenue,
                              revenue,
                              revenueGrowth,
                            } = child;
                            return (
                              <React.Fragment key={index}>
                                <tr className={`text-dark table-body-font-size`}>
                                  <td colSpan={1}>
                                    <div className="ms-3 d-flex align-items-center">
                                      <p className="ms-2 m-0">{name}</p>
                                    </div>
                                  </td>
                                  <td colSpan={1}>{formatNum(priorYearSales)}</td>
                                  <td colSpan={1}>{formatNum(quantity)}</td>
                                  <td colSpan={1}>{formatNum(caseGrowth)}%</td>
                                  <td colSpan={1}>${formatNum(priorYearRevenue)}</td>
                                  <td colSpan={1}>${formatNum(revenue)}</td>
                                  <td colSpan={1}>{formatNum(revenueGrowth)}%</td>
                                </tr>
                              </React.Fragment>
                            );
                          })
                      : null}
                  </React.Fragment>
                );
              })}
              <tr className="table-bottom-gray">
                <td>Grand Total</td>
                <td>{formatNum(grandTotal.priorYearSales)}</td>
                <td>{formatNum(grandTotal.quantity)}</td>
                <td>{formatNum(grandTotal.caseGrowth)}%</td>
                <td>${formatNum(grandTotal.priorYearRevenue)}</td>
                <td>${formatNum(grandTotal.revenue)}</td>
                <td>{formatNum(grandTotal.revenueGrowth)}%</td>
              </tr>
            </tbody>
          </Table>
          <div className="row">
            <div className="col-md-6 align-self-center">
              <p id="dataTable_info-3" className="dataTables_info" role="status" aria-live="polite">
                Showing {Math.min(page * pageSize, count)} of {count}
              </p>
            </div>
            <div className="col-md-6 mb-2">
              <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                <ul className="pagination">
                  <li className={'page-item cursor-pointer' + (page - 1 > 0 ? '' : ' disabled')}>
                    <div
                      className="page-link"
                      aria-label="Previous"
                      onClick={() => {
                        if (page > 1) {
                          const newPage = page - 1;
                          setPage(newPage);
                          if (page === currentStartPage) {
                            setCurrentStartPage(Math.max(currentStartPage - 1, 1));
                          }
                        }
                      }}
                    >
                      <span aria-hidden="true">«</span>
                    </div>
                  </li>
                  <Pagination
                    totalPages={totalPages}
                    startPage={startPage}
                    currentStartPage={currentStartPage}
                    setCurrentStartPage={setCurrentStartPage}
                    pageSize={pageSize}
                    count={count}
                    page={page}
                    setPage={setPage}
                    setData={setRegionForecasts}
                    initialData={initialData}
                  />
                  <li className={'page-item cursor-pointer' + (page + 1 <= totalPages ? '' : ' disabled')}>
                    <div
                      className="page-link"
                      aria-label="Next"
                      onClick={() => {
                        const newPage = page + 1;
                        setCurrentStartPage(currentStartPage + 1);
                        setPage(newPage);
                      }}
                    >
                      <span aria-hidden="true">»</span>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <h3 className="text-dark mb-4">No Forecasts In the System</h3>
  );
};

export default RegionalForecast;
