import { formatNegativeNumFromStr, formatNum } from './formatNum';
import { linearRegression, linearRegressionLine } from 'simple-statistics';

export const calculateForecastQuantity = (
  prevQuantity: number,
  prevSalesGrowth: number | string,
  salesGrowth: number | string,
) => {
  // can be a negative number inside a string
  const prevSalesGrowthNumber = formatNegativeNumFromStr(prevSalesGrowth);
  const salesGrowthNumber = formatNegativeNumFromStr(salesGrowth);
  return (Number(prevQuantity) / (100 + prevSalesGrowthNumber)) * (100 + Number(salesGrowthNumber));
};

export const calculateForecastRevenue = (
  prevRevenue: number,
  prevSalesGrowth: number,
  prevPriceGrowth: number | string,
  salesGrowth: number,
  priceGrowth: number | string,
) => {
  // can be a negative number inside a string
  const priceGrowthNumber = formatNegativeNumFromStr(priceGrowth);
  const prevPriceGrowthNumber = formatNegativeNumFromStr(prevPriceGrowth);
  const salesGrowthNumber = formatNegativeNumFromStr(salesGrowth);
  const prevSalesGrowthNumber = formatNegativeNumFromStr(prevSalesGrowth);

  return (
    (Number(prevRevenue) * (100 + salesGrowthNumber) * (100 + priceGrowthNumber)) /
    (100 + prevSalesGrowthNumber) /
    (100 + prevPriceGrowthNumber)
  );
};

export const calculateRevenueGrowth = (salesGrowth: number, priceGrowth: number | string) => {
  // can be a negative number inside a string
  const priceGrowthNumber = formatNegativeNumFromStr(priceGrowth);
  const salesGrowthNumber = formatNegativeNumFromStr(salesGrowth);

  const value = (salesGrowthNumber * priceGrowthNumber) / 100 + salesGrowthNumber + priceGrowthNumber;
  return isNaN(value) ? 0 : value;
};

export const calculateSalesGrowth = (newQuantity: number | string, oldQuantity: number | string) => {
  const newQuantityNumber = formatNegativeNumFromStr(newQuantity);
  const oldQuantityNumber = formatNegativeNumFromStr(oldQuantity);

  return (newQuantityNumber / oldQuantityNumber - 1) * 100;
};

export const calculateExecQuantity = (execQuantity: number, salesGrowth: number | string) => {
  // can be a negative number inside a string
  const salesGrowthNumber = formatNegativeNumFromStr(salesGrowth);

  return (execQuantity * salesGrowthNumber) / 100;
};

export const calculateExecRevenue = (execRevenue: number, priceGrowth: number | string) => {
  // can be a negative number inside a string
  const priceGrowthNumber = formatNegativeNumFromStr(priceGrowth);

  return (execRevenue * priceGrowthNumber) / 100;
};

export const sumForecastBudgetByTerritory = (items: any[], territoryId) => {
  const sum = items.reduce(
    (acc: any, item: any) => {
      if (item.territoryId === territoryId && item.budgetType === 'brand') {
        acc.quantity += Number(item.quantity);
        acc.revenue += Number(item.revenue);
      }
      return acc;
    },
    { quantity: 0, revenue: 0 },
  );
  return sum;
};

export const getParentTerritories = (forecastCalculator, territoryId: string) => {
  const result = [];
  let parent = forecastCalculator.territoryCollection.getParrentTerritory(territoryId);
  while (parent) {
    result.push(parent);
    parent = forecastCalculator.territoryCollection.getParrentTerritory(parent.territoryId);
  }
  return result.reverse();
};

export const findParentTerrioryByType = (forecastCalculator, territoryId: string, type: string) => {
  let parent = forecastCalculator.territoryCollection.getParrentTerritory(territoryId);
  while (parent) {
    if (parent.type === type) {
      return parent;
    }
    parent = forecastCalculator.territoryCollection.getParrentTerritory(parent.territoryId);
  }
};

export const checkNegativeAndDecimalBeforeCalulateRound = (value: string | number): string | number => {
  const result =
    typeof value === 'string'
      ? value === '-' || value.endsWith('.') || value.includes('0')
        ? value
        : parseFloat(value)
      : Math.floor(value * 100) / 100;
  return result;
};

export const darkenRgbaColor = (rgba: any, percent: number) => {
  const rgbaMatch = rgba.match(/rgba?\((\d+), (\d+), (\d+), ([0-9.]+)\)/);

  if (rgbaMatch) {
    const [_, rStr, gStr, bStr, aStr] = rgbaMatch;
    const r = parseInt(rStr);
    const g = parseInt(gStr);
    const b = parseInt(bStr);
    const a = parseFloat(aStr);

    const newR = Math.max(0, Math.min(255, r * (1 - percent / 100)));
    const newG = Math.max(0, Math.min(255, g * (1 - percent / 100)));
    const newB = Math.max(0, Math.min(255, b * (1 - percent / 100)));

    return `rgba(${newR}, ${newG}, ${newB}, ${a})`;
  } else {
    return rgba;
  }
};

export const calcGrowthFromValues = (newValue, oldValue) => {
  if (oldValue === 0) return 0;
  return ((newValue - oldValue) / oldValue) * 100;
};

export const calcTotalGrowthFromArray = (initialData, valueGetter, growthGetter) => {
  const sum = initialData.reduce(
    (acc, item) => {
      const newValue = valueGetter(item);
      const growth = growthGetter(item);
      const oldValue = newValue / (1 + growth / 100) || 0;
      return {
        oldValue: acc.oldValue + oldValue,
        newValue: acc.newValue + newValue,
      };
    },
    { oldValue: 0, newValue: 0 },
  );
  return calcGrowthFromValues(sum.newValue, sum.oldValue);
};

export const calculateLinearRegression = (data: number[]) => {
  const xValues = data.map((_, index) => index + 1); // Use indices as x values
  const yValues = data;

  const dataPoints = xValues.map((x, i) => [x, yValues[i]]);

  const regression = linearRegression(dataPoints);
  const regressionLineFunc = linearRegressionLine(regression);

  const regLine = xValues.map((x) => ({
    x,
    y: regressionLineFunc(x),
  }));

  return regLine;
};

export const roundRetailPrice = (price: number) => {
  if (price % 1 === 0) {
    return price;
  }
  const roundedPrice = Math.ceil(price);
  return roundedPrice > 0 ? roundedPrice - 0.01 : roundedPrice + 0.01;
};

export const getNonRepeatedSubstring = (str1: string, str2: string) => {
  const minLength = Math.min(str1.length, str2.length);
  let commonPrefixLength = 0;

  for (let i = 0; i < minLength; i++) {
    if (str1[i] === str2[i]) {
      commonPrefixLength++;
    } else {
      break;
    }
  }

  if (str1.length > str2.length) {
    return str1.substring(commonPrefixLength).trim();
  } else {
    return str2.substring(commonPrefixLength).trim();
  }
};
