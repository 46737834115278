import React from 'react';

export interface BackgroundTaskWatcherConfig {
  onCheck: (...args: any[]) => Promise<boolean> | boolean;
}

export const useBackgroundTaskWatcher = (config?: BackgroundTaskWatcherConfig) => {
  const { onCheck } = config || {};
  const ref = React.useRef<{
    intervalTimer: any;
    counter: number;
  }>({
    intervalTimer: null,
    counter: 0,
  });
  const disposer = React.useCallback(() => {
    const { intervalTimer } = ref.current;
    if (intervalTimer) {
      clearInterval(intervalTimer);
      ref.current.intervalTimer = null;
    }
  }, []);
  const checker = React.useCallback(async (...args) => {
    if (onCheck) {
      const res = await onCheck(...args);
      if (res) {
        disposer();
      }
    }
  }, []);
  React.useEffect(() => disposer, []);
  const watchBackgroundTask = React.useCallback((taskId) => {
    checker(taskId);
    ref.current.intervalTimer = setInterval(() => {
      ref.current.counter = ref.current.counter + 1;
      checker(taskId);
    }, 5000);
  }, []);
  return watchBackgroundTask;
};

export default useBackgroundTaskWatcher;
