import React from 'react';

const Loading: React.FC = () => {
  return (
    <div id="loading" className="loading hide-loading">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loading;
