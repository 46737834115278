import React from 'react';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ShareButton = (props: any) => {
  const { text, setCopied } = props;
  return (
    <>
      <button
        className="btn btn-outline-primary btn-sm "
        type="button"
        onClick={async () => {
          navigator.clipboard.writeText(`${window.location.origin}${text as string}`);
          setCopied(true);
        }}
        // style={{ border: '1px solid #3f80ea' }}
      >
        <FontAwesomeIcon icon={faShareAlt} className="me-1" /> Share
      </button>
    </>
  );
};

export default ShareButton;
