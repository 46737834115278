import {
  adminSection,
  analysisSection,
  forecastSection,
  priceAdminSection,
  productUserSection,
  salesBonusAdminSection,
  salesBonusSection,
} from '../components/sidebar/dashboardItems';

export const AdminPortions = [forecastSection, analysisSection, salesBonusAdminSection, adminSection];
export const ForecastingUserPortions = [forecastSection, analysisSection];
export const SalesBonusAdminPortions = [salesBonusAdminSection];
export const PriceAdminPortions = [priceAdminSection];
export const SalesBonusPortions = [salesBonusSection];
export const ProductUserPortions = [productUserSection];
