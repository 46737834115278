import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { FeeTypes } from '../../../utils/roles';
import { ApprovalStatus } from '../../../constants';

const FeeHistory = ({ history, isFreight }: { history: any; isFreight: boolean }) => {
  const [expandedItems, setExpandedItems] = useState({});
  const toggleExpand = (index) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  return (
    <div>
      <div>
        <div className="row mt-3">
          <div className="col" style={{ paddingLeft: '20px', height: '390px', overflowY: 'scroll' }}>
            {history.length === 0 && <h4>There is no history yet.</h4>}
            {history.map((item, index) => {
              const date = moment(item.createdAt);
              const formattedDate = date.format('MMMM DD, hh:mm A');
              const isExpanded = expandedItems[index];
              const allDiffs = isFreight ? item.freightDiff : item.warDiff;
              return allDiffs?.newValues?.length ? (
                <div className="row" key={index}>
                  <div className="col col-1" style={{ width: '20px' }}>
                    <FontAwesomeIcon
                      icon={isExpanded ? faAngleDown : faAngleRight}
                      className="my-2"
                      onClick={() => toggleExpand(index)}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  <div className="col">
                    <div>
                      <span>
                        <strong>{item.approvalStatus}</strong>:&nbsp;{formattedDate}
                      </span>
                    </div>
                    <div>
                      {item.approvals.length === 0 ? (
                        <span className={`badge bg-danger me-2`}>No approvals</span>
                      ) : (
                        item.approvals.map((approval, index) => (
                          <span
                            key={index}
                            className={`badge bg-${approval.approvalStatus === ApprovalStatus.REJECTED ? 'danger' : Number(item.approvalCount) === Number(process.env.REACT_APP_PRICING_APPROVAL_COUNT) && !approval.weasRejected ? 'success' : 'warning'} me-2`}
                          >
                            {approval?.user?.firstName} {approval?.user?.lastName}
                          </span>
                        ))
                      )}
                    </div>
                    {isExpanded && (
                      <div>
                        {allDiffs.newValues?.length > 0 ? (
                          <>
                            <h5 className="mt-2 pt-2" style={{ borderTop: '1px dotted #cdcdcd' }}>
                              Changed Fields:
                            </h5>
                            <div className="table-responsive" style={{ fontSize: '11px' }}>
                              <table className="table ">
                                <thead>
                                  <tr>
                                    <th>Previous</th>
                                    <th>New</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td style={{ verticalAlign: 'top', paddingLeft: 0 }}>
                                      <table className="table table-striped table-hover">
                                        <thead>
                                          <tr>
                                            {isFreight && <th>Brand</th>}
                                            <th>Warehouse</th>
                                            <th>Fees</th>
                                            <th>Action type</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {allDiffs.oldValues.map((value: any, index) => (
                                            <tr key={index}>
                                              {isFreight && (
                                                <td className={value.actionType === 'Deleted' ? 'text-danger' : ''}>
                                                  {value.brand}
                                                </td>
                                              )}
                                              <td className={value.actionType === 'Deleted' ? 'text-danger' : ''}>
                                                {value.warehouse ||
                                                  (value.feeType === FeeTypes.psi ? 'PSI' : 'Insurance')}
                                              </td>
                                              <td className={value.actionType === 'Deleted' ? 'text-danger' : ''}>
                                                {value.fee}
                                              </td>
                                              <td className={value.actionType === 'Deleted' ? 'text-danger' : ''}>
                                                {value.actionType}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </td>
                                    <td style={{ verticalAlign: 'top', paddingRight: 0 }}>
                                      <table className="table table-striped table-hover">
                                        <thead>
                                          <tr>
                                            {isFreight && <th>Brand</th>}
                                            <th>Warehouse</th>
                                            <th>Fees</th>
                                            <th>Action type</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {allDiffs.newValues.map((value: any, index) => (
                                            <tr key={index}>
                                              {isFreight && (
                                                <td className={value.actionType === 'Deleted' ? 'text-danger' : ''}>
                                                  {value.brand}
                                                </td>
                                              )}
                                              <td className={value.actionType === 'Deleted' ? 'text-danger' : ''}>
                                                {value.warehouse ||
                                                  (value.feeType === FeeTypes.psi ? 'PSI' : 'Insurance')}
                                              </td>
                                              <td className={value.actionType === 'Deleted' ? 'text-danger' : ''}>
                                                {value.fee}
                                              </td>
                                              <td className={value.actionType === 'Deleted' ? 'text-danger' : ''}>
                                                {value.actionType}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              ) : null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeHistory;
