import Axios from '../lib/axios';

const GetValue = (name: string) => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/feature', { params: { name } });
};

const FeatureService = {
  GetValue,
};

export default FeatureService;
