import React, { useState } from 'react';
import { faCircleXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';
import FeeHistory from './History';

const FreightFees = ({
  freightFees,
  setFreightFees,
  brands,
  warehouses,
  deletedFees,
  setDeletedFees,
  createdFees,
  setCreatedFees,
  updatedFees,
  setUpdatedFees,
  history,
  submit,
  hasFreightHistory,
}: {
  freightFees: any;
  setFreightFees: (x: any) => void;
  brands: any;
  warehouses: any;
  deletedFees: any;
  setDeletedFees: (x: any) => void;
  createdFees: any;
  setCreatedFees: (x: any) => void;
  updatedFees: any;
  setUpdatedFees: (x: any) => void;
  history: any;
  submit: (x: boolean) => void;
  hasFreightHistory: boolean;
}) => {
  const [freightTabIndex, setFreightTabIndex] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [newFreightFee, setNewFreightFee] = useState({
    brandId: '',
    brandName: '',
    warehouse: '',
    warehouseId: '',
    fee: '',
    freightFeeId: '',
  });

  const handleFieldChange = (newValue: any, wasCreated: boolean, prevData: any) => {
    if (!isNaN(newValue)) {
      const existingChange = freightFees.find(
        (change: any) => change.brandId === prevData.brandId && change.warehouseId === prevData.warehouseId,
      );

      if (existingChange) {
        const updatedChanges = freightFees.map((change: any) =>
          change.brandId === prevData.brandId && change.warehouseId === prevData.warehouseId
            ? { ...change, fee: newValue }
            : change,
        );
        setFreightFees(updatedChanges);

        if (wasCreated) {
          const existingChangeCreatedData = createdFees.find(
            (change: any) => change.brandId === prevData.brandId && change.warehouseId === prevData.warehouseId,
          );

          if (existingChangeCreatedData) {
            const updatedCreatedChanges = createdFees.map((change: any) =>
              change.brandId === prevData.brandId && change.warehouseId === prevData.warehouseId
                ? { ...change, fee: newValue }
                : change,
            );
            setCreatedFees(updatedCreatedChanges);
          } else {
            setCreatedFees([...createdFees, { ...existingChange, fee: newValue }]);
          }

          const filteredDeletedData = deletedFees.filter(
            (item1) => !createdFees.some((item2) => item1.freightFeeId === item2.freightFeeId),
          );
          setDeletedFees(filteredDeletedData);
        } else {
          const existingChangeEditedData = updatedFees.find(
            (change: any) => change.brandId === prevData.brandId && change.warehouseId === prevData.warehouseId,
          );

          if (existingChangeEditedData) {
            const updatedEditedChanges = updatedFees.map((change: any) =>
              change.brandId === prevData.brandId && change.warehouseId === prevData.warehouseId
                ? { ...change, fee: newValue, actionType: 'Updated' }
                : change,
            );
            setUpdatedFees(updatedEditedChanges);
          } else {
            setUpdatedFees([...updatedFees, { ...existingChange, fee: newValue, actionType: 'Updated' }]);
          }
        }
      } else {
        setFreightFees([...freightFees, { ...prevData, fee: newValue }]);
      }
    }
  };

  const addNewFreight = () => {
    if (
      newFreightFee.brandId !== '' &&
      newFreightFee.warehouse !== '' &&
      newFreightFee.fee !== '' &&
      Number(newFreightFee.fee) !== 0
    ) {
      if (
        !freightFees.some(
          (item) => item.brandId === newFreightFee.brandId && item.warehouse === newFreightFee.warehouse,
        )
      ) {
        setErrorMessage('');
        setFreightFees((prevData) => {
          const data = prevData;
          data.unshift({ ...newFreightFee, freightFeeId: uuidv4(), wasCreated: true });
          return data;
        });
        setCreatedFees((prevData) => {
          const data = prevData;
          data.push({ ...newFreightFee, freightFeeId: uuidv4(), wasCreated: true });
          return data;
        });
        setNewFreightFee({
          brandId: '',
          brandName: '',
          warehouse: '',
          fee: '',
          freightFeeId: '',
          warehouseId: '',
        });
      } else {
        setErrorMessage('There is an existing fee for the selected brand a warehouse.');
      }
    }
  };

  return (
    <div className="col">
      <div>
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item" role="presentation">
            <a
              className={`nav-link ${freightTabIndex === 1 && 'active'}`}
              role="tab"
              data-bs-toggle="tab"
              aria-selected={freightTabIndex === 1}
              onClick={() => setFreightTabIndex(1)}
            >
              Freight Fees
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className={`nav-link ${freightTabIndex === 2 && 'active'}`}
              role="tab"
              data-bs-toggle="tab"
              aria-selected={freightTabIndex === 2}
              onClick={() => setFreightTabIndex(2)}
            >
              Fee History
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div className={`tab-pane ${freightTabIndex == 1 && 'active show'}`} role="tabpanel" id="tab-1">
            <div className="row mt-3">
              <div className="col">
                <div className="table-responsive mb-2" style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                  <table className="table table-sm">
                    <thead className="sticky-top">
                      <tr>
                        <th>Brand</th>
                        <th>Warehouse</th>
                        <th>Fees</th>
                      </tr>
                    </thead>
                    <tbody>
                      {freightFees.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ width: '33%' }}>{item.brandName}</td>
                            <td style={{ width: '33%' }}>{item.warehouse}</td>
                            <td className="d-flex">
                              <input
                                type="text"
                                value={item.fee}
                                onChange={(event) => handleFieldChange(event.target.value, item.wasCreated, item)}
                              ></input>
                              <button
                                className="btn btn-danger mx-2"
                                type="button"
                                onClick={() => {
                                  const feeExist = deletedFees.some(
                                    (subItem: any) =>
                                      subItem.brandId === item.brandId && subItem.warehouseId === item.warehouseId,
                                  );
                                  if (!feeExist) {
                                    setDeletedFees(
                                      item.wasCreated
                                        ? deletedFees
                                        : (prevItems: any) => [...prevItems, { ...item, actionType: 'Deleted' }],
                                    );
                                    setUpdatedFees(
                                      updatedFees.filter(
                                        (item1) =>
                                          !deletedFees.some(
                                            (item2) =>
                                              item1.brandId === item2.brandId &&
                                              item1.warehouseId === item2.warehouseId,
                                          ),
                                      ),
                                    );
                                    setCreatedFees(
                                      createdFees.filter(
                                        (item1) =>
                                          !deletedFees.some(
                                            (item2) =>
                                              item1.brandId === item2.brandId &&
                                              item1.warehouseId === item2.warehouseId,
                                          ),
                                      ),
                                    );
                                  }
                                  setFreightFees((prevItems: any) =>
                                    prevItems.filter(
                                      (subItem: any) =>
                                        !(subItem.brandId === item.brandId && subItem.warehouseId === item.warehouseId),
                                    ),
                                  );
                                }}
                              >
                                <FontAwesomeIcon icon={faCircleXmark} style={{ fontSize: '13px' }} />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4">
                <select
                  className="form-select"
                  value={newFreightFee.brandId}
                  onChange={(e) => {
                    setNewFreightFee({
                      ...newFreightFee,
                      brandId: e.target.value,
                      brandName: brands.find((item) => item.value === e.target.value)?.label,
                    });
                  }}
                >
                  {brands.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-xl-4">
                <select
                  className="form-select"
                  value={newFreightFee.warehouse}
                  onChange={(e) => {
                    setNewFreightFee({
                      ...newFreightFee,
                      warehouse: e.target.value,
                      warehouseId: warehouses.find((item) => item.name === e.target.value).warehouseId,
                    });
                  }}
                >
                  <option value={''}>Warehouses</option>
                  {warehouses.map((item, index) => {
                    return (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-xl-2">
                <input
                  type="text"
                  placeholder="Fee"
                  value={newFreightFee.fee}
                  className="form-control me-2"
                  onChange={(e) => {
                    const newValue: any = e.target.value;
                    if (!isNaN(newValue)) {
                      setNewFreightFee({ ...newFreightFee, fee: String(newValue) });
                    }
                  }}
                ></input>
              </div>
              <div className="col">
                <button className="btn btn-primary" type="button" onClick={addNewFreight}>
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            </div>
          </div>
          <div className={`tab-pane ${freightTabIndex == 2 && 'active show'}`} role="tabpanel" id="tab-2">
            <FeeHistory isFreight={true} history={history} />
          </div>
          <hr />
        </div>
      </div>
      <p className="text-danger col d-flex justify-content-end">{errorMessage}</p>
      <button className="btn float-end btn-primary" type="button" onClick={() => submit(true)}>
        {hasFreightHistory ? 'Update request' : 'Submit for Approval'}
      </button>
    </div>
  );
};

export default FreightFees;
