import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faBoxOpen, faXmark } from '@fortawesome/free-solid-svg-icons';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import ProductDetail from './Details';
import ProductHistory from './History';
import ProductStatesFobs from './StateFobs';
import ProductExtendedProperties from './ExtendedProperties';
import { ProductSchema } from '../../../../utils/validations';
import AdminService from '../../../../services/admin.service';
import { hideLoading, showLoading } from '../../../../lib/uiService';
import { useAuth } from '../../../../providers/AuthProvider';
import { UserRole } from '../../../../lib/types';
import ProductRelations from './Relation';
import { CurrencyTypes } from '../../../../constants';

const ProductTabs = ({
  selectedObject,
  fetchProducts,
  productHasHistory,
  setProductHasHistory,
  productHistory,
  setSelectedObject,
  editable,
  setEditable,
  labels,
  territories,
  statePrices,
  initialStatePricesData,
  setStatePrices,
  setProductHistory,
  relatedProducts,
  setRelatedProducts,
  initialRelatedProducts,
  setInitialRelatedProducts,
  disabled,
}: {
  selectedObject: any;
  productHasHistory: boolean;
  setProductHasHistory: (x: boolean) => void;
  fetchProducts: () => void;
  setSelectedObject: (x: any) => void;
  setEditable: (x: any) => void;
  editable: boolean;
  disabled: boolean;
  labels: any;
  territories: any;
  productHistory: any;
  statePrices: any;
  initialStatePricesData: any;
  setStatePrices: (x: any) => void;
  relatedProducts: any;
  setRelatedProducts: (x: any) => void;
  initialRelatedProducts: any;
  setInitialRelatedProducts: (x: any) => void;
  setProductHistory: (x: any) => void;
}) => {
  const { setError } = useErrorHandling();
  const { role, id } = useAuth();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [tabIndex, setTabIndex] = useState(1);
  const [deletedTerritoryPrices, setDeletedTerritoryPrices] = useState<any>([]);
  const [createdTerritoryPrices, setCreatedTerritoryPrices] = useState<any>([]);
  const [editedTerritoryPrices, setEditedTerritoryPrices] = useState<any>([]);
  const [warehouseFees, setWarehouseFees] = useState<any>([]);
  const [statesPricesChanged, setStatesPricesChanged] = useState(false);
  const [tiersAreValid, setTiersAreValid] = useState(false);
  const [prodRelationsChanged, setProdRelationsChanged] = useState(false);

  useEffect(() => {
    AdminService.GetWarehouseFees()
      .then(({ data }) => {
        setWarehouseFees(data);
      })
      .catch((error) => {
        console.log(error);
        setError({ status: error.response.status || 500 });
      });
  }, []);

  useEffect(() => {
    setStatesPricesChanged(checkFieldsChange(initialStatePricesData, statePrices, 'name'));
    setProdRelationsChanged(checkFieldsChange(initialRelatedProducts, relatedProducts, 'sku'));
    setTiersAreValid(checktTiers());
  }, [statePrices, relatedProducts]);

  const checkFieldsChange = (initState, state, field) => {
    const sortArray = (arr) => {
      return arr.slice().sort((a, b) => a[field]?.localeCompare(b[field]));
    };

    // sort arrays so that the indexes match each territory
    const sortedInitialData = sortArray(initState);
    const sortedCurrentData = sortArray(state);

    if (sortedCurrentData.length !== sortedInitialData.length) {
      return true;
    }

    return sortedCurrentData.some((currentItem, index) => {
      const initialItem = sortedInitialData[index];
      return JSON.stringify(currentItem) !== JSON.stringify(initialItem);
    });
  };

  const checktTiers = () => {
    return statePrices.some(
      (terr) =>
        (terr.tier1Price !== null && terr.tier1Price !== '' && Number(terr.tier1Price) <= 0) ||
        (terr.tier2Price !== null && terr.tier2Price !== '' && Number(terr.tier2Price) <= 0),
    );
  };

  const onSubmit = async (values) => {
    const territoryHasNoPrice = statePrices.some((item: any) => Number(item.price) <= 0);
    if (territoryHasNoPrice) {
      setErrorMessage('All territories must have a price greater than 0.');
    } else {
      setErrorMessage('');
      const productData = {
        productHistoryId: selectedObject.productHistoryId,
        mappedSkus: relatedProducts,
        product: {
          productId: selectedObject.productId,
          brandId: selectedObject.brandId,
          description: values.label + ' ' + values.productSpecificName,
          labelId: values.labelId,
          warehouseId: values.warehouseId || null,
          federalExciseTax: values.federalExciseTax,
          volume: values.volume ? Number(values.volume) : null,
          quantity: values.quantity ? Number(values.quantity) : null,
          productIsActive: values.productIsActive,
          isDiscontinued: values.isDiscontinued,
          cogs: values.cogs ? Number(values.cogs) : null,
          currencyType: values.currencyType ? Number(values.currencyType) : CurrencyTypes.USD,
          landedCogs: values.landedCogs ? Number(values.landedCogs) : null,
          insurance: values.insurance ? Number(values.insurance) : null,
          freight: values.freight ? Number(values.freight) : null,
          abv: values.abv ? Number(values.abv) : null,
          margin: values.margin ? Number(values.margin) : null,
          natlFob: values.natlFob ? Number(values.natlFob) : null,
          tier1Price: values.tier1Price ? Number(values.tier1Price) : null,
          tier2Price: values.tier2Price ? Number(values.tier2Price) : null,
          preFeeFOB: values.preFeeFOB ? Number(values.preFeeFOB) : null,
          warehouseFees: values.warehouseFees ? Number(values.warehouseFees) : null,
          warehouse: values.warehouse,
          psiFees: 1.24,
          markup: values.markup ? Number(values.markup) : null,
          distributorAllowance: values.distributorAllowance ? Number(values.distributorAllowance) : null,
          distributorMargin: values.distributorMargin ? Number(values.distributorMargin) : null,
          distributorCasePrice: values.distributorCasePrice ? Number(values.distributorCasePrice) : null,
          distributorBottlePrice: values.distributorBottlePrice ? Number(values.distributorBottlePrice) : null,
          retailMarkup: values.retailMarkup ? Number(values.retailMarkup) : null,
          retailPrice: values.retailPrice ? Number(values.retailPrice) : null,
          suggestedRetailPrice: values.suggestedRetailPrice ? Number(values.suggestedRetailPrice) : null,
          bbpProductNumber: values.bbpProductNumber,
        },
        productPrice: {
          deletedTerritoryPrices,
          createdTerritoryPrices,
          editedTerritoryPrices,
        },
        productProperty: {
          productId: selectedObject.productId,
          vintage: values.vintage,
          bdnCode: values.bdnCode,
          colaTtbId: values.colaTtbId,
          palletWeight: values.palletWeight ? Number(values.palletWeight) : null,
          upc: values.upcCode,
          sccCode: values.sccCode,
          nabcaCode: values.nabcaCode,
          isComplianceProduct: values.isComplianceProduct,
          unitWeight: values.unitWeight ? Number(values.unitWeight) : null,
          layersPerPallet: values.layersPerPallet ? Number(values.layersPerPallet) : null,
          casesPerLayer: values.casesPerLayer ? Number(values.casesPerLayer) : null,
          casesPerPallet: values.casesPerPallet ? Number(values.casesPerPallet) : null,
          length: values.length ? Number(values.length) : null,
          width: values.width ? Number(values.width) : null,
          height: values.height ? Number(values.height) : null,
          weight: values.weight ? Number(values.weight) : null,
          containerType: values.containerType,
          productType: values.productType,
          productSubType: values.productSubType,
        },
      };
      showLoading();
      // if there's an open request and the user is a pricing admin
      if (productHasHistory && values.sendApproval) {
        // check approval count before submitting
        AdminService.GetApprovalCount({ productHistoryId: selectedObject.productHistoryId })
          .then(({ data }) => {
            if (Number(data.approvalCount) >= Number(process.env.REACT_APP_PRICING_APPROVAL_COUNT)) {
              hideLoading();
              toast.warning('This request has been approved');
              return;
            } else {
              AdminService.AddApprovalAndCheckRequestState(productData)
                .then(({ data }) => {
                  setEditable(false);
                  setProductHasHistory(false);
                  setRelatedProducts([]);
                  setInitialRelatedProducts([]);
                  setSelectedObject({});
                  setProductHistory([]);
                  setDeletedTerritoryPrices([]);
                  setCreatedTerritoryPrices([]);
                  setEditedTerritoryPrices([]);
                  hideLoading();
                  setTabIndex(1);
                  toast.success(
                    data.wasApproved
                      ? 'The request has been approved'
                      : 'You have approved these changes. Awaiting more approvals.',
                  );
                  fetchProducts();
                })
                .catch((e) => {
                  hideLoading();
                  setError(e.status ? e : { status: 500 });
                });
            }
          })
          .catch((error) => {
            setError({ status: error.response.status || 500 });
          });
      } else {
        // if there's an open request, update it
        if (productHasHistory) {
          AdminService.UpdateProductHistory(productData)
            .then(() => {
              setEditable(false);
              setProductHasHistory(false);
              setRelatedProducts([]);
              setInitialRelatedProducts([]);
              setSelectedObject({});
              setProductHistory([]);
              setDeletedTerritoryPrices([]);
              setCreatedTerritoryPrices([]);
              setEditedTerritoryPrices([]);
              hideLoading();
              setTabIndex(1);
              toast.success('The request has been updated.');
            })
            .catch((e) => {
              hideLoading();
              setError(e.status ? e : { status: 500 });
            });

          // otherwise create a request
        } else {
          AdminService.RequestProductApproval(productData)
            .then(() => {
              setEditable(false);
              setProductHasHistory(false);
              setRelatedProducts([]);
              setInitialRelatedProducts([]);
              setSelectedObject({});
              setProductHistory([]);
              setDeletedTerritoryPrices([]);
              setCreatedTerritoryPrices([]);
              setEditedTerritoryPrices([]);
              hideLoading();
              setTabIndex(1);
              toast.success('The changes request has been sent for approval.');
              fetchProducts();
            })
            .catch((e) => {
              hideLoading();
              setError(e.status ? e : { status: 500 });
            });
        }
      }
    }
  };

  const normalizeValue = (value: string) => {
    const num = Number(value);
    if (typeof value == 'boolean') {
      return value;
    }
    if (isNaN(num)) {
      return value;
    }
    return parseFloat(value).toString();
  };

  return (
    <div className="card shadow mb-[40px] p-0">
      <div className="card-header d-flex justify-content-between">
        <h5 className="text-primary m-0 fw-bold pt-1 pb-1 d-flex align-items-center">
          <FontAwesomeIcon icon={faBoxOpen} className="me-2" style={{ fontSize: '30px' }} />
          {editable ? 'Edit' : 'Create'} Product
        </h5>
        {editable && (
          <div className="pt-2 px-3 cursor-pointer">
            <FontAwesomeIcon
              icon={faXmark}
              onClick={() => {
                setEditable(false);
                setProductHasHistory(false);
                setSelectedObject({ label: selectedObject.label, labelId: selectedObject.labelId });
                setStatePrices([]);
              }}
            />
          </div>
        )}
      </div>
      <div className="card-body">
        <Formik
          enableReinitialize
          initialValues={{
            productSpecificName: selectedObject?.productSpecificName || '',
            federalExciseTax: selectedObject?.federalExciseTax || '',
            quantity: selectedObject?.quantity || '',
            volume: selectedObject?.volume || '',
            bbpProductNumber: selectedObject?.bbpProductNumber || '',
            labelId: selectedObject?.labelId || '',
            productIsActive: selectedObject.productIsActive,
            isDiscontinued: selectedObject.isDiscontinued,
            label: selectedObject?.label || '',
            brandId: selectedObject?.brandId || '',
            cogs: selectedObject?.cogs || '',
            currencyType: selectedObject?.currencyType || CurrencyTypes.USD,
            margin: selectedObject?.margin || '',
            markup: selectedObject?.markup || '',
            freight: selectedObject?.freight || '',
            abv: selectedObject?.abv || '',
            landedCogs: selectedObject?.landedCogs || '',
            insurance: selectedObject?.insurance || '',
            warehouseFees: selectedObject?.warehouseFees || '',
            warehouseId: selectedObject?.warehouseId || '',
            psiFees: 1.97,
            natlFob: selectedObject?.natlFob || '',
            tier1Price: selectedObject?.tier1Price || '',
            tier2Price: selectedObject?.tier2Price || '',
            preFeeFOB: selectedObject?.preFeeFOB || '',
            distributorAllowance: selectedObject?.distributorAllowance || '',
            distributorMargin: selectedObject?.distributorMargin || '',
            distributorCasePrice: selectedObject?.distributorCasePrice || '',
            distributorBottlePrice: selectedObject?.distributorBottlePrice || '',
            retailMarkup: selectedObject?.retailMarkup || '',
            retailPrice: selectedObject?.retailPrice || '',
            suggestedRetailPrice: selectedObject?.suggestedRetailPrice || '',
            productType: selectedObject?.productType || 'Spirits',
            productSubType: selectedObject?.productSubType || 'Spirits',
            vintage: selectedObject?.vintage,
            containerType: selectedObject?.containerType || 'Glass Bottle',
            unitWeight: selectedObject?.unitWeight,
            palletWeight: selectedObject?.palletWeight,
            layersPerPallet: selectedObject?.layersPerPallet,
            casesPerLayer: selectedObject?.casesPerLayer,
            casesPerPallet: selectedObject?.casesPerPallet,
            length: selectedObject?.length,
            width: selectedObject?.width,
            height: selectedObject?.height,
            weight: selectedObject?.weight,
            colaTtbId: selectedObject?.colaTtbId,
            upcCode: selectedObject?.upc,
            nabcaCode: selectedObject?.nabcaCode,
            sccCode: selectedObject?.sccCode,
            bdnCode: selectedObject?.bdnCode,
            isComplianceProduct: selectedObject?.isComplianceProduct || false,
            sendApproval: false,
            isFrozen: true,
          }}
          validationSchema={ProductSchema}
          onSubmit={onSubmit}
        >
          {({ errors, setFieldValue, handleSubmit, values, initialValues, setSubmitting }) => {
            const isDirty = () => {
              return Object.keys(initialValues).some((key) => {
                const initialValue = normalizeValue(initialValues[key]);
                const currentValue = normalizeValue(values[key]);
                return initialValue !== currentValue;
              });
            };

            return (
              <Form>
                {productHasHistory && (
                  <>
                    <div role="alert" className="fade alert-outline alert alert-primary alert-dismissible show">
                      <button type="button" className="btn-close d-none"></button>
                      <div className="alert-icon d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon icon={faBell} />
                      </div>
                      <div className="alert-message">
                        <strong>There are pending edits to this product awaiting your approval.&nbsp;</strong> Click to
                        approve.
                        <button
                          disabled={
                            (!role?.includes(UserRole.PricingAdmin) && !role?.includes(UserRole.Admin)) ||
                            productHistory[0]?.approvals.some((item) => item.createdBy === id)
                          }
                          className="btn btn-success mx-2"
                          onClick={(event) => {
                            event.preventDefault();
                            setFieldValue('sendApproval', true);
                            handleSubmit();
                          }}
                          style={{ textAlign: 'right' }}
                        >
                          Approve
                        </button>
                        <button
                          disabled={
                            (!role?.includes(UserRole.PricingAdmin) && !role?.includes(UserRole.Admin)) ||
                            productHistory[0]?.approvals.some((item) => item.createdBy === id)
                          }
                          className="btn btn-danger mx-2"
                          onClick={(event) => {
                            event.preventDefault();
                            AdminService.RejectApproval({
                              referenceId: selectedObject.approvalReferenceId,
                              isProductApproval: true,
                            })
                              .then(() => {
                                setEditable(false);
                                setProductHasHistory(false);
                                setSelectedObject({});
                                setProductHistory([]);
                                setDeletedTerritoryPrices([]);
                                setCreatedTerritoryPrices([]);
                                setEditedTerritoryPrices([]);
                                hideLoading();
                                setTabIndex(1);
                                toast.success('The request has been rejected.');
                              })
                              .catch((e) => {
                                hideLoading();
                                setError(e.status ? e : { status: 500 });
                              });
                          }}
                          style={{ textAlign: 'right' }}
                        >
                          Reject
                        </button>
                      </div>
                    </div>
                  </>
                )}
                <div>
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className={`nav-link ${tabIndex === 1 && 'active'}`}
                        role="tab"
                        data-bs-toggle="tab"
                        aria-selected={tabIndex === 1}
                        onClick={() => setTabIndex(1)}
                      >
                        Pricing
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className={`nav-link ${tabIndex === 2 && 'active'}`}
                        role="tab"
                        data-bs-toggle="tab"
                        aria-selected={tabIndex === 2}
                        onClick={() => setTabIndex(2)}
                      >
                        State FOBs
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className={`nav-link ${tabIndex === 3 && 'active'}`}
                        role="tab"
                        data-bs-toggle="tab"
                        aria-selected={tabIndex === 3}
                        onClick={() => setTabIndex(3)}
                      >
                        Portfolio Specs
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className={`nav-link ${tabIndex === 4 && 'active'}`}
                        role="tab"
                        data-bs-toggle="tab"
                        aria-selected={tabIndex === 4}
                        onClick={() => setTabIndex(4)}
                      >
                        Product Mappings
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className={`nav-link ${tabIndex === 5 && 'active'}`}
                        role="tab"
                        data-bs-toggle="tab"
                        aria-selected={tabIndex === 5}
                        onClick={() => setTabIndex(5)}
                      >
                        Product History
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div className={`tab-pane ${tabIndex == 1 && 'active show'}`} role="tabpanel" id="tab-1">
                      <ProductDetail warehousesFees={warehouseFees} labels={labels} disabled={disabled} />
                    </div>
                    <div className={`tab-pane ${tabIndex == 2 && 'active show'}`} role="tabpanel" id="tab-2">
                      <ProductStatesFobs
                        territories={territories}
                        statePrices={statePrices}
                        setStatePrices={setStatePrices}
                        deletedTerritoryPrices={deletedTerritoryPrices}
                        setDeletedTerritoryPrices={setDeletedTerritoryPrices}
                        createdTerritoryPrices={createdTerritoryPrices}
                        setCreatedTerritoryPrices={setCreatedTerritoryPrices}
                        editedTerritoryPrices={editedTerritoryPrices}
                        setEditedTerritoryPrices={setEditedTerritoryPrices}
                        natlPrice={values.natlFob}
                      />
                    </div>
                    <div className={`tab-pane ${tabIndex == 3 && 'active show'}`} role="tabpanel" id="tab-3">
                      <ProductExtendedProperties disabled={disabled} />
                    </div>
                    <div className={`tab-pane ${tabIndex == 4 && 'active show'}`} role="tabpanel" id="tab-4">
                      <ProductRelations relatedProducts={relatedProducts} setRelatedProducts={setRelatedProducts} />
                    </div>
                    <div className={`tab-pane ${tabIndex == 5 && 'active show'}`} role="tabpanel" id="tab-5">
                      <ProductHistory productHistory={productHistory} />
                    </div>
                  </div>
                </div>
                <div>
                  <p className="text-danger col d-flex justify-content-end">{errorMessage}</p>
                  <p className="text-danger col d-flex justify-content-end">
                    {Object.keys(errors).length > 0 &&
                      !role?.includes(UserRole.ProductUser) &&
                      'There are missing fields that need to be completed.'}
                  </p>
                  <div className="col d-flex justify-content-end">
                    <button
                      disabled={(!isDirty() && !statesPricesChanged && !prodRelationsChanged) || tiersAreValid}
                      className="btn btn-primary float-end"
                      type="button"
                      onClick={(event) => {
                        event.preventDefault();
                        if (role?.includes(UserRole.ProductUser)) {
                          setSubmitting(true);
                          onSubmit(values);
                          setSubmitting(false);
                        } else {
                          setFieldValue('sendApproval', false);
                          handleSubmit();
                        }
                      }}
                      style={{ textAlign: 'right' }}
                    >
                      Submit for Approval
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ProductTabs;
