import React from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';

const ProductExtendedProperties = ({ disabled }: { disabled: boolean }) => {
  const { setFieldValue, values } = useFormikContext<any>();

  return (
    <div className="pt-3">
      <div className="row">
        <div className="col col-4" style={{ borderRight: '1px solid #EEEEEE' }}>
          <div className="row">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Product Type
              </label>
              <Field disabled={disabled} as="select" name="productType" className="form-select form">
                <option value="Spirits">Spirits</option>
                <option value="Wine">Wine</option>
                <option value="Other">Other</option>
              </Field>
              <ErrorMessage name="productType" component="div" className="text-danger" />
            </div>
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Product Sub Type
              </label>
              <Field disabled={disabled} as="select" name="productSubType" className="form-select">
                <option value="Accounting">Accounting</option>
                <option value="Dry Goods">Dry Goods</option>
                <option value="Non-alcoholic Consumables">Non-alcoholic Consumables</option>
                <option value="Point of Sale">Point of Sale</option>
                <option value="Spirits">Spirits</option>
                <option value="Wine">Wine</option>
              </Field>
              <ErrorMessage name="productSubType" component="div" className="text-danger" />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Vintage
              </label>
              <Field disabled={disabled} type="text" name="vintage" className="form-control" />
            </div>
            <ErrorMessage name="vintage" component="div" className="text-danger" />
          </div>
          <div className="row">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Container Type
              </label>
              <Field disabled={disabled} as="select" name="containerType" className="form-select form">
                <option value="Glass Bottle">Glass Bottle</option>
                <option value="Other">Other</option>
              </Field>
            </div>
            <ErrorMessage name="containerType" component="div" className="text-danger" />
          </div>
          <div className="row">
            <div className="row">
              <div className="col">
                <label className="form-label mt-2">Compliance Product?</label>
                <div className="d-flex align-items-center">
                  <div className="form-check form-switch">
                    <input
                      disabled={disabled}
                      className="form-check-input"
                      type="checkbox"
                      style={{ width: 40, height: 20, cursor: 'pointer' }}
                      role="switch"
                      id="isComplianceProduct"
                      checked={values?.productIsActive}
                      onClick={() => {
                        setFieldValue('productIsActive', !values.productIsActive);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col col-4">
          <div className="row">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Unit Weight
              </label>
              <Field disabled={disabled} type="text" name="unitWeight" className="form-control" />
            </div>
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Pallet Weight
              </label>
              <Field disabled={disabled} type="text" name="palletWeight" className="form-control" />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ErrorMessage name="unitWeight" component="p" className="text-danger" />
            </div>
            <div className="col">
              <ErrorMessage name="palletWeight" component="p" className="text-danger" />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Layers Per Pallet
              </label>
              <Field disabled={disabled} type="text" name="layersPerPallet" className="form-control" />
            </div>
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Cases Per Layer
              </label>
              <Field disabled={disabled} type="text" name="casesPerLayer" className="form-control" />
            </div>
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Cases Per Pallet
              </label>
              <Field disabled={disabled} type="text" name="casesPerPallet" className="form-control" />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ErrorMessage name="layersPerPallet" component="p" className="text-danger" />
            </div>
            <div className="col">
              <ErrorMessage name="casesPerLayer" component="p" className="text-danger" />
            </div>
            <div className="col">
              <ErrorMessage name="casesPerPallet" component="p" className="text-danger" />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Length
              </label>
              <Field disabled={disabled} type="text" name="length" className="form-control" />
            </div>
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Width
              </label>
              <Field disabled={disabled} type="text" name="width" className="form-control" />
            </div>
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Height
              </label>
              <Field disabled={disabled} type="text" name="height" className="form-control" />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ErrorMessage name="length" component="p" className="text-danger" />
            </div>
            <div className="col">
              <ErrorMessage name="width" component="p" className="text-danger" />
            </div>
            <div className="col">
              <ErrorMessage name="height" component="p" className="text-danger" />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                Weight
              </label>
              <Field disabled={disabled} type="text" name="weight" className="form-control" />
            </div>
            <ErrorMessage name="weight" component="div" className="text-danger" />
          </div>
        </div>
        <div className="col col-4">
          <div className="row">
            <div className="col" style={{ borderRight: '1px solid #EEEEEE' }}>
              <div className="row">
                <div className="col">
                  <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                    Cola TTB ID
                  </label>
                  <Field disabled={disabled} type="text" name="colaTtbId" className="form-control" />
                </div>
                <ErrorMessage name="colaTtbId" component="div" className="text-danger" />
              </div>
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                UPC Code
              </label>
              <Field disabled={disabled} type="text" name="upcCode" className="form-control" />
              <ErrorMessage name="upcCode" component="div" className="text-danger" />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                SCC Code
              </label>
              <Field disabled={disabled} type="text" name="sccCode" className="form-control" />
            </div>
            <ErrorMessage name="sccCode" component="div" className="text-danger" />
          </div>
          <div className="row">
            <div className="col">
              <label className="form-label" style={{ fontSize: '13px', marginBottom: '2px' }}>
                BDN Code
              </label>
              <Field disabled={disabled} type="text" name="bdnCode" className="form-control" />
            </div>
            <ErrorMessage name="bdnCode" component="div" className="text-danger" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductExtendedProperties;
