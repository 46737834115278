import React, { useEffect, useMemo } from 'react';
import { Outlet, Navigate, useLocation, useNavigate } from 'react-router-dom';

import Wrapper from '../components/Wrapper';
import Sidebar from '../components/sidebar/Sidebar';
import Main from '../components/Main';
import Navbar from '../components/navbar/Navbar';
import Content from '../components/Content';
import Footer from '../components/Footer';

import { useAuth } from '../providers/AuthProvider';
import { ToastContainer } from 'react-toastify';
import { UserRole } from '../lib/types';
import {
  AdminPortions,
  ForecastingUserPortions,
  PriceAdminPortions,
  ProductUserPortions,
  SalesBonusAdminPortions,
  SalesBonusPortions,
} from '../utils/siderBarPortions';

const portionsMapping = {
  [UserRole.Admin]: AdminPortions,
  [UserRole.ForecastingUser]: ForecastingUserPortions,
  [UserRole.SalesBonusAdmin]: SalesBonusAdminPortions,
  [UserRole.PricingAdmin]: PriceAdminPortions,
  [UserRole.ProductUser]: ProductUserPortions,
  [UserRole.SalesBonusUser]: SalesBonusPortions,
};

const roleToUrlsMap = {
  [UserRole.Admin]: ['#'],
  [UserRole.SalesBonusAdmin]: [
    '/sales-bonus/detail/#',
    '/sales-bonus/upload',
    '/sales-bonus/review',
    '/sales-commission/kpi-adjustment',
    '/sales-commission/kpi-adjustment/create',
    '/sales-commission/kpi-adjustment/approve',
  ],
  [UserRole.SalesBonusUser]: [
    '/sales-bonus/detail/#',
    '/sales-commission/kpi-adjustment',
    '/sales-commission/kpi-adjustment/create',
    '/sales-commission/kpi-adjustment/approve',
  ],
  [UserRole.ForecastingUser]: ['/forecast/#', '/analysis'],
  [UserRole.PricingAdmin]: ['/admin/price-calculator', '/admin/product', '/admin/fee'],
  [UserRole.ProductUser]: ['/admin/product'],
};

const getBasePath = (path: string) => {
  const basePath = path.split('?')[0]; //remove query string
  const pathSegments = basePath.split('/');
  //remove dynamic segments that start with ":"
  return pathSegments.filter((segment) => !segment.startsWith(':')).join('/');
};
// function to remove duplicates
const removeDuplicatesByHref = (arr) => {
  const seen = new Map();

  return arr.reduce((acc, item) => {
    const normalizedHref = getBasePath(item.href);

    if (seen.has(normalizedHref)) {
      const existingItem = seen.get(normalizedHref);
      if (item.children && existingItem.children) {
        const existingChildrenHrefs = new Set(existingItem.children.map((child) => getBasePath(child.href)));
        const uniqueChildren = item.children.filter((child) => !existingChildrenHrefs.has(getBasePath(child.href)));
        existingItem.children = [...existingItem.children, ...uniqueChildren];
      }
    } else {
      seen.set(normalizedHref, item);
      acc.push(item);
    }

    return acc;
  }, []);
};
const getPortionsByRoles = (roles) => {
  const portions = [];
  for (const role of roles) {
    if (portionsMapping[role]) {
      portions.push(...portionsMapping[role]);
    }
  }
  if (portions.length === 0) {
    portions.push(...SalesBonusPortions);
  }

  return removeDuplicatesByHref(portions);
};

const MainLayout: any = ({ children, isAdmin, isBonus }: { children: any; isAdmin: boolean; isBonus: boolean }) => {
  const { isLoggedIn, role, isCheckingLoggedState } = useAuth();
  const logged = localStorage.getItem('loggingState');
  if ((!isLoggedIn && !isCheckingLoggedState) || logged !== 'loggedIn') {
    return <Navigate to="/login" replace={true} />;
  }
  const location = useLocation();
  const navigate = useNavigate();
  localStorage.setItem('path', location.pathname);
  const currentPathBase = getBasePath(location.pathname);

  useEffect(() => {
    if (isLoggedIn && !isCheckingLoggedState) {
      const allowedUrls = role.reduce<string[]>((acc, role) => {
        const urls = roleToUrlsMap[role] || [];
        return acc.concat(urls);
      }, []);
      const isAllowed = allowedUrls.some((url) => {
        const allowedPathBase = getBasePath(url);
        if (allowedPathBase === '#') return true; // Admins have access to everything
        if (allowedPathBase.endsWith('/#')) {
          const baseUrl = allowedPathBase.replace('/#', '');
          return currentPathBase.startsWith(baseUrl);
        }
        return currentPathBase === allowedPathBase;
      });

      if (!isAllowed && location.pathname !== '/login') {
        navigate(allowedUrls[0] === '#' ? '/forecast/dashboard' : allowedUrls[0], { state: { adminCheck: true } });
      }
    }
  }, [currentPathBase]);

  const portions = useMemo(() => {
    if (isLoggedIn && !isCheckingLoggedState) {
      return getPortionsByRoles(role);
    }
    return [];
  }, [role, isLoggedIn, isCheckingLoggedState]);

  return (
    <React.Fragment>
      <Wrapper>
        <Sidebar items={portions} />
        <Main>
          <Navbar />
          <Content>
            <ToastContainer position="top-right" autoClose={3000} />
            {children}
            <Outlet />
          </Content>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default MainLayout;
