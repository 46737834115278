import React from 'react';
interface Option {
  label: string;
  value: number | string;
  disabled?: boolean;
}

interface ICustomSelect {
  type?: any;
  options: Option[];
  onSelectOption: (value: any, type?: any) => void;
  setPage?: (x: number) => void;
  defaultValue?: any;
}

const CustomSelect: React.FC<ICustomSelect> = ({ options, onSelectOption, type, setPage, defaultValue }) => {
  return (
    <select
      className="d-inline-block form-select form-select-sm"
      onChange={(e) => {
        onSelectOption(e.target.value, type);
        if (setPage) setPage(1);
      }}
    >
      {options.map(({ label, value, disabled = false }) => (
        <option defaultValue={defaultValue || ''} value={value} disabled={disabled} key={label + '_' + value}>
          {label}
        </option>
      ))}
    </select>
  );
};

export default CustomSelect;
