import React from 'react';

const BrandList = [
  {
    name: 'Agave de Cortes',
    CaseGrowth: 5,
    PriceGrowth: 0,
  },
  {
    name: 'Angelisco',
    CaseGrowth: 5,
    PriceGrowth: 0,
  },
  {
    name: 'Bigallet',
    CaseGrowth: 5,
    PriceGrowth: 0,
  },
];

const Management: React.FC = () => {
  return (
    <>
      <h2>Management Growth Assumptions</h2>
      <form method="POST" action="/management-growth" className="form">
        <div className="row">
          <div className="col-6">
            <div className="card flex-fill">
              <table
                id="datatable-assumptions"
                className="table table-striped table-sm table-striped my-0 dataTable no-footer"
              >
                <thead>
                  <tr style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    <th className="sorting" style={{ textAlign: 'left' }}>
                      Brand
                    </th>
                    <th className="sorting">Case Growth</th>
                    <th className="sorting">Price Growth</th>
                  </tr>
                </thead>
                <tbody>
                  {BrandList?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>
                        <div className="input-group mx-auto" style={{ width: '120px' }}>
                          <input
                            id="caseGrowth"
                            className="form-control "
                            type="text"
                            name="caseGrowth"
                            value={item.CaseGrowth}
                          />
                          <span className="input-group-text">%</span>
                        </div>
                      </td>
                      <td>
                        <div className="input-group mx-auto" style={{ width: '120px' }}>
                          <input
                            id="priceGrowth"
                            className="form-control"
                            type="text"
                            name="priceGrowth"
                            value={item.PriceGrowth}
                          />
                          <span className="input-group-text">%</span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row col-1 offset-lg-5">
          <input id="btnSubmit" className="form-control btn btn-primary" type="submit" value="Save" />
        </div>
      </form>
    </>
  );
};

export default Management;
