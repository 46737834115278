import React from 'react';

import { Dropdown } from 'react-bootstrap';

import { Settings } from 'react-feather';

import { useAuth } from '../../providers/AuthProvider';
import AuthService from '../../services/auth.service';
import { useNavigate } from 'react-router';

const NavbarUser: React.FC = () => {
  const { setIsLoggedIn, userLogo, setUserLogo, userName } = useAuth();
  const navigate = useNavigate();
  const handleSignOut = async () => {
    AuthService.LogOut();
    localStorage.setItem('loggingState', '');
    navigate('/login');
    setIsLoggedIn(false);
    setUserLogo('');
  };
  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <img src={userLogo} className="avatar img-fluid rounded-circle me-1" alt={userName} />
          <span className="text-dark">{userName}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu>
        {/*<Dropdown.Item>*/}
        {/*  <User size={18} className="align-middle me-2" />*/}
        {/*  Profile*/}
        {/*</Dropdown.Item>*/}
        {/*<Dropdown.Item>*/}
        {/*  <PieChart size={18} className="align-middle me-2" />*/}
        {/*  Analytics*/}
        {/*</Dropdown.Item>*/}
        {/*<Dropdown.Divider />*/}
        {/*<Dropdown.Item>Settings & Privacy</Dropdown.Item>*/}
        {/*<Dropdown.Item>Help</Dropdown.Item>*/}
        <Dropdown.Item onClick={handleSignOut}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
