import React from 'react';
import { Card } from 'react-bootstrap';
import { ImportType } from '../../lib/types';
import AdminService from '../../services/admin.service';
import { downloadFile } from '../../utils/downloadUtils';

export const ImportExportCard = ({
  title,
  importBody,
  exportBody,
}: {
  title: ImportType;
  importBody: any;
  exportBody: any;
}) => {
  const getTemplate = async (type: ImportType) => {
    const res = await AdminService.GetImportTemplate(type);
    downloadFile(res, `${type}-import-template.xlsx`);
  };
  return (
    <div>
      <Card className="d-flex p-4">
        <div>
          <h4 className="fw-bold m-0 pb-1 pt-1">{title}</h4>
        </div>
        <div className="my-2">
          <h5>Import</h5>
          {importBody}
          <a className="card-link" onClick={() => getTemplate(title)}>
            Upload Template
          </a>
        </div>
        <hr />
        <div>
          <h5>Export</h5>
          {exportBody}
        </div>
      </Card>
    </div>
  );
};
