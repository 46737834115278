import React from 'react';
import { useAuth } from '../providers/AuthProvider';
import { UserRole } from '../lib/types';

// props.branches = { admin: PageAdmin, manager: PageManager}
//
export const PagePermissionSwitch = (props: any) => {
  const { role } = useAuth();
  const { cases } = props;
  let Component;
  if (role?.includes(UserRole.Admin)) {
    Component = cases.admin;
  } else {
    Component = cases.manager;
  }
  if (!Component) {
    return null;
  }
  return <Component />;
};

export default PagePermissionSwitch;
