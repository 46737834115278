import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx-js-style';
import AdminService from '../services/admin.service';
import { formatCurrencyFromNum } from './formatNum';

interface IDownload {
  headers: any;
  data: any;
  fileName: string;
}

export const exportToCSV = ({ headers, data, fileName }: IDownload) => {
  const csvData = XLSX.utils.sheet_to_csv(XLSX.utils.aoa_to_sheet([headers, ...data]));
  const blob = new Blob([csvData], { type: 'text/csv' });
  saveAs(blob, `${fileName}.csv`);
};

// Utility function to convert string to ArrayBuffer
const s2ab = (s: any) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};

export const exportToExcel = ({ headers, data, fileName }: IDownload) => {
  const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);
  // set first column width and set the rest colums based on the data length
  const wscols = [{ wpx: 300 }];
  for (let i = 0; i < data.length; i++) {
    wscols[i + 1] = { wpx: 150 };
  }
  ws['!cols'] = wscols;

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  // Convert Excel workbook to a binary string
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  // Create a Blob from the Excel data
  const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
  saveAs(blob, `${fileName}.xlsx`);
};

export const downloadFile = (data: any, fileName: string) => {
  if (data.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    const blob = new Blob([data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    toast.success('No matching record.');
  }
};

export const parseVerifyXlsData = (file: any, setRows: any) => {
  const reader = new FileReader();
  reader.onload = (e: any) => {
    const data = e.target.result;
    const wb = XLSX.read(data, { type: 'binary' });
    const result = [];
    let rows = [];
    let header = [];
    let title;
    for (let j = 0; j < wb.SheetNames.length; j++) {
      const dataParse = XLSX.utils
        .sheet_to_json(wb.Sheets[wb.SheetNames[j]], { header: 1 })
        .filter((_, i) => i <= 5) as any;
      if (dataParse.length !== 0) {
        title = wb.SheetNames[j];
        header = dataParse[0] as any;
        for (let i = 1; i < dataParse.length; i++) {
          const tempData: Record<string, unknown> = header.reduce(
            (obj: { [x: string]: any }, key: string | number, index: string | number) => {
              obj[key] = dataParse[i][index] as any;
              return obj;
            },
            {},
          );
          rows.push({
            ...tempData,
            orderDate: tempData.orderDate ? new Date(tempData.orderDate as any).toLocaleDateString() : '',
          });
        }
        result.push({ title, header, rows });
      } else {
        toast.warn('no data from xlsx file');
        return;
      }
      rows = [];
    }
    setRows(result);

    return result;
  };

  reader.readAsArrayBuffer(new Blob([file]));
};

const removeDollarSign = (value) => {
  if (typeof value === 'string' && value.startsWith('$')) {
    return value.replace('$', '');
  }
  return value;
};

export const parseBonusData = (file: any, parseSalesBonusData: any, setBonusDate: any) => {
  const reader = new FileReader();
  reader.onload = async (e: any) => {
    const data = e.target.result;
    const workbook = XLSX.read(data, { type: 'binary' });
    let body: { description: any; header: any; rows: any[] }[] = [];
    const result: { title: string; body: any }[] = [];
    let rows: any[] = [];
    let header = [];
    let description: any = {
      manager: '',
      territory: '',
      total: 0,
      period: '',
    };
    workbook.SheetNames.forEach((sheetName) => {
      const dataParse = XLSX.utils
        .sheet_to_json(workbook.Sheets[sheetName], {
          header: 1,
          blankrows: false,
        })
        .map((row: any) => row.slice(0, 6)) as any;
      if (dataParse.length !== 0) {
        if (sheetName == 'Commission') {
          const startDate = XLSX.SSF.format('mm/dd/yyyy', dataParse[0][0]);
          const endDate = XLSX.SSF.format('mm/dd/yyyy', dataParse[1][0]);
          setBonusDate({ startDate, endDate });
          const index = dataParse.findIndex((subArray) => subArray[0] === 'Goal Plan');
          const realData = dataParse.slice(index - 1);
          header = realData[1].filter(Boolean) as any;
          header.push('Compensation');
          for (let i = 0; i < realData.length; i++) {
            const tempBonus: any = header.reduce(
              (obj: { [x: string]: any }, key: string | number, index: string | number) => {
                obj[key] = realData[i][index] as any;
                return obj;
              },
              {},
            );
            if (tempBonus['Goal Plan']) {
              rows.push({
                ...tempBonus,
                '% Achieved': tempBonus['% Achieved'] ? (tempBonus['% Achieved'] * 100).toFixed(1) : 0,
                Compensation: formatCurrencyFromNum(
                  Number(
                    tempBonus['Compensation'] && tempBonus['Compensation'] !== 'Compensation'
                      ? removeDollarSign(tempBonus['Compensation'])
                      : 0,
                  ),
                ),
              });
            }
            if (realData[i].some((str) => typeof str === 'string' && str.toLowerCase().includes('total:'))) {
              description = {
                manager: rows[0][header[0]],
                territory: rows[0][header[1]],
                totalPotential: rows[0][header[header.indexOf('Compensation')]],
                totalEarned: rows[rows.length - 1][header[header.indexOf('Compensation')]],
              } as any;
              rows = rows.slice(2, rows.length);
              body.push({ description, header, rows });
              rows = [];
            }
          }
          result.push({ title: 'Market Managers', body });
          body = [];
        } else if (sheetName == 'RMs') {
          const header = ['Market Manager', '% Total Contribution', '% Earned Contribution', 'Contribution'];
          for (let i = 1; i < dataParse.length; i++) {
            const tmpRMs: any = header.reduce((obj: { [x: string]: any }, key: string | number, index: number) => {
              obj[key] = dataParse[i][index + 1] as any;
              return obj;
            }, {});
            rows.push({
              ...tmpRMs,
              '% Total Contribution': tmpRMs['% Total Contribution']
                ? (tmpRMs['% Total Contribution'] * 100).toFixed(2)
                : 0,
              '% Earned Contribution': tmpRMs['% Earned Contribution']
                ? (tmpRMs['% Earned Contribution'] * 100).toFixed(2)
                : 0,
              Contribution: formatCurrencyFromNum(Number(tmpRMs['Contribution'] ? tmpRMs['Contribution'] : 0)),
            });
            description = {
              manager: dataParse[i][0] ?? description.manager,
            } as any;
            if (dataParse[i].some((str) => typeof str === 'string' && str.toLowerCase().includes('total'))) {
              description = {
                ...description,
                totalPotential: rows[0][header[3]],
                totalEarned: rows[rows.length - 1][header[3]],
              };
              rows.shift();
              body.push({ description, header, rows });
              rows = [];
            }
          }
          result.push({ title: 'Regional Managers', body });
          body = [];
        } else if (sheetName == 'Directors') {
          const header = ['Market Manager', '% Total Contribution', '% Earned Contribution', 'Contribution'];
          for (let i = 1; i < dataParse.length; i++) {
            const tmpDirectors: any = header.reduce(
              (obj: { [x: string]: any }, key: string | number, index: number) => {
                obj[key] = dataParse[i][index + 1] as any;
                return obj;
              },
              {},
            );
            rows.push({
              ...tmpDirectors,
              '% Total Contribution': tmpDirectors['% Total Contribution']
                ? (tmpDirectors['% Total Contribution'] * 100).toFixed(2)
                : 0,
              '% Earned Contribution': tmpDirectors['% Earned Contribution']
                ? (tmpDirectors['% Earned Contribution'] * 100).toFixed(2)
                : 0,
              Contribution: formatCurrencyFromNum(
                Number(tmpDirectors['Contribution'] ? tmpDirectors['Contribution'] : 0),
              ),
            });
            description = {
              manager: dataParse[i][0] ?? description.manager,
            } as any;
            if (dataParse[i].some((str) => typeof str === 'string' && str.toLowerCase().includes('total'))) {
              description = {
                ...description,
                totalPotential: rows[0][header[3]],
                totalEarned: rows[rows.length - 1][header[3]],
              };
              rows.shift();
              body.push({ description, header, rows });
              rows = [];
            }
          }
          result.push({ title: 'Director of Sales', body });
          body = [];
        }
      } else {
        toast.warn('no data from xlsx file');
        return;
      }
      rows = [];
    });
    try {
      for (const val of result) {
        for (const body of val.body) {
          if (body.description.manager && typeof body.description.manager === 'string') {
            const { data } = await AdminService.ValidaterUser(body.description.manager);
            body.description.userId = data.userId;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    parseSalesBonusData(result);
  };

  reader.readAsArrayBuffer(new Blob([file]));
};
