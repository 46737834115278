import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { formatNum } from '../../utils/formatNum';
import { darkenRgbaColor } from '../../utils/calculation';

const LineChart = ({ data, type }: { data: any; type: string }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const [chartInstance, setChartInstance] = useState<Chart | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstance) {
        chartInstance.destroy();
      }
      const originalBorderColors: any = [];

      // Initialize originalBorderColors array with initial border colors
      data.forEach((dataset: any) => {
        originalBorderColors.push(dataset.borderColor);
      });

      const ctx: any = chartRef.current.getContext('2d');
      const newChartInstance = new Chart(ctx, {
        type: 'line',
        data: {
          labels: ['Q1', 'Q2', 'Q3', 'Q4'],
          datasets: data,
        },
        options: {
          scales: {
            y: {
              ticks: {
                callback: function (value) {
                  return type === 'cases' ? formatNum(Number(value), 1, 1) : `$${formatNum(Number(value))}`;
                },
              },
            },
          },
          aspectRatio: 1.5,
          plugins: {
            tooltip: {
              enabled: true,
            },
            legend: {
              display: true,
              labels: {
                generateLabels: function (chart: any) {
                  const original = Chart.defaults.plugins.legend.labels.generateLabels;
                  const labels = original.call(this, chart);
                  if (chart.legend && chart.legend.legendItems) {
                    labels.forEach((label: any) => {
                      label.fontColor = chart.legend.legendItems[label.datasetIndex]?.fontColor || '#666';
                      label.lineWidth = chart.legend.legendItems[label.datasetIndex]?.lineWidth || 1;
                    });
                  }
                  return labels;
                },
              },
              onHover: function (_event, legendItem) {
                const index = legendItem.datasetIndex;
                const chart = newChartInstance;

                if (index !== undefined && chart.legend && chart.legend.legendItems) {
                  chart.legend.legendItems.forEach((item, i) => {
                    if (i === index) {
                      item.fontColor = 'black';
                    } else {
                      item.fontColor = '#666'; // Default color for other items
                    }
                  });
                  chart.data.datasets.forEach((dataset, i) => {
                    if (i === index) {
                      dataset.borderColor = darkenRgbaColor(dataset.borderColor, 30);
                    } else {
                      dataset.borderColor = originalBorderColors[i];
                    }
                  });

                  chart.update();
                }
              },
            },
          },
          hover: {
            mode: 'dataset',
            intersect: false,
          },
          onHover: (event, elements) => {
            const chart = newChartInstance;
            if (chart.legend && chart.legend.legendItems) {
              if (elements.length) {
                const datasetIndex = elements[0].datasetIndex;
                chart.data.datasets.forEach((dataset, i) => {
                  if (i === datasetIndex) {
                    dataset.borderColor = darkenRgbaColor(dataset.borderColor, 30);
                  } else {
                    dataset.borderColor = originalBorderColors[i];
                  }
                });

                chart.legend.legendItems.forEach((legendItem, index) => {
                  if (index === datasetIndex) {
                    legendItem.fontColor = 'black';
                  } else {
                    legendItem.fontColor = '#666'; // Default color
                  }
                });
              } else {
                chart.legend.legendItems.forEach((legendItem) => {
                  legendItem.fontColor = '#666'; // Default color
                });
              }
              chart.update();
            }
          },
        },
      });
      setChartInstance(newChartInstance);
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default LineChart;
