import Axios from '../lib/axios';

import { TaskRequest } from '../lib/types';

const GetTask = (request: TaskRequest) => {
  return Axios.post(`${process.env.REACT_APP_SERVER_ROOT || ''}/task/${request.taskId}`, {});
};

const TaskService = {
  GetTask,
};

export default TaskService;
