import React, { type PropsWithChildren, forwardRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Badge, Collapse } from 'react-bootstrap';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface ICustomRouterLink extends PropsWithChildren {
  to: string;
  activeclassname: string;
  className: string;
  ref: any;
}

export interface ISidebarNavListItem extends PropsWithChildren {
  key: string;
  title: string;
  href: string;
  icon?: IconDefinition;
  badge?: number;
  open?: boolean;
}

const CustomRouterLink = forwardRef<HTMLAnchorElement, ICustomRouterLink>((props) => (
  <React.Fragment>
    <NavLink {...props} />
  </React.Fragment>
));

const SidebarNavListItem: React.FC<ISidebarNavListItem> = (props) => {
  const { title, href, children, icon: Icon, badge, open: openProp = false } = props;

  const [open, setOpen] = React.useState(openProp);

  useEffect(() => {
    const refreshState = localStorage.getItem(`openSidebarState${title}`);
    if (refreshState !== '') {
      setOpen(refreshState === 'true' ? true : false);
    }
  }, []);

  const handleToggle = () => {
    setOpen((state) => {
      localStorage.setItem(`openSidebarState${title}`, `${!state}`);
      return !state;
    });
  };

  if (children) {
    return (
      <li className={`sidebar-item ${open ? 'active' : ''}`}>
        <a
          className={`sidebar-link ${open ? '' : 'collapsed'}`}
          data-bs-toggle="collapse"
          aria-expanded={open ? 'true' : 'false'}
          onClick={handleToggle}
        >
          {Icon && <FontAwesomeIcon className="feather align-middle" icon={Icon} />}{' '}
          <span className="align-middle">{title}</span>
          {badge && (
            <Badge className="badge-sidebar-primary" bg="">
              {badge}
            </Badge>
          )}
          {open ? <div /> : <div />}
        </a>
        <Collapse in={open}>
          <ul className="sidebar-dropdown list-unstyled">{children}</ul>
        </Collapse>
      </li>
    );
  }

  return (
    <li className="sidebar-item">
      <CustomRouterLink to={href} activeclassname="active" className="sidebar-link">
        {Icon && <FontAwesomeIcon className="feather align-middle" icon={Icon} />}{' '}
        <span className="align-middle">{title}</span>
        {badge && (
          <Badge className="badge-sidebar-primary" bg="">
            {badge}
          </Badge>
        )}
      </CustomRouterLink>
    </li>
  );
};

export default SidebarNavListItem;
