import React, { useEffect, useState } from 'react';
import CustomTable from '../common/Table';
import { Description } from '../../pages/SalesBonus/Review';
import AdminService from '../../services/admin.service';
import { hideLoading, showLoading } from '../../lib/uiService';
import CustomAlert from '../common/Alert';

export const RollupManagerItem = ({
  description,
  columns,
  rows,
  showName = true,
  checkUser = true,
}: {
  description: Description | undefined;
  columns: any;
  rows: any;
  showName?: boolean;
  checkUser?: boolean;
}) => {
  const [isBonus, setViewBonus] = useState<any>(false);
  const [invalidRows, setInValidRows] = useState<number[]>([]);
  useEffect(() => {
    const invalidUsers: number[] = [];
    showLoading();
    if (checkUser && description && description?.userId) {
      rows.forEach(async (row: any, i: number) => {
        const { data } = await AdminService.ValidaterUser(row['Market Manager']);
        if (!data.userId && i !== rows.length - 1) {
          invalidUsers.push(i);
        }
      });
      setInValidRows(invalidUsers);
    }
    hideLoading();
  }, []);
  return (
    <div className="d-flex w-100">
      <div className="col-3 p-2">
        {showName && <h5>{description ? description?.manager : ''}</h5>}
        <h5>Total Potential: {isBonus && description ? description?.totalPotential : '********'}</h5>
        <h5>
          <p>Total Earned This Period: {isBonus && description ? description?.totalEarned : '********'}</p>
        </h5>
        <CustomAlert
          show={!description?.userId}
          variant="outline-danger"
          message={`User ${description ? description?.manager : ''} not located in system. Check spelling and try again.`}
        />
      </div>
      <div className="col-9">
        <CustomTable
          columns={columns}
          data={rows}
          invalidUser={!description?.userId}
          viewData={isBonus}
          setViewData={setViewBonus}
          invalidRows={invalidRows}
        />
      </div>
    </div>
  );
};
