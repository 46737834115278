import { useState, useEffect } from 'react';
import { useForecast } from '../providers/ForecastProvider';

const useForecastModal = () => {
  const { forecastId, territoryId, hasForecasts, forecastCalendarId, showPeriods } = useForecast();
  const [showForecastModal, setShowForecastModal] = useState(false);

  useEffect(() => {
    // wait a second for the forecast hook to save data from localstorage if it exists
    const timer = setTimeout(() => {
      if ((!forecastId || !territoryId || (showPeriods && !forecastCalendarId)) && hasForecasts) {
        setShowForecastModal(true);
      }
    }, 1000);
    // clean up function
    return () => {
      clearTimeout(timer);
    };
  }, [forecastId, territoryId, forecastCalendarId, showPeriods, hasForecasts]);

  return { showForecastModal, setShowForecastModal };
};

export default useForecastModal;
