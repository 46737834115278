import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { useSidebar } from '../../providers/SidebarProvider';
import SidebarFooter from './SidebarFooter';
import { ReactComponent as BackbarLogo } from '../../assets/img/backbar-logo-white.svg';
import SidebarNavList from './SidebarNavList';
import { IDashboardItem } from './dashboardItems';

interface ISidebar {
  items: IDashboardItem[];
  showFooter?: boolean;
}
const Sidebar: React.FC<ISidebar> = ({ items, showFooter = true }) => {
  const { isOpen } = useSidebar();

  return (
    <nav className={`sidebar ${!isOpen ? 'collapsed' : ''}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/forecast">
            <BackbarLogo />
          </a>

          <SidebarNavList items={items} />
          {!!showFooter && <SidebarFooter />}
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
