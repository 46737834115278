import React from 'react';

interface PaginationProps {
  currentStartPage: number;
  totalPages: number;
  startPage: number;
  pageSize: number;
  count: number;
  page: number;
  setPage: (page: number) => void;
  setData: (data: any) => void;
  initialData: any[];
  setCurrentStartPage?: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  // startPage,
  currentStartPage,
  pageSize,
  count,
  page,
  setPage,
  setData,
  initialData,
}) => {
  const renderPaginationElements = () => {
    const elements = [];
    const pages = totalPages === Number.MAX_SAFE_INTEGER ? 10 : totalPages;
    const pagesToShow = Math.min(5, pages);
    const startPage = Math.min(currentStartPage, totalPages - pagesToShow + 1);

    for (let i = 0; i < pagesToShow; i++) {
      const pageNumber = startPage + i;
      const indexOfFirstItem = (pageNumber - 1) * pageSize;
      const indexOfLastItem = Math.min(pageNumber * pageSize, count);

      elements.push(
        <li
          key={i}
          className={
            'page-item cursor-pointer' +
            ((pageNumber === page ? ' active' : '') + (startPage * pageSize < count ? '' : ' disabled'))
          }
        >
          <div
            className="page-link"
            onClick={() => {
              setPage(pageNumber);
              setData(initialData.slice(indexOfFirstItem, indexOfLastItem));
            }}
          >
            {pageNumber}
          </div>
        </li>,
      );
    }
    return elements;
  };

  return <>{renderPaginationElements()}</>;
};

export default Pagination;
