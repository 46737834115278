import React from 'react';
import { formatNegativeNumFromStr, formatNum, formatNumOneDecimal } from '../../utils/formatNum';
import { validateOnChangeInput } from '../../utils/validation';
import StageButton from '../../components/projection/StageButton';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import CaseInput from '../../components/common/CaseInput';
import GrowthInput from '../../components/common/GrowthInput';

export const useProductProjectionsTableColumns = (handlers: any) => {
  const { handleChange, handleRemove, handleSaveItem, isForecastClosed } = handlers;
  return React.useMemo<any>(() => {
    return [
      {
        Header: 'Name',
        accessor: 'productName',
        canSort: false,
        Cell: ({ row }: { row: any }) => {
          return row.original?.forecastProduct?.product?.description;
        },
        Footer: () => {
          return <strong>Grand Total</strong>;
        },
      },
      {
        Header: 'Forecast Cases',
        accessor: 'forecastQuantity',
        Cell: ({ row }: { row: any }) => {
          const value = row.original?.forecastProduct?.quantity || 0;
          return `${formatNumOneDecimal(value)}`;
        },
        Footer: ({ data }: any) => {
          const forecastQuantity = data.reduce(
            (acc: number, row: any) => acc + Number(row.forecastProduct.quantity),
            0,
          );
          return <strong>{formatNumOneDecimal(forecastQuantity)}</strong>;
        },
      },
      {
        Header: 'Projected Cases',
        accessor: 'quantity',
        canSort: false,
        Cell: ({ row }: { data: any; row: any }) => {
          const rowData = row.original;
          return isForecastClosed ? (
            `${formatNumOneDecimal(rowData.quantity)}`
          ) : (
            <td>
              <div className="d-flex">
                <CaseInput
                  style={{ width: '45px' }}
                  value={rowData.quantity}
                  onChange={(e: any) => {
                    validateOnChangeInput(e, rowData.forecastProductProjectionId, 'quantity', handleChange);
                  }}
                />
              </div>
            </td>
          );
        },
        Footer: ({ data }: any) => {
          const quantity = data.reduce((acc: number, row: any) => acc + Number(row.quantity), 0);
          return <strong>{formatNumOneDecimal(quantity)}</strong>;
        },
      },
      {
        Header: 'Net Difference',
        accessor: 'diffQuantity',
        canSort: false,
        Cell: ({ row }: { row: any }) => {
          const diff = row.original?.quantity - row.original?.forecastProduct?.quantity;
          return `${formatNum(diff)}`;
        },
        Footer: ({ data }: any) => {
          const diffQuantity = data.reduce(
            (acc: number, row: any) => acc + (Number(row.quantity) - Number(row.forecastProduct.quantity)),
            0,
          );
          return <strong>{formatNum(diffQuantity)}</strong>;
        },
      },
      {
        Header: 'Case Growth',
        canSort: false,
        Cell: ({ row }: { data: any; row: any }) => {
          const rowData = row.original;
          return isForecastClosed ? (
            `${formatNumOneDecimal(rowData.salesGrowth)}%`
          ) : (
            <td>
              <div className="d-flex">
                <GrowthInput
                  style={{ width: '45px', paddingRight: 12 }}
                  value={rowData.salesGrowth}
                  onChange={(e: any) => {
                    validateOnChangeInput(e, rowData.forecastProductProjectionId, 'salesGrowth', handleChange);
                  }}
                />
                <span className="" style={{ transform: 'translate(-14px, 3px)' }}>
                  %
                </span>
              </div>
            </td>
          );
        },
        Footer: ({ data }: any) => {
          const forecastedQuantity =
            data.reduce((acc: number, row: any) => {
              return acc + formatNegativeNumFromStr(row.forecastProduct.quantity);
            }, 0) || 0;
          const projectedQuantity =
            data.reduce((acc: number, row: any) => {
              return acc + formatNegativeNumFromStr(row.quantity);
            }, 0) || 0;
          const salesGrowth = ((projectedQuantity - forecastedQuantity) / forecastedQuantity) * 100;
          return <strong>{formatNum(salesGrowth)}%</strong>;
        },
      },
      {
        Header: 'Price Growth',
        canSort: false,
        Cell: ({ row }: { row: any }) => {
          const rowData = row.original;
          return isForecastClosed ? (
            `${formatNumOneDecimal(rowData.priceGrowth)}%`
          ) : (
            <td>
              <div className="d-flex">
                <GrowthInput
                  style={{ width: '45px', paddingRight: 12 }}
                  value={rowData.priceGrowth}
                  onChange={(e: any) => {
                    validateOnChangeInput(e, rowData.forecastProductProjectionId, 'priceGrowth', handleChange);
                  }}
                />
                <span className="" style={{ transform: 'translate(-14px, 3px)' }}>
                  %
                </span>
              </div>
            </td>
          );
        },
        Footer: ({ data }: any) => {
          const priceGrowth =
            (data.reduce((acc: number, row: any) => {
              return acc + formatNegativeNumFromStr(row.priceGrowth);
            }, 0) || 0) / (data.length || 1);
          return <strong>{formatNum(priceGrowth)}%</strong>;
        },
      },
      {
        Header: 'Forecasted Revenue',
        accessor: 'forecastRevenue',
        canSort: false,
        Cell: ({ row }: { data: any; row: any }) => {
          const value = row.original?.forecastProduct?.revenue || 0;
          return <td className="cursor-pointer">${formatNum(value)}</td>;
        },
        Footer: ({ data }: any) => {
          const forecastRevenue = data.reduce((acc: number, row: any) => acc + Number(row.forecastProduct.revenue), 0);
          return <strong>${formatNum(forecastRevenue)}</strong>;
        },
      },
      {
        id: 'projectedRevenue',
        Header: 'Projected Revenue',
        canSort: false,
        accessor: 'revenue',
        Cell: ({ value }: { value: any }) => {
          return <td className="cursor-pointer">${formatNum(value)}</td>;
        },
        Footer: ({ data }: any) => {
          const revenue = data.reduce((acc: number, row: any) => acc + Number(row.revenue), 0);
          return <strong>${formatNum(revenue)}</strong>;
        },
      },
      {
        Header: 'Net Difference',
        id: 'diffRevenue',
        accessor: 'diffRevenue',
        canSort: false,
        Cell: ({ row }: { row: any }) => {
          const diff = row.original?.revenue - row.original?.forecastProduct?.revenue;
          return `$${formatNum(diff)}`;
        },
        Footer: ({ data }: any) => {
          const diffRevenue = data.reduce(
            (acc: number, row: any) => acc + (Number(row.revenue) - Number(row.forecastProduct.revenue)),
            0,
          );
          return <strong>${formatNum(diffRevenue)}</strong>;
        },
      },
      {
        Header: '',
        id: 'actions',
        canSort: false,
        Cell: ({ row }: { row: any }) => {
          return (
            <div className="d-flex justify-content-center">
              <button
                disabled={isForecastClosed}
                className="btn btn-sm btn-danger float-end"
                type="button"
                onClick={() => {
                  handleRemove(row.original.forecastProductProjectionId);
                }}
              >
                <FontAwesomeIcon icon={faCircleXmark} />
              </button>
              <StageButton
                disabled={isForecastClosed}
                changedItem={row.original}
                handleSave={() => {
                  handleSaveItem([row.original]);
                }}
                title={<FontAwesomeIcon icon={faInfoCircle} />}
              />
            </div>
          );
        },
      },
    ];
  }, [isForecastClosed]);
};
