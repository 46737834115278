import React, { useEffect, useState } from 'react';
import { IForecastToUpdate, IPageToShow } from '.';
import { useForecast } from '../../providers/ForecastProvider';
import ForecastService from '../../services/forecast.service';
import useErrorHandling from '../../hooks/useErrorHandling';
import useBackgroundTaskWatcher from '../../hooks/useBackgroundTaskWatcher';
import { hideLoading, showLoading } from '../../lib/uiService';
import AdminService from '../../services/admin.service';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCircleXmark, faCalculator } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../components/Pagination';
import { toast } from 'react-toastify';
import { ForecastStatusType } from '../../lib/types';
import TaskService from '../../services/task.service';

interface ICurrentForecasts {
  setForecastToUpdate: (x: IForecastToUpdate) => void;
  setPageToShow: (x: IPageToShow) => void;
  fetchAgain: boolean;
  setFetchAgain: (x: boolean) => void;
}

const CurrentForecasts: React.FC<ICurrentForecasts> = ({
  setForecastToUpdate,
  setPageToShow,
  fetchAgain,
  setFetchAgain,
}) => {
  const { setForecastUpdated } = useForecast();
  const { setError } = useErrorHandling();
  const [page, setPage] = useState<number>(1);
  const [startPage, setStartPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(Number.MAX_SAFE_INTEGER);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentStartPage, setCurrentStartPage] = useState(1);
  const [forecasts, setForecasts] = useState<IForecastToUpdate[]>([]);
  const [count, setCount] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const [forecastName, setForecastName] = useState('');

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setStartPage(1);
  }, [pageSize]);

  useEffect(() => {
    ForecastService.GetForecasts({ page, pageSize, search, allForecast: true })
      .then(({ data }) => {
        setForecasts(data.forecasts);
        setCount(data?.count);
        setTotalPages(Math.ceil(data?.count / pageSize));
        setFetchAgain(false);
      })
      .catch((error: any) => {
        console.log(error);
        setError({ status: error?.response?.status || 500 });
      });
  }, [page, pageSize, search, fetchAgain]);

  const handleDelete = async (id: string) => {
    setPageToShow('Create');
    showLoading();
    try {
      await AdminService.DeleteForecast(id);
      hideLoading();
      setFetchAgain(true);
      setForecastUpdated(true);
      const currentDate = new Date();
      const lookbackStartDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 0);
      const lookbackEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
      setForecastToUpdate({
        forecastId: '',
        name: '',
        methodAndType: 'Linear Weighted',
        startDate: `${new Date().getFullYear() + 1}-01-01`,
        endDate: `${new Date().getFullYear() + 1}-12-31`,
        lookbackPeriod: '1',
        lookbackEndDate: lookbackEndDate.toISOString().split('T')[0],
        lookbackStartDate: lookbackStartDate.toISOString().split('T')[0],
        forecastPeriod: '1',
        calculationPeriod: 'Quarterly',
        periods: {},
        status: ForecastStatusType.Pending,
        weights: [],
        forecastCalendars: [],
      });
      toast.success('Successfully Deleted Forecast');
    } catch (error) {
      hideLoading();
      console.log(error);
    }
  };

  const handleDuplicate = async (forecastId: string, name: string) => {
    showLoading();
    try {
      const newForecastName = `${name}_copy_${new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })}`;
      setForecastName(newForecastName);
      await AdminService.DuplicateForecast(forecastId, newForecastName);
      toggle();

      hideLoading();
      setFetchAgain(true);
      setForecastUpdated(true);
    } catch (error) {
      hideLoading();
      console.log(error);
    }
  };

  const watchBackgroundTask = useBackgroundTaskWatcher({
    onCheck: async (taskId) => {
      const data = await TaskService.GetTask({ taskId });
      const result = data?.data?.task?.result;
      if (result === 'Success') {
        setFetchAgain(true);
        return true;
      }
    },
  });

  const handleRecalculate = async (forecastId: string, name: string) => {
    try {
      // optimistic update forecast status
      const updatedForecasts = forecasts.map((forecast: IForecastToUpdate) => {
        if (forecast.forecastId === forecastId) {
          forecast.status = ForecastStatusType.Pending;
        }
        return forecast;
      });
      setForecasts(updatedForecasts);
      const data = await AdminService.RecalcualteForecast(forecastId);
      const taskId: string = data?.data?.taskId;
      setFetchAgain(true);
      if (taskId) {
        watchBackgroundTask(taskId);
      }
      setForecastUpdated(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="card shadow">
      <div className="card-header py-3">
        <p className="text-primary m-0 fw-bold" style={{ fontSize: '1.25rem' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="1em"
            height="1em"
            fill="currentColor"
            className="me-2"
            style={{ fontSize: '30px' }}
          >
            <path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"></path>
          </svg>
          Current Forecasts
        </p>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6 text-nowrap">
            <div id="dataTable_length-1" className="dataTables_length" aria-controls="dataTable">
              <label className="form-label">
                Show&nbsp;
                <select
                  className="d-inline-block form-select form-select-sm"
                  onChange={(e) => {
                    setFetchAgain(true);
                    setPageSize(Number(e.target.value));
                  }}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
                &nbsp;
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="text-md-end dataTables_filter" id="dataTable_filter-1">
              <label className="form-label">
                <input
                  type="search"
                  className="form-control form-control-sm"
                  aria-controls="dataTable"
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="table-responsive table mt-2" id="dataTable-1" role="grid" aria-describedby="dataTable_info">
          <table className="table table-hover my-0" id="dataTable">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Forecast Period</th>
                <th>Lookback</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {forecasts?.map((forecast, index) => {
                const startDate = new Date(forecast.startDate).toISOString().substring(0, 10);
                const endDate = new Date(forecast.endDate).toISOString().substring(0, 10);
                const lookbackStartDate = new Date(forecast.lookbackStartDate).toISOString().substring(0, 10);
                const lookbackEndDate = new Date(forecast.lookbackEndDate).toISOString().substring(0, 10);

                const lookBackPeriod = `${lookbackStartDate} - ${lookbackEndDate}`;
                return (
                  <tr key={index}>
                    <td>
                      <u
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          const weights = forecast.forecastCalendars
                            .map((calendar) => {
                              if (calendar.periodType === 'Quarter' || calendar.periodType === 'Month') {
                                return calendar?.forecastWeight?.weight || 0;
                              }
                              return null;
                            })
                            .filter((weight) => weight !== null);
                          const updateForecast = {
                            ...forecast,
                            // set default to custom
                            weights: weights,
                            forecastPeriod: '5',
                            lookbackPeriod: '5',
                            calculationPeriod: Number(forecast.calculationPeriod) === 4 ? 'Quarterly' : 'Monthly',
                          };
                          setForecastToUpdate(updateForecast);
                          setPageToShow('Update');
                        }}
                        className={forecast.status === 'Pending' ? 'fst-italic text-secondary' : ''}
                      >
                        {forecast.name}
                      </u>
                    </td>
                    <td className={forecast.status === 'Pending' ? 'fst-italic text-secondary' : ''}>
                      {forecast.methodAndType}
                    </td>
                    <td>{`${startDate} - ${endDate}`}</td>
                    <td>{lookBackPeriod}</td>
                    <td>{forecast.status}</td>
                    <td>
                      <button
                        className="btn btn-primary me-2"
                        type="button"
                        style={{ textAlign: 'right' }}
                        onClick={() => handleRecalculate(forecast.forecastId, forecast.name)}
                        disabled={forecast.status !== 'Created'}
                      >
                        <FontAwesomeIcon icon={faCalculator} />
                      </button>
                      <button
                        className="btn btn-primary me-2"
                        type="button"
                        style={{ textAlign: 'right' }}
                        onClick={() => handleDuplicate(forecast.forecastId, forecast.name)}
                        disabled={forecast.status === 'Pending'}
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </button>
                      <button
                        className="btn btn-danger"
                        type="button"
                        style={{ textAlign: 'right' }}
                        onClick={() => handleDelete(forecast.forecastId)}
                        disabled={forecast.status === 'Pending'}
                      >
                        <FontAwesomeIcon icon={faCircleXmark} />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="row">
          <div className="col-md-6 align-self-center">
            <p id="dataTable_info-3" className="dataTables_info" role="status" aria-live="polite">
              Showing {pageSize > count ? count : pageSize} of {count}
            </p>
          </div>
          <div className="col-md-6">
            <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
              <ul className="pagination">
                <li className={'page-item cursor-pointer' + (page - 1 > 0 ? '' : ' disabled')}>
                  <div
                    className="page-link"
                    aria-label="Previous"
                    onClick={() => {
                      if (page > 1) {
                        setPage(page - 1);
                        if (page === currentStartPage) {
                          setCurrentStartPage(Math.max(currentStartPage - 1, 1));
                        }
                        setFetchAgain(true);
                      }
                    }}
                  >
                    <span aria-hidden="true">«</span>
                  </div>
                </li>
                <Pagination
                  currentStartPage={currentStartPage}
                  totalPages={totalPages}
                  startPage={startPage}
                  pageSize={pageSize}
                  count={count}
                  page={page}
                  setPage={setPage}
                  setData={setForecasts}
                  initialData={forecasts}
                  setCurrentStartPage={setCurrentStartPage}
                />
                <li className={'page-item cursor-pointer' + (page + 1 <= totalPages ? '' : ' disabled')}>
                  <div
                    className="page-link"
                    aria-label="Next"
                    onClick={() => {
                      setPage(page + 1);
                      setCurrentStartPage(currentStartPage + 1);
                      setFetchAgain(true);
                    }}
                  >
                    <span aria-hidden="true">»</span>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <Modal show={isOpen} onHide={() => toggle()} centered>
        <Modal.Header closeButton>Copy completed</Modal.Header>
        <Modal.Body className="text-center m-3">
          <p className="mb-0">
            Copy <b>{forecastName}</b> completed!
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => toggle()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CurrentForecasts;
