import * as Yup from 'yup';

export const ProductSchema = Yup.object().shape({
  productSpecificName: Yup.string().required('A specific name must be inputed for the product.'),
  federalExciseTax: Yup.number()
    .nullable()
    .notRequired()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
    .min(0, 'Value must be greater than or equal to 0')
    .nullable(),
  quantity: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'Quantity must be greater than 0')
    .required('Quantity is required'),
  volume: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'Volume must be greater than 0')
    .required('Volume is required'),
  cogs: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'COGs must be greater than 0')
    .required('COGs is required'),
  margin: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'Margin must be greater than 0')
    .required('Margin is required'),
  markup: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'Markup must be greater than 0')
    .required('Markup is required'),
  freight: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'Freight must be greater than 0')
    .required('Freight is required'),
  insurance: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'Insurance must be greater than 0')
    .required('Insurance is required'),
  landedCogs: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'Landed COGs must be greater than 0')
    .required('Landed COGs is required'),
  warehouseFees: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'Warehouse Fees must be greater than 0')
    .required('Warehouse Fees is required'),
  psiFees: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'PSI Fees must be greater than 0')
    .required('PSI Fees is required'),
  natlFob: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, "Natl'l FOB must be greater than 0")
    .required("Natl'l FOB is required"),
  preFeeFOB: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'Pre Fees FOB must be greater than 0')
    .required('Pre Fees FOB is required'),
  distributorAllowance: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'Distributor Allowance must be greater than 0'),
  distributorMargin: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'Distributor Margin must be greater than 0')
    .required('Distributor Margin is required'),
  distributorCasePrice: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'Distributor Case Price must be greater than 0')
    .required('Distributor Case Price is required'),
  distributorBottlePrice: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'Distributor Bottle Price must be greater than 0')
    .required('Distributor Case Bottle is required'),
  retailMarkup: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'Retail Markup must be greater than 0')
    .required('Retail Markup is required'),
  retailPrice: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'Retail Price must be greater than 0')
    .required('Retail Price is required'),
  suggestedRetailPrice: Yup.number()
    .typeError('Must be a number')
    .moreThan(0, 'SRP must be greater than 0')
    .required('SRP is required'),
  abv: Yup.number().typeError('Must be a number').moreThan(0, 'ABV must be greater than 0').required('ABV is required'),
  unitWeight: Yup.number().typeError('Unit Weight must be a number').nullable().notRequired(),
  palletWeight: Yup.number().typeError('Pallet Weight must be a number').nullable().notRequired(),
  layersPerPallet: Yup.number().typeError('Layers Per Pallet must be a number').nullable().notRequired(),
  casesPerLayer: Yup.number().typeError('Cases Per Layer must be a number').nullable().notRequired(),
  casesPerPallet: Yup.number().typeError('Cases Per Pallet must be a number').nullable().notRequired(),
  length: Yup.number().typeError('Length must be a number').nullable().notRequired(),
  tier1Price: Yup.number().typeError('Tier 1 must be a number').nullable().notRequired(),
  tier2Price: Yup.number().typeError('Tier 2 must be a number').nullable().notRequired(),
  width: Yup.number().typeError('Width must be a number').nullable().notRequired(),
  height: Yup.number().typeError('Height must be a number').nullable().notRequired(),
  weight: Yup.number().typeError('Weight must be a number').nullable().notRequired(),
});
