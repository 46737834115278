import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { exportToCSV, exportToExcel } from '../utils/downloadUtils';
import AdminService from '../services/admin.service';
import { useForecast } from '../providers/ForecastProvider';
import { hideLoading, showLoading } from '../lib/uiService';

const DownloadSelector = ({
  row,
  prepareDataFunction,
  downloadColumnHeaders,
  fileName,
  hasFullForecast,
}: {
  row: any;
  prepareDataFunction: () => void;
  downloadColumnHeaders: any;
  fileName: string;
  hasFullForecast?: boolean;
}) => {
  const { forecastId, forecastCalendarId } = useForecast();
  const onDownloadHandle = (type: string) => {
    prepareDataFunction();
    if (type === 'csv') {
      exportToCSV({
        headers: downloadColumnHeaders,
        data: row,
        fileName,
      });
    } else if (type === 'excel') {
      exportToExcel({
        headers: downloadColumnHeaders,
        data: row,
        fileName,
      });
    }
  };
  return (
    <Dropdown>
      <Dropdown.Toggle variant="dark">Download </Dropdown.Toggle>
      <Dropdown.Menu>
        {hasFullForecast && (
          <Dropdown.Item
            onClick={() => {
              showLoading();
              AdminService.GetFullForecastReport({ forecastId, forecastCalendarId })
                .then(async (res: any) => {
                  const url = window.URL.createObjectURL(new Blob([res]));
                  const a = document.createElement('a');
                  a.href = url;
                  a.download = 'Full Forecast Report.xlsx';
                  document.body.appendChild(a);
                  a.click();
                  hideLoading();
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);
                })
                .catch((error) => {
                  console.log(error);
                  hideLoading();
                });
            }}
          >
            Full Forecast Report
          </Dropdown.Item>
        )}
        <Dropdown.Item
          onClick={() => {
            onDownloadHandle('excel');
          }}
        >
          as XLS
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            onDownloadHandle('csv');
          }}
        >
          as CSV
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DownloadSelector;
