import React from 'react';
import { toast } from 'react-toastify';
import ForecastService from '../services/forecast.service';
import { useForecast } from '../providers/ForecastProvider';

export const useWatchForecastStatus = (forecastId: string, onRefresh?: () => void) => {
  const ref = React.useRef({ timer: null, status: '', forecastId });
  const interval = 1000 * 10;
  ref.current.forecastId = forecastId;
  React.useEffect(() => {
    ref.current.timer = setInterval(async () => {
      const { forecastId } = ref.current;
      if (forecastId) {
        const res = await ForecastService.GetForecast(forecastId);
        // const allStatus = ["Created", "Calculating"];
        // const status = allStatus[Math.floor(Math.random() * allStatus.length)];
        const status = res?.data?.forecast?.status;

        if (status && ref.current.status !== status) {
          if (status !== 'Created') {
            toast.info('This Forecast is being recalculated using new data.', {
              autoClose: interval,
              onClose: () => {
                onRefresh && onRefresh();
              },
            });
            const eles = document.getElementsByClassName('content');
            for (const ele of eles) {
              ele.classList.add('content-grayout');
            }
          } else {
            toast.dismiss();
            const eles = document.getElementsByClassName('content');
            for (const ele of eles) {
              ele.classList.remove('content-grayout');
            }
          }
          ref.current.status = status;
        }
      }
    }, interval);
    return () => {
      if (ref.current.timer) {
        clearInterval(ref.current.timer);
      }
    };
  }, []);
};

export const ForecastStatusWatcher = (props: any) => {
  const { children } = props;
  const [refreshing, $refreshing] = React.useState(false);
  const { forecastId } = useForecast();
  useWatchForecastStatus(forecastId, () => {
    $refreshing(true);
  });
  React.useEffect(() => {
    if (refreshing) {
      setTimeout(() => {
        $refreshing(false);
      }, 1000 * 0.1);
    }
  }, [refreshing]);
  if (refreshing) return null;
  return children;
};

export default useWatchForecastStatus;
