import Axios from '../lib/axios';
import {
  GetBrandsRequest,
  GetForecastBestProductsRequest,
  GetForecastBudgetRequest,
  GetForecastProductRequest,
  GetForecastRequest,
  GetRegionalForecastRequest,
  UpdateForecastBudgetRequest,
  UpdateForecastProductRequest,
  GetPrevAndCurrentForecastBudgetRequest,
} from '../lib/types';

const GetForecastBudget = (getForecastBudgetRequest: GetForecastBudgetRequest) => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/forecast/budget', {
    params: getForecastBudgetRequest,
  });
};

const GetForecastCalculatorMetaData = (req: any) => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/forecast/metadata', {
    params: req,
  });
};

const GetPrevAndCurrentForecastBudget = (getForecastBudgetRequest: GetPrevAndCurrentForecastBudgetRequest) => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/forecast/prev-and-current-budget', {
    params: getForecastBudgetRequest,
  });
};

const UpdateForecastBudget = (updateForecastBudgetRequest: UpdateForecastBudgetRequest) => {
  return Axios.put(process.env.REACT_APP_SERVER_ROOT + '/forecast/budget', updateForecastBudgetRequest);
};

const GetForecasts = (getForecastRequest: GetForecastRequest) => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/forecast', {
    params: getForecastRequest,
  });
};

const GetForecast = (forecastId: string) => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + `/forecast/id/${forecastId}`);
};

const GetYearSales = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/sales/years');
};

const GetSalesLookbackSources = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/sales/lookback/sources');
};

const GetTerritories = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/forecast/territory');
};

const GetStateTerritories = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/forecast/states');
};

const GetUserTerritories = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/user/territory');
};

const GetTerritoriesHierarchy = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/territories/hierarchy');
};

const GetForecastProduct = (getForecastProductRequest: GetForecastProductRequest) => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/forecast/product', {
    params: getForecastProductRequest,
  });
};

const GetForecastBestProducts = (getForecastProductRequest: GetForecastBestProductsRequest) => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/forecast/best-products', {
    params: getForecastProductRequest,
  });
};

const UpdateForecastProduct = (productId: string, updateForecastProductRequest: UpdateForecastProductRequest) => {
  return Axios.put(process.env.REACT_APP_SERVER_ROOT + '/forecast/product/' + productId, updateForecastProductRequest);
};

const GetRegionalForecast = (getRegionalForecastRequest: GetRegionalForecastRequest) => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/forecast/region', {
    params: getRegionalForecastRequest,
  });
};

// brand API
const GetBrands = (geBrandsRequest?: GetBrandsRequest) => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/brands', {
    params: geBrandsRequest,
  });
};

const ForecastService = {
  GetForecastBudget,
  GetForecastCalculatorMetaData,
  GetForecasts,
  GetForecast,
  GetTerritories,
  GetStateTerritories,
  GetTerritoriesHierarchy,
  UpdateForecastBudget,
  GetForecastProduct,
  UpdateForecastProduct,
  GetForecastBestProducts,
  GetRegionalForecast,
  GetUserTerritories,
  GetYearSales,
  GetSalesLookbackSources,
  GetBrands,
  GetPrevAndCurrentForecastBudget,
};

export default ForecastService;
