import React from 'react';

const NotFound = (props: any) => {
  const { children, renderNotFound, title, isNotFound } = props;

  const notFoundEle = renderNotFound ? (
    renderNotFound(props)
  ) : (
    <div className="col-12">
      <div className="alert text-lg" role="alert">
        {title || 'Not Found'}
      </div>
    </div>
  );
  if (isNotFound) {
    return notFoundEle;
  }
  return children;
};

export default NotFound;
