import React from 'react';
const page500: React.FC = () => {
  return (
    <div className="main d-flex w-100 justify-content-center">
      <div className="d-flex flex-column container">
        <div className="h-100 row">
          <div className="mx-auto d-table h-100 col-xl-5 col-lg-6 col-md-8 col-sm-10">
            <div className="d-table-cell align-middle">
              <div className="text-center">
                <h1 className="display-1 fw-bold">500</h1>
                <p className="h2">Internal server error.</p>
                <p className="lead fw-normal mt-3 mb-4">
                  The server encountered something unexpected that didn&apos;t allow it to complete the request.
                </p>
                <a href="/forecast/dashboard">
                  <button type="button" className="btn btn-primary btn-lg">
                    Return to website
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default page500;
