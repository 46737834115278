import React from 'react';

interface ICaseInputProps {
  value: string | number;
  onChange: (value: string | number) => void;
  style?: any;
  className?: string;
  disabled?: boolean;
  min?: string | number;
  max?: string | number;
}

const validate = (input: string, props: any) => {
  if (props.min !== undefined && parseFloat(input) < parseFloat(props.min)) {
    return false;
  }
  if (props.max !== undefined && parseFloat(input) > parseFloat(props.max)) {
    return false;
  }
  const isValid = /^\d*\.?\d*$/.test(input);
  return isValid;
};

const CaseInput: React.FC<ICaseInputProps> = (props: any) => {
  const { style, className, disabled, onChange, value } = props;
  const [intervalValue, $intervalValue] = React.useState(value);
  const ref = React.useRef<any>({
    isChanging: false,
  });
  React.useEffect(() => {
    if (!ref.current.isChanging) {
      $intervalValue(value);
    }
  }, [value, ref]);
  return (
    <input
      type="text"
      style={style}
      className={className}
      disabled={disabled}
      value={intervalValue}
      onChange={(e) => {
        ref.current.isChanging = true;
        const value = e.target.value;
        if (!validate(value, props)) {
          return false;
        }
        $intervalValue(value);
        onChange && onChange(e);
      }}
      onBlur={() => {
        ref.current.isChanging = false;
      }}
    />
  );
};

export default CaseInput;
