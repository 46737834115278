import React, { useState } from 'react';
import CustomTable from '../common/Table';
import { Description } from '../../pages/SalesBonus/Review';
import CustomAlert from '../common/Alert';

export const MarketManagerItem = ({
  description,
  territories,
  columns,
  rows,
}: {
  description: Description | undefined;
  territories?: any;
  columns: any;
  rows: any;
}) => {
  const [isBonus, setViewBonus] = useState<any>(false);
  return (
    <div>
      <>
        <h5>{`Market Manager: ${description ? description?.manager : ''}`}</h5>
        <h5>{'Territory: ' + (description ? description?.territory : '')}</h5>
        <h5>Total Potential: {isBonus && description ? description?.totalPotential : '********'}</h5>
        <h5>
          <p>Total Earned This Period: {isBonus && description ? description?.totalEarned : '********'}</p>
        </h5>
        <CustomAlert
          show={!description?.userId}
          variant="outline-danger"
          message={`User ${description ? description?.manager : ''} not located in system. Check spelling or role and try again.`}
        />
        <CustomAlert
          show={territories && !territories.includes(description?.territory)}
          variant="outline-warning"
          message="Territory cannot be located in the system. Moving forward without territory."
        />
      </>
      <CustomTable
        columns={columns}
        data={rows}
        invalidUser={!description?.userId}
        viewData={isBonus}
        setViewData={setViewBonus}
      />
    </div>
  );
};
