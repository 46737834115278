export const formatNum = (num: number, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
  return Number(num).toLocaleString('en-US', { minimumFractionDigits, maximumFractionDigits });
};

export const formatNumOneDecimal = (num: number, minimumFractionDigits = 1, maximumFractionDigits = 1) => {
  return Number(num).toLocaleString('en-US', { minimumFractionDigits, maximumFractionDigits });
};

export const formatNegativeNumFromStr = (num: string | number) => {
  ///precondition: @num has already been cleansed for bad input using /^-?\d*\.?\d*$/ regex
  if (typeof num === 'string') {
    if ((num.startsWith('.') || num.startsWith('.')) && num.length === 1) return 0;
    if (num.startsWith('-.') && num.length === 2) return 0;
    if (num.startsWith('.') && num.length > 1) {
      // Handle cases like .5
      return parseFloat(num);
    } else if (num.startsWith('.-') && num.length > 2) {
      // Handle cases like -.5
      console.log(num.split('-.')[1]);
      return -parseFloat(num.split('.-')[1]);
    } else if (num.includes('-')) {
      // Handle negative numbers
      return -Number(num.split('-')[1]);
    } else {
      return Number(num);
    }
  } else {
    return Number(num);
  }
  //return typeof num === 'string' && num.includes('-') ? -Number(num.split('-')[1]) : Number(num);
};

export const formatCurrencyFromNum = (num: number) => {
  return num.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};
