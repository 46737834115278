import React, { type PropsWithChildren } from 'react';
import classNames from 'classnames';

interface IMain extends PropsWithChildren {
  className?: string;
}
const Main: React.FC<IMain> = ({ className, children }) => (
  <div className={classNames('main', className)}>{children}</div>
);

export default Main;
