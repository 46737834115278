import Axios from '../lib/axios';

import { ListHistoryRequest, RollbackHistoryRequest } from '../lib/types';

const ListHistory = (request: ListHistoryRequest) => {
  return Axios.get(`${process.env.REACT_APP_SERVER_ROOT || ''}/forecast/${request.forecastId}/history`, {
    params: request,
  });
};

const RollbackHistory = (request: RollbackHistoryRequest) => {
  return Axios.put(
    `${process.env.REACT_APP_SERVER_ROOT || ''}/admin/forecast/history/${request.historyId}/rollback`,
    request,
  );
};

const RemoveHistory = (request: RollbackHistoryRequest) => {
  return Axios.put(
    `${process.env.REACT_APP_SERVER_ROOT || ''}/admin/forecast/history/${request.historyId}/remove`,
    request,
  );
};

const HistoryService = {
  ListHistory,
  RollbackHistory,
  RemoveHistory,
};

export default HistoryService;
