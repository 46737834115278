import React, { ReactElement } from 'react';
import { matchPath } from 'react-router-dom';

import SidebarNavListItem from './SidebarNavListItem';
import SidebarNavList from './SidebarNavList';
import { IDashboardItem } from './dashboardItems';

interface IReduceChildRoutes {
  elements: ReactElement[];
  item: IDashboardItem;
  currentRoute: string;
}
const reduceChildRoutes = (props: IReduceChildRoutes) => {
  const { elements, item, currentRoute } = props;

  if (item.children) {
    const open = item.href
      ? !!matchPath(
          {
            path: item.href,
            end: false,
          },
          currentRoute,
        )
      : false;

    elements.push(
      <SidebarNavListItem icon={item.icon} key={item.title} open={!!open} title={item.title} href={item.href}>
        <SidebarNavList items={item.children} />
      </SidebarNavListItem>,
    );
  } else {
    elements.push(
      <SidebarNavListItem href={item.href} icon={item.icon} key={item.title} badge={item.badge} title={item.title} />,
    );
  }

  return elements;
};

export default reduceChildRoutes;
