import React, { useState, useRef } from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

const ProductStatesFobs = ({
  territories,
  statePrices,
  setStatePrices,
  deletedTerritoryPrices,
  setDeletedTerritoryPrices,
  createdTerritoryPrices,
  setCreatedTerritoryPrices,
  editedTerritoryPrices,
  setEditedTerritoryPrices,
  natlPrice,
}: {
  territories: any;
  statePrices: any;
  natlPrice: any;
  setStatePrices: (x: any) => void;
  deletedTerritoryPrices: any;
  setDeletedTerritoryPrices: (x: any) => void;
  createdTerritoryPrices: any;
  setCreatedTerritoryPrices: (x: any) => void;
  editedTerritoryPrices: any;
  setEditedTerritoryPrices: (x: any) => void;
}) => {
  const scrollableRef: any = useRef(null);

  const handleFieldChange = (territoryId: string, newValue: string, wasCreated: boolean, field: string) => {
    const existingChange = statePrices.find((change: any) => change.territoryId === territoryId);

    if (existingChange) {
      // Update the statePrices array
      const updatedChanges = statePrices.map((change: any) =>
        change.territoryId === territoryId ? { ...change, [field]: newValue } : change,
      );
      setStatePrices(updatedChanges);

      if (wasCreated) {
        const existingChangeCreatedData = createdTerritoryPrices.find(
          (change: any) => change.territoryId === territoryId,
        );

        if (existingChangeCreatedData) {
          const updatedCreatedChanges = createdTerritoryPrices.map((change: any) =>
            change.territoryId === territoryId ? { ...change, [field]: newValue } : change,
          );
          setCreatedTerritoryPrices(updatedCreatedChanges);
        } else {
          setCreatedTerritoryPrices([...createdTerritoryPrices, { ...existingChange, [field]: newValue }]);
        }

        const filteredDeletedPrices = deletedTerritoryPrices.filter(
          (item1) => !createdTerritoryPrices.some((item2) => item1.territoryId === item2.territoryId),
        );
        setDeletedTerritoryPrices(filteredDeletedPrices);
      } else {
        const existingChangeEditedData = editedTerritoryPrices.find(
          (change: any) => change.territoryId === territoryId,
        );

        if (existingChangeEditedData) {
          const updatedEditedChanges = editedTerritoryPrices.map((change: any) =>
            change.territoryId === territoryId ? { ...change, [field]: newValue } : change,
          );
          setEditedTerritoryPrices(updatedEditedChanges);
        } else {
          setEditedTerritoryPrices([...editedTerritoryPrices, { ...existingChange, [field]: newValue }]);
        }
      }
    } else {
      setStatePrices([...statePrices, { territoryId, [field]: newValue }]);
    }
  };

  const applyAllPrices = () => {
    const newPrices = statePrices.map((item) => ({ ...item, price: natlPrice }));
    setStatePrices(newPrices);
    setEditedTerritoryPrices(newPrices);
  };

  return (
    <div className="col ps-4">
      <div>
        <div className="d-flex justify-content-between">
          <div className="my-2">
            <Select
              placeholder="Territory list"
              isSearchable={true}
              defaultInputValue=""
              onChange={(option: any) => {
                const territoryExist = statePrices.some((item: any) => item.territoryId === option.territoryId);
                if (!territoryExist) {
                  setStatePrices([...statePrices, { ...option, price: 0, wasCreated: true }]);
                  if (scrollableRef && scrollableRef.current) {
                    scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
                  }
                }
              }}
              options={territories}
              styles={{
                container: (baseStyles) => ({
                  ...baseStyles,
                  width: '180px',
                }),
                control: (baseStyles) => ({
                  ...baseStyles,
                  minHeight: '27px',
                  borderColor: 'rgb(118, 118, 118)',
                }),
                valueContainer: (baseStyles) => ({
                  ...baseStyles,
                  height: '27px',
                  alignItems: 'normal',
                }),
                indicatorsContainer: (baseStyles) => ({
                  ...baseStyles,
                  height: '27px',
                }),
              }}
            />
          </div>
          <button className="btn btn-primary m-2" type="button" onClick={applyAllPrices} style={{ textAlign: 'right' }}>
            Apply Nat&apos;l FOB
          </button>
        </div>
        <div className="table-responsive table-sm" ref={scrollableRef} style={{ fontSize: '11px', maxHeight: '450px' }}>
          <table className="table" style={{ fontSize: '13px' }}>
            <thead>
              <tr>
                <th>
                  <span style={{ fontWeight: 400 }}>State</span>
                </th>
                <th className="text-center">
                  <span style={{ fontWeight: 400 }}>FOB</span>
                </th>
                <th className="text-center">
                  <span style={{ fontWeight: 400 }}>Tier 1</span>
                </th>
                <th className="text-center">
                  <span style={{ fontWeight: 400 }}>Tier 2</span>
                </th>
                <th className="text-center"></th>
              </tr>
            </thead>
            <tbody style={{ borderTop: '2px solid #6c757d' }}>
              {statePrices?.length === 0 ? (
                <p>No new State Prices. Add one above</p>
              ) : (
                statePrices.map((terr: any, index) => (
                  <tr key={index}>
                    <td>
                      {terr.name} ({terr.shortName})
                    </td>
                    <td className="text-center">
                      <input
                        type="text"
                        value={terr.price}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (/^-?\d*\.?\d*$/.test(inputValue) || inputValue === '-' || inputValue === '') {
                            handleFieldChange(terr.territoryId, inputValue, terr.wasCreated, 'price');
                          } else {
                            e.preventDefault();
                          }
                        }}
                      />
                      {Number(terr.price) <= 0 && <p className="text-danger">Price must be greater than 0</p>}
                    </td>
                    <td className="text-center">
                      <input
                        type="text"
                        value={terr.tier1Price}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (/^-?\d*\.?\d*$/.test(inputValue) || inputValue === '-' || inputValue === '') {
                            handleFieldChange(terr.territoryId, inputValue, terr.wasCreated, 'tier1Price');
                          } else {
                            e.preventDefault();
                          }
                        }}
                      />
                      {terr.tier1Price !== null && terr.tier1Price !== '' && Number(terr.tier1Price) <= 0 && (
                        <p className="text-danger">Tier 1 must be greater than 0</p>
                      )}
                    </td>
                    <td className="text-center">
                      <input
                        type="text"
                        value={terr.tier2Price}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (/^-?\d*\.?\d*$/.test(inputValue) || inputValue === '-' || inputValue === '') {
                            handleFieldChange(terr.territoryId, inputValue, terr.wasCreated, 'tier2Price');
                          } else {
                            e.preventDefault();
                          }
                        }}
                      />
                      {terr.tier2Price !== null && terr.tier2Price !== '' && Number(terr.tier2Price) <= 0 && (
                        <p className="text-danger">Tier 2 must be greater than 0</p>
                      )}
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-danger btn-sm"
                        type="button"
                        onClick={() => {
                          const territoryExist = deletedTerritoryPrices.some(
                            (item: any) => item.territoryId === terr.territoryId,
                          );
                          if (!territoryExist) {
                            setDeletedTerritoryPrices((prevItems: any) => [...prevItems, terr]);
                            setEditedTerritoryPrices(
                              editedTerritoryPrices.filter(
                                (item1) =>
                                  !deletedTerritoryPrices.some((item2) => item1.territoryId === item2.territoryId),
                              ),
                            );
                            setCreatedTerritoryPrices(
                              createdTerritoryPrices.filter(
                                (item1) =>
                                  !deletedTerritoryPrices.some((item2) => item1.territoryId === item2.territoryId),
                              ),
                            );
                          }
                          setStatePrices((prevItems: any) =>
                            prevItems.filter((item: any) => item.territoryId !== terr.territoryId),
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faCircleXmark} style={{ fontSize: '13px' }} />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProductStatesFobs;
