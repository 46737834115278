import React, { useState, PropsWithChildren } from 'react';
import { createSafeContext } from './createSafeContext';

interface BrandForecastContextType {
  regionalData: any[];
  setRegionalData: (x: any) => void;
}

const [useBrandForecast, Provider] = createSafeContext<BrandForecastContextType>();

export { useBrandForecast };

export const BrandForecastProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [regionalData, setRegionalData] = useState<any[]>([]);

  return <Provider value={{ regionalData, setRegionalData }}>{children}</Provider>;
};
