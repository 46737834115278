import { UserRole } from '../lib/types';
import AuthService from '../services/auth.service';

export const getRedirectUrl = async (roles, path: string) => {
  const rolePriorities = await AuthService.GetRedirectingRoutes();
  const matchedRole = rolePriorities.data
    .filter((priority) => roles.includes(priority.role))
    .sort((a, b) => a.priority - b.priority)[0];

  if (matchedRole) {
    if (matchedRole.role === UserRole.ForecastingUser && path.includes('/sales-bonus')) {
      return '/forecast/dashboard';
    }
    return matchedRole.loginUrl || path || '/forecast/dashboard';
  }
  return path || '/forecast/dashboard';
};

export const enum FeeTypes {
  warehouse = 1,
  psi = 2,
  insurance = 3,
}
