import { useEffect, useRef } from 'react';

const failurePath = ['/403', '/404', '/500'];

export const usePrevLocation = (location: any) => {
  const prevLocRef = useRef(location);

  useEffect(() => {
    const prevPath = localStorage.getItem('prevPath') || '';
    if (
      !failurePath.includes(location.pathname) &&
      !failurePath.includes(prevPath) &&
      prevLocRef.current.pathname !== location.pathname
    ) {
      localStorage.setItem('prevPath', prevLocRef.current.pathname);
      prevLocRef.current = location;
    }
  }, [location, prevLocRef.current.key]);

  return prevLocRef.current;
};
