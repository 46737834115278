import React from 'react';
import { Card } from 'react-bootstrap';
import salesCompService from '../../services/sales.bonus.service';
import { downloadFile } from '../../utils/downloadUtils';

export const UploadBonusCard = ({
  title,
  importBody,
  exportBody,
}: {
  title: string;
  importBody: any;
  exportBody: any;
}) => {
  const getTemplate = async () => {
    const res = await salesCompService.GetTemplate();
    downloadFile(res, 'commission_example.xlsx');
  };
  return (
    <div>
      <Card className="d-flex p-4">
        <div>
          <h5 className="text-primary m-0 pb-1 pt-1">{title}</h5>
        </div>
        <div className="my-2">
          {importBody}
          <a className="card-link" onClick={getTemplate}>
            Sample Template
          </a>
        </div>
        <hr />
        <div>{exportBody}</div>
      </Card>
    </div>
  );
};
