import React, { useEffect, useState } from 'react';
import { formatDate } from '../../../utils/formatDate';
import { hideLoading, showLoading } from '../../../lib/uiService';
import salesCompService from '../../../services/sales.bonus.service';
import useErrorHandling from '../../../hooks/useErrorHandling';
import { useAuth } from '../../../providers/AuthProvider';
import { kpiAdjustmentsTypes } from '../../../constants';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { UserRole } from '../../../lib/types';
import AdminService from '../../../services/admin.service';

const NewKpiAdjustmentRequest = () => {
  const { setError } = useErrorHandling();
  const { id, role } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [periods, setPeriods] = useState<any[]>([]);
  const [goals, setGoals] = useState<any[]>([]);
  const [disable, setDisable] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [newKpiAdjustment, setNewKpiAdjustment] = useState<any>({});
  const [searchParams] = useSearchParams();
  const paramKpiAdjustmentId = searchParams.get('kpi');

  const validationSchema = Yup.object({
    /* compSalesId: Yup.string().required('Time Period is required'),
    goal: Yup.string().required('Goal is required'), */
    accountName: Yup.string().required('Account Name is required'),
    accountUrl: Yup.string().required('Account URL is required'),
    requestedChange: Yup.number()
      .typeError('Requested Change must be a number')
      .positive('Requested change must be a positive number')
      .required('Requested adjustment is required'),
    reasons: Yup.string().required('Reason for adjustment is required'),
  });

  useEffect(() => {
    showLoading();
    salesCompService
      .GetSalesComp()
      .then(({ data }) => {
        if (data.length > 0) {
          const tempSalesCompList = data.map((comp: any) => {
            const startDate = formatDate(
              { month: '2-digit', day: '2-digit', year: 'numeric' },
              new Date(comp.startDate),
            );
            const endDate = formatDate({ month: '2-digit', day: '2-digit', year: 'numeric' }, new Date(comp.endDate));
            return { label: `${startDate ?? ''} - ${endDate ?? ''}`, value: comp.salesCompId };
          });
          setPeriods(tempSalesCompList);
        }
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        console.error(error);
        setError({ status: error.response.status });
      });
  }, []);

  useEffect(() => {
    if (paramKpiAdjustmentId) {
      salesCompService
        .GetKPIAdjustmentRequest({ kpiAdjustmentId: paramKpiAdjustmentId })
        .then(({ data }) => {
          if (data.userKpiAdjustmentRequest) {
            setDisable(location.pathname.includes('approve'));
            setNewKpiAdjustment({
              ...data.userKpiAdjustmentRequest,
              goalId: data.userKpiAdjustmentRequest.goalId,
              goal: data.userKpiAdjustmentRequest.kpi,
              accountUrl: data.userKpiAdjustmentRequest.accountURL,
              requestedChange: data.userKpiAdjustmentRequest.newGoal,
              reasons: data.userKpiAdjustmentRequest.adjustmentReason,
            });
            // THE MISSING PERIOD AND GOAL INPUTS WILL BE FILLED WHEN SALESFORCE TABLE IS DEFINED
          }
        })
        .catch((error) => {
          console.error(error);
          setError({ status: error.response.status });
        });
    }
  }, []);

  const requestNewKpiAdjustment = (values) => {
    const updatedValues = { ...newKpiAdjustment, ...values };
    if (location.pathname.includes('approve')) {
      showLoading();
      salesCompService
        .ApproveKPIAdjustmentRequest({ kpiAdjustment: updatedValues })
        .then(({ data }) => {
          hideLoading();
          toast.success(
            data.wasApproved
              ? 'This request has been approved.'
              : 'You have approved these request. Awaiting more approvals.',
          );
          navigate('/sales-commission/kpi-adjustment');
        })
        .catch((error) => {
          hideLoading();
          console.error(error);
          setError({ status: error.response.status });
        });
    } else {
      setErrorMessage('');
      showLoading();
      salesCompService
        .CreateKPIAdjustmentRequest({ newKpiAdjustment: updatedValues, selectedGoal })
        .then(() => {
          hideLoading();
          toast.success('KPI Adjustment Request Successfully Created');
          navigate('/sales-commission/kpi-adjustment');
        })
        .catch((error) => {
          hideLoading();
          console.error(error);
          setError({ status: error.response.status });
        });
    }
  };
  //TODO wait for SALESFORCE
  return (
    <div className="container-fluid">
      <h3 className="text-dark mb-4">KPI Adjustment</h3>
      <div className="row">
        <div className="col">
          <div
            className="card shadow"
            style={{
              padding: '0px',
              paddingBottom: '0px',
              marginBottom: '40px',
              paddingRight: '0px',
            }}
          >
            <div className="card-header py-3">
              <p className="fw-bold text-primary m-0">Request a new KPI Request</p>
            </div>
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={{
                  compSalesId: newKpiAdjustment.compSalesId,
                  goal: goals.find((item) => item.goalPlan === newKpiAdjustment.goal),
                  accountName: newKpiAdjustment.accountName,
                  accountUrl: newKpiAdjustment.accountUrl,
                  requestedChange: newKpiAdjustment.requestedChange,
                  adjustmentType: kpiAdjustmentsTypes.Adjustment,
                  reasons: newKpiAdjustment.reasons,
                  approverNotes: newKpiAdjustment.approverNotes,
                }}
                validationSchema={validationSchema}
                onSubmit={requestNewKpiAdjustment}
              >
                {({ values, handleChange, setFieldValue }) => (
                  <Form>
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label className="form-label">
                            <strong>Time Period</strong>
                          </label>
                          <Field
                            as="select"
                            name="compSalesId"
                            className="form-select"
                            disabled={disable}
                            onChange={(e) => {
                              const compSalesId = e.target.value;
                              handleChange(e);
                              setFieldValue('compSalesId', compSalesId);
                              if (compSalesId !== '') {
                                salesCompService
                                  .GetSalesCompDetail(compSalesId)
                                  .then(({ data }) => {
                                    if (data) {
                                      const compDetail = data.groups.find((item) => item.userId === id);
                                      if (compDetail) {
                                        setGoals(
                                          compDetail.items
                                            .map((item) => {
                                              return !item.goalPlan.toLowerCase().includes('total') ? item : null;
                                            })
                                            .filter((item) => item),
                                        );
                                      } else {
                                        setErrorMessage("There isn't a goal related to the current user.");
                                      }
                                    } else {
                                      salesCompService
                                        .GetSalesRollUp(compSalesId)
                                        .then(({ data }) => {
                                          if (data.salesComp) {
                                            const compDetail = data.salesComp.groups.find((item) => item.userId === id);
                                            if (compDetail) {
                                              setGoals(
                                                compDetail.items
                                                  .map((item) => {
                                                    return !item.goalPlan.toLowerCase().includes('total') ? item : null;
                                                  })
                                                  .filter((item) => item),
                                              );
                                            } else {
                                              setErrorMessage("There isn't a goal related to the current user.");
                                            }
                                          } else {
                                            setGoals([]);
                                          }
                                        })
                                        .catch((error) => {
                                          console.error(error);
                                          setError({ status: error.response.status });
                                        });
                                    }
                                  })
                                  .catch((error) => {
                                    console.error(error);
                                    setError({ status: error.response.status });
                                  });
                              } else {
                                setNewKpiAdjustment({ ...newKpiAdjustment, goal: '', requestedChange: '' });
                                setGoals([]);
                                setSelectedGoal({});
                              }
                            }}
                          >
                            <option value=""> - </option>
                            {periods.map((item, index) => (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage name="compSalesId" component="div" className="text-danger" />
                        </div>
                        <div className="form-group">
                          <label className="form-label">
                            <strong>Goal</strong>
                          </label>
                          <Field
                            as="select"
                            name="goal"
                            className="form-select"
                            disabled={disable}
                            onChange={(e) => {
                              const goal = e.target.value;
                              setSelectedGoal(goals.find((item) => item.goalPlan === goal));
                              handleChange(e);
                              setFieldValue('goal', goal);
                            }}
                          >
                            <option value=""> - </option>
                            {goals.map((item, index) => (
                              <option key={index} value={item.goalPlan}>
                                {item.goalPlan}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage name="goal" component="div" className="text-danger" />
                        </div>
                        <div className="form-group">
                          <label className="form-label mt-2">
                            <strong>Account Name</strong>
                          </label>
                          <Field name="accountName" className="form-control" type="text" disabled={disable} />
                          <ErrorMessage name="accountName" component="div" className="text-danger" />
                        </div>

                        <div className="form-group">
                          <label className="form-label mt-2">
                            <strong>Great Vines Account URL</strong>
                          </label>
                          <Field name="accountUrl" className="form-control" type="text" disabled={disable} />
                          <ErrorMessage name="accountUrl" component="div" className="text-danger" />
                        </div>
                        <div className="form-group mt-2">
                          <label className="form-label">
                            <strong>Requested Adjustment</strong>
                          </label>
                          <div className="row">
                            <div className="col">
                              <div className="table-responsive table-sm" style={{ fontSize: '12px' }}>
                                <table className="table table-sm">
                                  <thead>
                                    <tr>
                                      <th>Goal Quantity</th>
                                      <th>Activities</th>
                                      <th>RAD</th>
                                      <th>Requested</th>
                                      <th>% Achieved</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {selectedGoal?.goalQuantity && (
                                      <tr>
                                        <td>{selectedGoal.goalQuantity}</td>
                                        <td>{selectedGoal.activities}</td>
                                        <td>{selectedGoal.rad}</td>
                                        <td>
                                          <Field
                                            name="requestedChange"
                                            className="form-control"
                                            type="text"
                                            style={{ width: '60px' }}
                                            disabled={disable}
                                          />
                                        </td>
                                        <td>{selectedGoal.percentAchieved}</td>
                                      </tr>
                                    )}
                                    <ErrorMessage name="requestedChange" component="div" className="text-danger" />
                                  </tbody>
                                </table>
                              </div>
                              <div className="form-group">
                                <div className="form-check">
                                  <input
                                    disabled={disable}
                                    name="requestedChange"
                                    onChange={() => {
                                      setFieldValue('adjustmentType', kpiAdjustmentsTypes.Adjustment);
                                    }}
                                    className="form-check-input"
                                    type="radio"
                                    id="formCheck-1"
                                    checked={values.adjustmentType === kpiAdjustmentsTypes.Adjustment}
                                  />
                                  <label className="form-check-label" htmlFor="formCheck-1">
                                    Adjustment
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    disabled={disable}
                                    className="form-check-input"
                                    onChange={() => {
                                      setFieldValue('adjustmentType', kpiAdjustmentsTypes.ReportingMistake);
                                    }}
                                    checked={values.adjustmentType === kpiAdjustmentsTypes.ReportingMistake}
                                    type="radio"
                                    id="formCheck-2"
                                  />
                                  <label className="form-check-label" htmlFor="formCheck-2">
                                    Reporting Mistake
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label className="form-label">
                            <strong>Reason for Adjustment</strong>
                          </label>
                          <Field as="textarea" name="reasons" rows={5} className="form-control" disabled={disable} />
                          <ErrorMessage name="reasons" component="div" className="text-danger" />
                        </div>
                        <div className="form-group">
                          <label className="form-label">
                            <strong>Approver Notes</strong>
                          </label>
                          <Field
                            as="textarea"
                            name="approverNotes"
                            rows={5}
                            className="form-control"
                            disabled={location.pathname.includes('create')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <button className="btn btn-primary float-end" type="submit">
                        {location.pathname.includes('approve') ? 'Approve' : 'Request New KPI Adjustment'}
                      </button>
                    </div>
                    {location.pathname.includes('approve') &&
                      (role.includes(UserRole.Admin) || role.includes(UserRole.SalesBonusAdmin)) && (
                        <button
                          className="btn btn-danger float-end mx-3"
                          onClick={(e) => {
                            e.preventDefault();
                            AdminService.RejectApproval({
                              referenceId: newKpiAdjustment.kpiAdjustmentId,
                              isProductApproval: false,
                            })
                              .then(() => {
                                navigate(`/sales-commission/kpi-adjustment`);
                                hideLoading();
                                toast.success('The request has been rejected.');
                              })
                              .catch((e) => {
                                hideLoading();
                                setError(e.status ? e : { status: 500 });
                              });
                          }}
                        >
                          Decline
                        </button>
                      )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewKpiAdjustmentRequest;
