import React, { type PropsWithChildren, useState } from 'react';

import { createSafeContext } from './createSafeContext';
import { SIDEBAR_POSITION, SIDEBAR_BEHAVIOR } from '../constants';

interface ISidebarContext {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  position: string;
  setPosition: (position: string) => void;
  behavior: string;
  setBehavior: (behavior: string) => void;
}

const initialState = {
  isOpen: true,
  position: SIDEBAR_POSITION.LEFT,
  behavior: SIDEBAR_BEHAVIOR.STICKY,
};

const [useSidebar, Provider] = createSafeContext<ISidebarContext>();

export { useSidebar };

export const SidebarProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [position, setPosition] = useState<string>(initialState.position);
  const [behavior, setBehavior] = useState<string>(initialState.behavior);

  return (
    <Provider
      value={{
        isOpen,
        setIsOpen,
        position,
        setPosition,
        behavior,
        setBehavior,
      }}
    >
      {children}
    </Provider>
  );
};
