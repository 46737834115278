import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik, Form, ErrorMessage, useFormikContext } from 'formik';
import { Card } from 'react-bootstrap';
import { object, string } from 'yup';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPenToSquare, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import CustomSelect from '../../../components/common/CustomSelect';
import CustomTable from '../../../components/common/Table';
import Pagination from '../../../components/common/Pagination';
import useDebounce from '../../../hooks/useDebounce';
import { IPageToShow } from '../../AdminForecast';
import AdminService from '../../../services/admin.service';
import { hideLoading, showLoading } from '../../../lib/uiService';
import { IBrandData, IBrandFamily } from '../../../lib/types';
import 'react-toastify/dist/ReactToastify.css';
import ForecastService from '../../../services/forecast.service';
import { animateScroll as scroll } from 'react-scroll';
import useErrorHandling from '../../../hooks/useErrorHandling';

const BrandCreateForm: React.FC<{ brandData: IBrandData; mode: IPageToShow; setMode: any }> = ({
  brandData,
  mode,
  setMode,
}) => {
  const { setValues, values } = useFormikContext<IBrandData>();
  const [brandFamily, setBrandFamily] = useState<IBrandFamily[]>(brandData.brandFamilies);
  const [, setActive] = useState(brandData.isActive);

  useEffect(() => {
    setValues(brandData);
    setBrandFamily(brandData.brandFamilies);
  }, [brandData]);

  const OnActive = (e: any) => {
    setActive(e.target.checked);
    setValues({
      ...values,
      isActive: e.target.checked,
    });
  };

  const handlBrandFamily = (row: IBrandFamily[], id: number) => {
    const tmpBrandFamily = row.map((val, i) => {
      return i === Number(id) ? { ...val, isDefault: true } : { ...val, isDefault: false };
    });
    setBrandFamily(tmpBrandFamily);
    setValues({
      ...values,
      brandFamilies: tmpBrandFamily,
    });
  };

  const addBrandFamily = () => {
    if (values.brandFamilyName !== '') {
      const tmpBrandFamily = [...brandFamily, { brandFamilyId: '', name: values.brandFamilyName, isDefault: false }];
      setBrandFamily(tmpBrandFamily);
      setValues({
        ...values,
        brandFamilies: tmpBrandFamily,
      });
    }
  };

  const onDeleteBrandFamily = (data: IBrandFamily[], id: number) => {
    // delete brand api
    if (data[id].brandFamilyId !== '') {
      toast.warning('Can not delete brand family!');
    } else {
      const filterBrandFamily = data.filter((v, i) => {
        return i !== Number(id);
      });
      setBrandFamily(filterBrandFamily);
      setValues({
        ...values,
        brandFamilies: filterBrandFamily,
      });
    }
  };

  const columns: any = useMemo(() => {
    return [
      {
        Header: 'Name',
        id: 'name',
        accessor: 'name',
        canSort: true,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const { name } = data[row.id];
          return (
            <td>
              <span>{name}</span>
            </td>
          );
        },
      },
      {
        Header: 'Default',
        id: 'default',
        canSort: false,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const { isDefault } = data[row.id];
          return (
            <td className="align-items-center ">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={isDefault}
                  id={`isDefault${row.id as string}`}
                  onChange={() => handlBrandFamily(data, row.id)}
                />
              </div>
            </td>
          );
        },
      },
      {
        Header: '',
        id: 'action',
        canSort: false,
        Cell: ({ data, row }: { data: any; row: any }) => {
          return (
            <td>
              <button className="btn btn-danger btn-sm" type="button" onClick={() => onDeleteBrandFamily(data, row.id)}>
                <FontAwesomeIcon icon={faClose} />
              </button>
            </td>
          );
        },
      },
    ];
  }, [brandData, values]);

  return (
    <Form>
      <div className="row mb-3">
        <div className="col">
          <Card>
            <div className="d-flex justify-content-between">
              <p className="text-primary px-2 mx-3 pt-3 fw-bold">{`${mode} Brand`}</p>
              {mode === 'Update' && (
                <div className="pt-2 px-3">
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={() => {
                      setValues({
                        brandId: '',
                        brandName: '',
                        isActive: false,
                        brandFamilies: [],
                        brandFamilyName: '',
                      });
                      setBrandFamily([]);
                      setMode('Create');
                    }}
                  />
                </div>
              )}
            </div>
            <div className="row mx-2 px-1">
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="brandName" className="col-form-label">
                    Brand Name
                  </label>
                  <div>
                    <input
                      id="brandName"
                      name="brandName"
                      value={values.brandName}
                      type="text"
                      onChange={(e: any) => setValues({ ...values, brandName: e.target.value })}
                    />
                  </div>
                  <div style={{ fontSize: '10px', color: 'red' }}>
                    <ErrorMessage name="brandName" />
                  </div>
                </div>
                <p className="mb-0">Active?</p>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="isActive"
                    style={{ width: 40, height: 20, cursor: 'pointer' }}
                    checked={values.isActive}
                    onChange={(e) => OnActive(e)}
                  />
                </div>
              </div>
              <div className="col my-2">
                <div className="px-2 border-start border-end">
                  <p>Brand Families:</p>
                  <CustomTable columns={columns} data={brandFamily} />
                  {brandFamily.length === 0 && <p>No Brand Families. Add one below</p>}
                </div>
                <hr />
                <div className="mb-3 d-flex">
                  <div className="pt-1">
                    <input
                      id="brandFamilyName"
                      name="brandFamilyName"
                      value={values.brandFamilyName}
                      type="text"
                      onChange={(e: any) => setValues({ ...values, brandFamilyName: e.target.value })}
                    />
                  </div>
                  <button onClick={addBrandFamily} className="btn btn-primary mx-3" type="button">
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>
              <div className="col my-2"></div>
              <hr />
              <div className="col d-flex flex-row-reverse align-items-end">
                <div className="my-4">
                  <button className="btn btn-primary btn-md" type="submit">
                    {mode === 'Create' ? 'Create' : 'Save'}
                  </button>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Form>
  );
};

const Brand: React.FC = () => {
  const [brands, setBrandList] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [mode, setMode] = useState<IPageToShow>('Create');
  const [rowSelect, setRowSelect] = useState(-1);
  const pageOptions = [
    { label: 'All', value: Number.MAX_SAFE_INTEGER },
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];
  const [rowPerPage, setRowPerPage] = useState(pageOptions[0].value);
  const [brandData, setData] = useState<IBrandData>({
    brandId: '',
    brandName: '',
    isActive: false,
    brandFamilies: [],
    brandFamilyName: '',
  });
  const { setError } = useErrorHandling();

  useEffect(() => {
    if (mode === 'Create') {
      setRowSelect(-1);
    }
  }, [mode]);

  const hadlePageToShow = (value: any) => {
    setRowPerPage(Number(value));
  };

  const onEditBrand = (brandId: string, row: number) => {
    setRowSelect(row);
    AdminService.GetBrand(brandId)
      .then(({ data }) => {
        const { brandId, name, isActive, brandFamilies } = data.brand;
        setData({ brandName: name, brandId, isActive, brandFamilies, brandFamilyName: '' });
        setMode('Update');
      })
      .catch((error) => {
        console.error(error);
      });
    scroll.scrollToTop({
      duration: 350,
      smooth: true,
    });
  };

  const onDeleteBrand = async (brandId: string) => {
    await AdminService.DeleteBrand(brandId);
    toast.success('Brand Successfully Deactivated');
    fetchList(page, search, rowPerPage);
  };

  useEffect(() => {
    fetchList(1, '', rowPerPage);
  }, [setBrandList]);

  useEffect(() => {
    fetchList(page, search, rowPerPage);
  }, [page, rowPerPage]);

  const fetchList = (page: number, searchString?: string, pageSize?: any) => {
    showLoading();
    ForecastService.GetBrands({
      page,
      pageSize,
      search: searchString,
    })
      .then(({ data }) => {
        setBrandList(data.brands);
        setTotalCount(data.totalCount);
        setPageCount(Math.ceil(data.totalCount / rowPerPage));
        hideLoading();
      })
      .catch((error) => {
        console.error(error);
        hideLoading();
        setError(error.status);
      });
  };

  const debouncedFetchList = useDebounce(fetchList, 500);

  const handleSearch = useCallback(
    (value: string) => {
      setSearch(value);

      if (pageCount < 2) {
        return;
      }

      if (value.length === 0 || value.length > 2) {
        debouncedFetchList(page, value, rowPerPage);
      }
    },
    [pageCount, search],
  );

  const columns: any = useMemo(() => {
    return [
      {
        Header: 'Brand Name',
        id: 'name',
        accessor: 'name',
        canSort: true,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const { name } = data[row.id];
          const { brandId } = data[row.id];
          return (
            <td>
              <span
                onClick={() => onEditBrand(brandId, row.id)}
                style={{ cursor: 'pointer' }}
                className="text-decoration-underline"
              >
                {name}
              </span>
            </td>
          );
        },
      },
      {
        Header: 'Brand Family',
        id: 'brand family',
        accessor: 'brand family',
        canSort: false,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const brandFamilyName = data[row.id].brandFamilies.map((v: any) => v.name).join(', ');
          return (
            <td>
              <span>{brandFamilyName}</span>
            </td>
          );
        },
      },
      {
        Header: 'Active',
        id: 'active',
        accessor: 'action',
        canSort: true,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const { isActive } = data[row.id];
          return (
            <td>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" disabled checked={isActive} />
              </div>
            </td>
          );
        },
      },
      {
        Header: '',
        id: 'action',
        accessor: 'action',
        canSort: false,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const { brandId } = data[row.id];
          return (
            <td>
              <button
                className="btn btn-primary btn-sm me-1"
                type="button"
                onClick={() => onEditBrand(brandId, row.id)}
              >
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
              <button className="btn btn-danger btn-sm" type="button" onClick={() => onDeleteBrand(brandId)}>
                <FontAwesomeIcon icon={faClose} />
              </button>
            </td>
          );
        },
      },
    ];
  }, [brands]);

  const validation = object().shape({
    brandName: string().required('Required!'),
  });

  const onSubmit = async (values: IBrandData) => {
    showLoading();
    try {
      const { brandName, brandId, isActive, brandFamilies } = values;
      const createBrandFamily = brandFamilies.filter(({ brandFamilyId }) => brandFamilyId === '');
      const editBrandFamily = brandFamilies.filter(({ brandFamilyId }) => brandFamilyId !== '');

      if (mode === 'Create') {
        // create brand api
        await AdminService.CreateBrand(brandName)
          .then(({ data }) => {
            const { brandId } = data.brand;
            if (createBrandFamily.length !== 0) {
              Promise.all(
                createBrandFamily.map(async ({ name, isDefault }) => {
                  await AdminService.CreateBrandFamily(brandId, name, isDefault);
                }),
              );
            }
            toast.success('Brand Successfully Created');
          })
          .catch((error) => console.error(error));
      } else {
        // update brand api
        await AdminService.EditBrand(brandId, brandName, isActive);
        if (editBrandFamily.length !== 0) {
          Promise.all(
            editBrandFamily.map(async ({ brandFamilyId, name, isDefault }) => {
              await AdminService.EditBrandFamily(brandFamilyId, name, isDefault);
            }),
          );
        }
        if (createBrandFamily.length !== 0) {
          Promise.all(
            createBrandFamily.map(async ({ name, isDefault }) => {
              await AdminService.CreateBrandFamily(brandId, name, isDefault);
            }),
          );
        }
        toast.success('Brand Successfully Updated');
      }
      setData({
        brandId: '',
        brandName: '',
        isActive: false,
        brandFamilies: [],
        brandFamilyName: '',
      });
      setMode('Create');
      hideLoading();
      setRowSelect(-1);
    } catch (error) {
      hideLoading();
      console.error(error);
    } finally {
      fetchList(page, search, rowPerPage);
    }
  };

  return (
    <>
      <h3 className="text-dark mb-4">Brands</h3>
      <Formik initialValues={brandData} validationSchema={validation} onSubmit={onSubmit}>
        <BrandCreateForm brandData={brandData} mode={mode} setMode={setMode} />
      </Formik>
      <Card title="Brand">
        <div className="p-3">
          <h5 className="text-primary fw-bold m-0 pb-1 pt-1 d-flex align-items-center ">
            <span className="mx-2">Current Brands</span>
          </h5>
        </div>
        <div className="row p-3">
          <div className="col-md-6 text-nowrap d-flex">
            <div className="mb-2">
              <label className="col-form-label" htmlFor="showperpage">
                Show:&nbsp;
              </label>
              <CustomSelect options={pageOptions} onSelectOption={hadlePageToShow} setPage={setPage} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="text-md-end dataTables_filter">
              <label className="form-label">
                <input
                  type="search"
                  className="form-control form-control-sm"
                  aria-controls="dataTable"
                  placeholder="Search"
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="p-3">
          <CustomTable columns={columns} data={brands} row={rowSelect} search={search} />
        </div>
        {brands.length > 0 && (
          <div className="row px-3">
            <div className="col-md-6">
              <p id="dataTable_info-3" className="dataTables_info" role="status" aria-live="polite">
                Showing {brands.length} of {totalCount}
              </p>
            </div>
            <div className="col-md-6">
              <Pagination total={pageCount} currentPage={page} setPage={setPage} />
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

export default Brand;
