import React, { useState } from 'react';
import { faPlus, faQuestionCircle, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ProductRelations = ({
  relatedProducts,
  setRelatedProducts,
}: {
  relatedProducts: any[];
  setRelatedProducts: (x: any) => void;
}) => {
  const [value, setValue] = useState('');
  return (
    <div className="col ps-4">
      <div className="my-2">
        <div className="d-flex flex-row align-items-baseline">
          <h4>Add or Update Product Mappings</h4>
          <OverlayTrigger
            placement={'right'}
            overlay={
              <Tooltip id={`tooltip-right`} className="tooltip-container">
                <div style={{ width: '400px', padding: '5px' }}>
                  <div style={{ textAlign: 'left' }}>
                    Exact match: BBP-ACANJN-750 -{'>'} any Park Start Shipment that has that SKU would map to this
                    product
                  </div>
                  <div style={{ textAlign: 'left' }}>
                    Starts with match: &quot;BBP-TPSZ&quot; -{'>'} any SKU that starts with BBP-TPSZ will be mapped to
                    this product.
                  </div>
                  <ul style={{ textAlign: 'left' }}>
                    <li>Match: BBP-TPSZ34</li>
                    <li>Match: BBP-TPSZ556</li>
                    <li>No Match: BBP-TP123SZ</li>
                    <li>No Match: ZBBP-TPSZ34</li>
                  </ul>
                </div>
              </Tooltip>
            }
          >
            <FontAwesomeIcon style={{ fontSize: '15px' }} className="mx-3" icon={faQuestionCircle} />
          </OverlayTrigger>
        </div>
        <div className="d-flex align-content-center my-2">
          <input
            defaultValue={''}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            type="text"
            className="w-20"
          />
          <button
            disabled={value === ''}
            className="btn btn-primary mx-2"
            onClick={(e) => {
              setRelatedProducts([...relatedProducts, value]);
              setValue('');
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>

        {relatedProducts.length ? (
          <div className="d-flex flex-row flex-wrap">
            {relatedProducts.map((item, key) => {
              return (
                <div className="m-1 p-2 border rounded" key={key}>
                  {item?.sku ? item.sku : item}
                  <FontAwesomeIcon
                    icon={faX}
                    className="ms-2"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setRelatedProducts((prevProducts) => prevProducts.filter((_, index) => index !== key));
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProductRelations;
