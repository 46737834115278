import React, { useEffect, useMemo, useState } from 'react';
import { useForecast } from '../../providers/ForecastProvider';
import Select from 'react-select';

interface ISelectItem {
  name: string;
  forecastId?: string;
  territoryId?: string;
  forecastCalendarId?: string;
  year?: number;
  status: string;
}

interface INavbarSelect {
  items: ISelectItem[];
  placeholder: string;
  scope: 'Territory' | 'Forecast' | 'TimePeriod';
  disabled?: boolean;
  isParamData: boolean;
}

const NavbarSelect: React.FC<INavbarSelect> = (props) => {
  const { items, placeholder, scope, disabled, isParamData } = props;
  const [selectedOption, setSelectedOption] = useState<any>();
  const {
    setForecastId,
    forecastId,
    forecastCalendarId,
    setTerritoryId,
    forecastUpdated,
    setForecastUpdated,
    hasForecasts,
    setForecastCalendarId,
    setCurrentForecastYear,
    setIsForecastClosed,
  } = useForecast();

  const options = items?.map((item) => ({
    value: scope === 'Forecast' ? item.forecastId : scope === 'Territory' ? item.territoryId : item.forecastCalendarId,
    label: item.name,
    status: item.status,
  }));

  useEffect(() => {
    if (scope === 'TimePeriod' && !selectedOption && forecastCalendarId) {
      setSelectedOption(options?.find((option) => option.value === forecastCalendarId));
    }
  }, [forecastCalendarId]);

  useEffect(() => {
    if (!isParamData && hasForecasts) {
      const prevValue = localStorage.getItem(`previous${scope}`) || '';
      const prevYear = localStorage.getItem('curYear');
      if (prevYear) setCurrentForecastYear(Number(prevYear));
      setSelectedOption(options?.find((option) => option.value === prevValue));
      if (scope === 'Forecast') setForecastId(prevValue);
      if (scope === 'Territory') setTerritoryId(prevValue);
    } else {
      if (scope === 'Forecast') setSelectedOption(options?.find((option) => option.value === forecastId));
      if (scope === 'TimePeriod') setSelectedOption(options?.find((option) => option.value === forecastCalendarId));
    }
  }, [forecastUpdated, isParamData, scope, hasForecasts, forecastCalendarId, forecastId]);

  const handleChange = ({ value }: { value: string }) => {
    const curYear = new Date().getFullYear();
    if (!value) {
      return;
    } else {
      setSelectedOption(options?.find((option) => option.value === value));
      if (scope === 'Forecast') {
        setForecastId(value);
        const selectedForecast = options?.find((option) => option.value === value);
        setIsForecastClosed(selectedForecast?.status === 'Closed');
        setForecastCalendarId('');
        localStorage.setItem('TimePeriod', '');
        localStorage.setItem('previousTimePeriod', '');
        localStorage.setItem('previousCurrentYear', '');
        localStorage.setItem('Forecast', String(value));
      }
      if (scope === 'Territory') setTerritoryId(value);
      if (scope === 'TimePeriod') {
        const currForecastCalendar = items.filter((item) => item.forecastCalendarId === value);
        const yearValue =
          currForecastCalendar && currForecastCalendar[0]?.year ? currForecastCalendar[0].year : curYear;
        setCurrentForecastYear(yearValue);
        localStorage.setItem('curYear', String(yearValue));
        localStorage.setItem('previousCurrentYear', String(yearValue));
        setForecastCalendarId(value);
        localStorage.setItem(scope, value);
      }
      localStorage.setItem(`previous${scope}`, value);
      setForecastUpdated(!forecastUpdated);
    }
  };

  return (
    <div>
      <Select
        isDisabled={!hasForecasts || disabled}
        isSearchable={true}
        value={selectedOption}
        onChange={(option: any) => handleChange(option)}
        placeholder={placeholder}
        options={options}
        styles={{
          option: (styles, { data }) => {
            const status = data.status;
            return {
              ...styles,
              fontStyle: scope === 'Forecast' && status === 'Closed' ? 'italic' : 'initial',
            };
          },
          singleValue: (styles, { data }) => {
            const status = data.status;
            return {
              ...styles,
              fontStyle: scope === 'Forecast' && status === 'Closed' ? 'italic' : 'initial',
              color: scope === 'Forecast' && status === 'Closed' ? 'gray' : 'black',
            };
          },
          container: (baseStyles) => ({
            ...baseStyles,
            width: '180px',
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            minHeight: '27px',
            borderColor: '#e5e5ea',
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            height: '27px',
            alignItems: 'normal',
          }),
          indicatorsContainer: (baseStyles) => ({
            ...baseStyles,
            height: '27px',
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            zIndex: '1000',
          }),
        }}
      />
    </div>
  );
};

export default NavbarSelect;
