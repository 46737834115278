import React, { useEffect, useState } from 'react';
import ForecastService from '../../../services/forecast.service';
import { Tree } from 'primereact/tree';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import AdminService from '../../../services/admin.service';
import { TERRITORY_TYPE } from '../../../constants';
import { hideLoading, showLoading } from '../../../lib/uiService';
import { findTerritoriesFromTreeByType } from '../../../utils/buildTreeOption';
import {
  getTerritoryObject,
  updateParentIdForDivision,
  updateParentIdForNational,
  updateParentIdForRegion,
  updateParentIdForState,
} from './utils';
import { toast, ToastContainer } from 'react-toastify';
import { animateScroll as scroll } from 'react-scroll';

const AdminTerritories: React.FC = () => {
  const [territories, setTerritories] = useState<any>([]);
  const [expandedKeys, setExpandedKeys] = useState<any>({});
  const [selectedKey, setSelectedKey] = useState<any>('');
  const [isNewChild, setIsNewChild] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>('');
  const [selectedTerritory, setSelectedTerritory] = useState<any>({});
  const [posibleParentsTerritory, setPosibleParentsTerritory] = useState<any>([]);
  const [selectedTerritoryPrevData, setSelectedTerritoryPrevData] = useState<any>({});
  const [, setActive] = useState(selectedTerritory.isActive);

  const buildTerritoriesNodeTree = (territories: any) => {
    const territoryMap: any = {};
    const hierarchy: any = [];
    territories.forEach((territory: any) => {
      territoryMap[territory.territoryId] = {
        id: territory.territoryId,
        key: territory.territoryId,
        data: territory.territoryId,
        isActive: territory.isActive,
        label: territory.name,
        children: [],
        draggable: true,
        droppable: true,
        style: { padding: 0 },
        ...territory,
      };
    });
    territories.forEach((territory: any) => {
      setExpandedKeys((prevData: any) => {
        return { ...prevData, [territory.territoryId]: true };
      });
      if (territory.parentId !== null) {
        const parentTerritory = territoryMap[territory.parentId];
        if (parentTerritory) {
          parentTerritory.children.push(territoryMap[territory.territoryId]);
        }
      } else {
        hierarchy.push(territoryMap[territory.territoryId]);
      }
    });

    return hierarchy;
  };

  const getTerritories = () => {
    showLoading();
    ForecastService.GetTerritories()
      .then(({ data }) => {
        const nodeTree = buildTerritoriesNodeTree(data);
        setTerritories(nodeTree);
      })
      .catch((error) => console.error(error));
    hideLoading();
  };

  useEffect(() => {
    getTerritories();
  }, []);

  useEffect(() => {
    if (selectedKey !== '') {
      AdminService.GetTerritoryData(selectedKey)
        .then(({ data }) => {
          setSelectedTerritory({ ...data, parentId: '' });
          setSelectedTerritoryPrevData(data);
          const parentTerritories = findTerritoriesFromTreeByType(
            territories,
            data.type === 'State' ? 'Region' : data.type === 'Region' ? 'Division' : 'National',
          );
          setPosibleParentsTerritory(parentTerritories);
        })
        .catch((error) => console.error(error));
      scroll.scrollToTop({
        duration: 350,
        smooth: true,
      });
    }
  }, [selectedKey]);

  const getParentLevelData = async (parentId: string) => {
    if (!parentId) return {};
    const parent = await AdminService.GetTerritoryData(parentId);
    return parent;
  };

  const handleSwitch = (e: any) => {
    setActive(e.target.checked);
    setSelectedTerritory({
      ...selectedTerritory,
      isActive: e.target.checked,
    });
  };

  const saveChanges = async () => {
    showLoading();
    const territory = selectedTerritory;
    if (territory.parentId === '') territory.parentId = selectedTerritoryPrevData.parentId;
    if (selectedTerritory.type !== selectedTerritoryPrevData.type) {
      const parentOfParentLevelOne: any = await getParentLevelData(selectedTerritory.parentId);
      const parentOfParentLevelTwo = await getParentLevelData(parentOfParentLevelOne.data?.parentId);
      const objectFromNode = getTerritoryObject(territories, territory.territoryId);

      switch (selectedTerritoryPrevData.type) {
        case 'State':
          await updateParentIdForState(territory, selectedTerritory, parentOfParentLevelOne, parentOfParentLevelTwo);
          break;

        case 'Region':
          await updateParentIdForRegion(territories, territory, selectedTerritory, objectFromNode, setWarningMessage);
          break;

        case 'Division':
          await updateParentIdForDivision(territories, territory, selectedTerritory, objectFromNode, setWarningMessage);
          break;

        case 'National':
          await updateParentIdForNational(territories, territory, selectedTerritory, objectFromNode, setWarningMessage);
          break;

        default:
          break;
      }
    }

    AdminService.UpdateTerritoryData(territory)
      .then(() => {
        toast.success('Territory Successfully Updated');
        setWarningMessage('');
        setSelectedTerritory({});
        setSelectedKey('');
        getTerritories();
        hideLoading();
      })
      .catch((error) => console.error(error));
  };

  const createTerritory = () => {
    const territory = selectedTerritory;
    territory.parentId = selectedTerritoryPrevData.territoryId;
    territory.type =
      selectedTerritoryPrevData.type === 'National'
        ? 'Division'
        : selectedTerritoryPrevData.type === 'Division'
          ? 'Region'
          : 'State';

    AdminService.CreateTerritory(territory)
      .then(() => {
        toast.success('Territory Successfully Created');
        setWarningMessage('');
        setSelectedTerritory({});
        setSelectedKey('');
        setIsNewChild(false);
        getTerritories();
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="container-fluid">
      <h3 className="text-dark mb-4">Territory Admin</h3>
      <div
        className="card shadow"
        style={{ padding: '0px', paddingBottom: '0px', marginBottom: '40px', paddingRight: '0px' }}
      >
        <div className="card-header py-3">
          <p className="text-primary m-0 fw-bold">Create &amp; Modify Territories</p>
        </div>
        <div className="card-body">
          <div className="row mb-3">
            <div className="col text-center">
              <Tree
                value={territories}
                expandedKeys={expandedKeys}
                onToggle={(e: any) => setExpandedKeys(e.value)}
                filter
                filterMode="strict"
                filterPlaceholder="Filter"
                selectionMode="single"
                selectionKeys={selectedKey}
                contentStyle={{ fontSize: 13 }}
                onSelect={(e) => {
                  setSelectedKey(e.node.id);
                  setIsNewChild(false);
                }}
              />
            </div>
            <div className="col">
              <h3>Territory Details</h3>
              <form className="mb-3">
                <label className="form-label">Name</label>
                <input
                  className="form-control mb-2"
                  type="text"
                  value={selectedTerritory?.name ?? ''}
                  onChange={(e) => setSelectedTerritory({ ...selectedTerritory, name: e.target.value })}
                />
                <label className="form-label">Short Name</label>
                <input
                  className="form-control mb-2"
                  type="text"
                  value={selectedTerritory?.shortName ?? ''}
                  onChange={(e) => setSelectedTerritory({ ...selectedTerritory, shortName: e.target.value })}
                />
                <label className="form-label">Type</label>
                <select
                  disabled
                  //disabled={isNewChild}
                  className="form-select mb-2"
                  value={selectedTerritory?.type ?? ''}
                  onChange={(e) => setSelectedTerritory({ ...selectedTerritory, type: e.target.value })}
                >
                  {TERRITORY_TYPE.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
                <label className="form-label">Move to</label>
                <select
                  disabled={posibleParentsTerritory.length === 0 || isNewChild}
                  className="form-select mb-2"
                  value={selectedTerritory?.parentId ?? ''}
                  onChange={(e) => setSelectedTerritory({ ...selectedTerritory, parentId: e.target.value })}
                >
                  <option value={''}></option>
                  {posibleParentsTerritory.map((terr: any) => (
                    <option key={terr.territoryId} value={terr.territoryId}>
                      {terr.name}
                    </option>
                  ))}
                </select>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input mb-2"
                    type="checkbox"
                    style={{ width: 40, height: 20, cursor: 'pointer' }}
                    role="switch"
                    id="flexSwitchCheckDefault"
                    checked={selectedTerritory.isActive}
                    onChange={handleSwitch}
                  />
                  <label className="form-check-label px-2 pt-1" htmlFor="flexSwitchCheckDefault">
                    Active
                  </label>
                </div>
              </form>
              <p>{warningMessage}</p>
              <div>
                <button
                  disabled={selectedKey === ''}
                  className="btn btn-primary float-end"
                  type="button"
                  onClick={() => (isNewChild ? createTerritory() : saveChanges())}
                >
                  Save
                </button>
                <button
                  disabled={selectedKey === '' || selectedTerritory?.type === 'State'}
                  onClick={() => {
                    setSelectedTerritory({
                      type:
                        selectedTerritory?.type === 'National'
                          ? 'Division'
                          : selectedTerritory?.type === 'Division'
                            ? 'Region'
                            : 'State',
                    });
                    setIsNewChild(true);
                  }}
                  className="btn btn-primary"
                  type="button"
                >
                  New Child
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
};

export default AdminTerritories;
